import React from "react";
import MainBanner from "../MainBanner";
import Aside from "../Aside";

const Home = () => {
	return (
		<>
			<MainBanner />
			<div className="l-block"></div>
			<main className="main-content g-container gtc4-s gcg1-s">
				<Aside />
				<section className="main-section gcss3-s">
					<div class="l-block-2"></div>
					<p className="main-text normal pb32">
						Guia de consulta de clases y componentes css para la
						realización de patrones de diseño, y estructuras de
						proyectos de Clickerti.
					</p>
					<p className="main-text normal">
						La guía se actualiza constantememte.
					</p>
				</section>
			</main>
		</>
	);
};

export default Home;
