import Main from "./pages/Main";
import Header from "./pages/Header";
import { BrowserRouter } from "react-router-dom";
import "./styles/style.scss";

function App() {
	return (
		<div className="App">
			<BrowserRouter>
					<Header />
					<Main />
			</BrowserRouter>
		</div>
	);
}

export default App;