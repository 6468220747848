import React from "react";
import Aside from "../Aside";
import Order from "./Order";

const Flexbox = () => {
	return (
		<>
			<main className="main-content g-container gtc4-s gcg1-s">
				<Aside />
				<section className="main-section gcss3-s">
					<div className="l-block-2"></div>
					<h2>Flexbox</h2>
					<div className="main-block">
						<p className="main-text">
							Además de las clases para crear contenedores e items
							flexibles y de las clases de las alinenaciones para
							flex, tambien están las clases para{" "}
							<code>flex-wrap: nowrap | wrap | wrap-reverse</code>
						</p>
						<p className="main-text">
							Por defecto un contenedor flex siempre es{" "}
							<code>flex-wrap: nowrap</code>, es decir, mientras
							tengan espacio se situan uno detras del otro en la
							misma linea. Si los item se quedan sin espacio
							empiezan a desbordar fuera del contenedor.
						</p>{" "}
						<pre className="language-html code-toolbar">
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">div&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">f-container fwnw-s</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									<code className="language-html">
										<span>&nbsp;&nbsp;&nbsp;</span>
										<span className="token punctuation">
											&lt;
										</span>
										<span className="token tag">
											div&nbsp;
										</span>
										<span className="token attr-name">
											class
										</span>
										<span className="token punctuation">
											="
										</span>
										<span className="token attr-value">
											item-box m16-s
										</span>
										<span className="token punctuation">
											"
										</span>
										<span className="token punctuation">
											&gt;
										</span>
										<span className="token html-value">
											item1
										</span>
										<span className="token punctuation">
											&lt;/
										</span>
										<span className="token tag">div</span>
										<span className="token punctuation">
											&gt;
										</span>
									</code>{" "}
									<code className="language-html">
										<span>&nbsp;&nbsp;&nbsp;</span>
										<span className="token punctuation">
											&lt;
										</span>
										<span className="token tag">
											div&nbsp;
										</span>
										<span className="token attr-name">
											class
										</span>
										<span className="token punctuation">
											="
										</span>
										<span className="token attr-value">
											item-box m16-s
										</span>
										<span className="token punctuation">
											"
										</span>
										<span className="token punctuation">
											&gt;
										</span>
										<span className="token html-value">
											item2
										</span>
										<span className="token punctuation">
											&lt;/
										</span>
										<span className="token tag">div</span>
										<span className="token punctuation">
											&gt;
										</span>
									</code>{" "}
									<code className="language-html">
										<span>&nbsp;&nbsp;&nbsp;</span>
										<span className="token punctuation">
											&lt;
										</span>
										<span className="token tag">
											div&nbsp;
										</span>
										<span className="token attr-name">
											class
										</span>
										<span className="token punctuation">
											="
										</span>
										<span className="token attr-value">
											item-box m16-s
										</span>
										<span className="token punctuation">
											"
										</span>
										<span className="token punctuation">
											&gt;
										</span>
										<span className="token html-value">
											item3
										</span>
										<span className="token punctuation">
											&lt;/
										</span>
										<span className="token tag">div</span>
										<span className="token punctuation">
											&gt;
										</span>
									</code>{" "}
									<code className="language-html">
										<span>&nbsp;&nbsp;&nbsp;</span>
										<span className="token punctuation">
											&lt;
										</span>
										<span className="token tag">
											div&nbsp;
										</span>
										<span className="token attr-name">
											class
										</span>
										<span className="token punctuation">
											="
										</span>
										<span className="token attr-value">
											item-box m16-s
										</span>
										<span className="token punctuation">
											"
										</span>
										<span className="token punctuation">
											&gt;
										</span>
										<span className="token html-value">
											item4
										</span>
										<span className="token punctuation">
											&lt;/
										</span>
										<span className="token tag">div</span>
										<span className="token punctuation">
											&gt;
										</span>
									</code>{" "}
									<code className="language-html">
										<span>&nbsp;&nbsp;&nbsp;</span>
										<span className="token punctuation">
											&lt;
										</span>
										<span className="token tag">
											div&nbsp;
										</span>
										<span className="token attr-name">
											class
										</span>
										<span className="token punctuation">
											="
										</span>
										<span className="token attr-value">
											item-box m16-s
										</span>
										<span className="token punctuation">
											"
										</span>
										<span className="token punctuation">
											&gt;
										</span>
										<span className="token html-value">
											item5
										</span>
										<span className="token punctuation">
											&lt;/
										</span>
										<span className="token tag">div</span>
										<span className="token punctuation">
											&gt;
										</span>
									</code>{" "}
									<code className="language-html">
										<span>&nbsp;&nbsp;&nbsp;</span>
										<span className="token punctuation">
											&lt;
										</span>
										<span className="token tag">
											div&nbsp;
										</span>
										<span className="token attr-name">
											class
										</span>
										<span className="token punctuation">
											="
										</span>
										<span className="token attr-value">
											item-box m16-s
										</span>
										<span className="token punctuation">
											"
										</span>
										<span className="token punctuation">
											&gt;
										</span>
										<span className="token html-value">
											item6
										</span>
										<span className="token punctuation">
											&lt;/
										</span>
										<span className="token tag">div</span>
										<span className="token punctuation">
											&gt;
										</span>
									</code>{" "}
									<code className="language-html">
										<span>&nbsp;&nbsp;&nbsp;</span>
										<span className="token punctuation">
											&lt;
										</span>
										<span className="token tag">
											div&nbsp;
										</span>
										<span className="token attr-name">
											class
										</span>
										<span className="token punctuation">
											="
										</span>
										<span className="token attr-value">
											item-box m16-s
										</span>
										<span className="token punctuation">
											"
										</span>
										<span className="token punctuation">
											&gt;
										</span>
										<span className="token html-value">
											item7
										</span>
										<span className="token punctuation">
											&lt;/
										</span>
										<span className="token tag">div</span>
										<span className="token punctuation">
											&gt;
										</span>
									</code>{" "}
									<code className="language-html">
										<span>&nbsp;&nbsp;&nbsp;</span>
										<span className="token punctuation">
											&lt;
										</span>
										<span className="token tag">
											div&nbsp;
										</span>
										<span className="token attr-name">
											class
										</span>
										<span className="token punctuation">
											="
										</span>
										<span className="token attr-value">
											item-box m16-s
										</span>
										<span className="token punctuation">
											"
										</span>
										<span className="token punctuation">
											&gt;
										</span>
										<span className="token html-value">
											item8
										</span>
										<span className="token punctuation">
											&lt;/
										</span>
										<span className="token tag">div</span>
										<span className="token punctuation">
											&gt;
										</span>
									</code>
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">div</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<div className="toolbar">
								<div className="toolbar-item">
									<span>HTML</span>
								</div>
							</div>
						</pre>
						<div className="f-container-demo fwnw-s">
							<div className="item-box m16-s">item1</div>
							<div className="item-box m16-s">item2</div>
							<div className="item-box m16-s">item3</div>
							<div className="item-box m16-s">item4</div>
							<div className="item-box m16-s">item5</div>
							<div className="item-box m16-s">item6</div>
							<div className="item-box m16-s">item7</div>
							<div className="item-box m16-s">item8</div>
						</div>
						<p className="main-text">
							Cuando quieres utilizar multiples lineas de arriba
							hacia abajo se utiliza <code>flex-wrap: wrap</code>.
						</p>
						<pre className="language-html code-toolbar">
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">div&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">f-container fww-s</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									<code className="language-html">
										<span>&nbsp;&nbsp;&nbsp;</span>
										<span className="token punctuation">
											&lt;
										</span>
										<span className="token tag">
											div&nbsp;
										</span>
										<span className="token attr-name">
											class
										</span>
										<span className="token punctuation">
											="
										</span>
										<span className="token attr-value">
											item-box m16-s
										</span>
										<span className="token punctuation">
											"
										</span>
										<span className="token punctuation">
											&gt;
										</span>
										<span className="token html-value">
											item1
										</span>
										<span className="token punctuation">
											&lt;/
										</span>
										<span className="token tag">div</span>
										<span className="token punctuation">
											&gt;
										</span>
									</code>{" "}
									<code className="language-html">
										<span>&nbsp;&nbsp;&nbsp;</span>
										<span className="token punctuation">
											&lt;
										</span>
										<span className="token tag">
											div&nbsp;
										</span>
										<span className="token attr-name">
											class
										</span>
										<span className="token punctuation">
											="
										</span>
										<span className="token attr-value">
											item-box m16-s
										</span>
										<span className="token punctuation">
											"
										</span>
										<span className="token punctuation">
											&gt;
										</span>
										<span className="token html-value">
											item2
										</span>
										<span className="token punctuation">
											&lt;/
										</span>
										<span className="token tag">div</span>
										<span className="token punctuation">
											&gt;
										</span>
									</code>{" "}
									<code className="language-html">
										<span>&nbsp;&nbsp;&nbsp;</span>
										<span className="token punctuation">
											&lt;
										</span>
										<span className="token tag">
											div&nbsp;
										</span>
										<span className="token attr-name">
											class
										</span>
										<span className="token punctuation">
											="
										</span>
										<span className="token attr-value">
											item-box m16-s
										</span>
										<span className="token punctuation">
											"
										</span>
										<span className="token punctuation">
											&gt;
										</span>
										<span className="token html-value">
											item3
										</span>
										<span className="token punctuation">
											&lt;/
										</span>
										<span className="token tag">div</span>
										<span className="token punctuation">
											&gt;
										</span>
									</code>{" "}
									<code className="language-html">
										<span>&nbsp;&nbsp;&nbsp;</span>
										<span className="token punctuation">
											&lt;
										</span>
										<span className="token tag">
											div&nbsp;
										</span>
										<span className="token attr-name">
											class
										</span>
										<span className="token punctuation">
											="
										</span>
										<span className="token attr-value">
											item-box m16-s
										</span>
										<span className="token punctuation">
											"
										</span>
										<span className="token punctuation">
											&gt;
										</span>
										<span className="token html-value">
											item4
										</span>
										<span className="token punctuation">
											&lt;/
										</span>
										<span className="token tag">div</span>
										<span className="token punctuation">
											&gt;
										</span>
									</code>{" "}
									<code className="language-html">
										<span>&nbsp;&nbsp;&nbsp;</span>
										<span className="token punctuation">
											&lt;
										</span>
										<span className="token tag">
											div&nbsp;
										</span>
										<span className="token attr-name">
											class
										</span>
										<span className="token punctuation">
											="
										</span>
										<span className="token attr-value">
											item-box m16-s
										</span>
										<span className="token punctuation">
											"
										</span>
										<span className="token punctuation">
											&gt;
										</span>
										<span className="token html-value">
											item5
										</span>
										<span className="token punctuation">
											&lt;/
										</span>
										<span className="token tag">div</span>
										<span className="token punctuation">
											&gt;
										</span>
									</code>{" "}
									<code className="language-html">
										<span>&nbsp;&nbsp;&nbsp;</span>
										<span className="token punctuation">
											&lt;
										</span>
										<span className="token tag">
											div&nbsp;
										</span>
										<span className="token attr-name">
											class
										</span>
										<span className="token punctuation">
											="
										</span>
										<span className="token attr-value">
											item-box m16-s
										</span>
										<span className="token punctuation">
											"
										</span>
										<span className="token punctuation">
											&gt;
										</span>
										<span className="token html-value">
											item6
										</span>
										<span className="token punctuation">
											&lt;/
										</span>
										<span className="token tag">div</span>
										<span className="token punctuation">
											&gt;
										</span>
									</code>{" "}
									<code className="language-html">
										<span>&nbsp;&nbsp;&nbsp;</span>
										<span className="token punctuation">
											&lt;
										</span>
										<span className="token tag">
											div&nbsp;
										</span>
										<span className="token attr-name">
											class
										</span>
										<span className="token punctuation">
											="
										</span>
										<span className="token attr-value">
											item-box m16-s
										</span>
										<span className="token punctuation">
											"
										</span>
										<span className="token punctuation">
											&gt;
										</span>
										<span className="token html-value">
											item7
										</span>
										<span className="token punctuation">
											&lt;/
										</span>
										<span className="token tag">div</span>
										<span className="token punctuation">
											&gt;
										</span>
									</code>{" "}
									<code className="language-html">
										<span>&nbsp;&nbsp;&nbsp;</span>
										<span className="token punctuation">
											&lt;
										</span>
										<span className="token tag">
											div&nbsp;
										</span>
										<span className="token attr-name">
											class
										</span>
										<span className="token punctuation">
											="
										</span>
										<span className="token attr-value">
											item-box m16-s
										</span>
										<span className="token punctuation">
											"
										</span>
										<span className="token punctuation">
											&gt;
										</span>
										<span className="token html-value">
											item8
										</span>
										<span className="token punctuation">
											&lt;/
										</span>
										<span className="token tag">div</span>
										<span className="token punctuation">
											&gt;
										</span>
									</code>
									<code className="language-html">
										<span>&nbsp;&nbsp;&nbsp;</span>
										<span className="token punctuation">
											&lt;
										</span>
										<span className="token tag">
											div&nbsp;
										</span>
										<span className="token attr-name">
											class
										</span>
										<span className="token punctuation">
											="
										</span>
										<span className="token attr-value">
											item-box m16-s
										</span>
										<span className="token punctuation">
											"
										</span>
										<span className="token punctuation">
											&gt;
										</span>
										<span className="token html-value">
											item9
										</span>
										<span className="token punctuation">
											&lt;/
										</span>
										<span className="token tag">div</span>
										<span className="token punctuation">
											&gt;
										</span>
									</code>
									<code className="language-html">
										<span>&nbsp;&nbsp;&nbsp;</span>
										<span className="token punctuation">
											&lt;
										</span>
										<span className="token tag">
											div&nbsp;
										</span>
										<span className="token attr-name">
											class
										</span>
										<span className="token punctuation">
											="
										</span>
										<span className="token attr-value">
											item-box m16-s
										</span>
										<span className="token punctuation">
											"
										</span>
										<span className="token punctuation">
											&gt;
										</span>
										<span className="token html-value">
											item10
										</span>
										<span className="token punctuation">
											&lt;/
										</span>
										<span className="token tag">div</span>
										<span className="token punctuation">
											&gt;
										</span>
									</code>
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">div</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<div className="toolbar">
								<div className="toolbar-item">
									<span>HTML</span>
								</div>
							</div>
						</pre>
						<div className="f-container-demo fww-s">
							<div className="item-box m16-s">item1</div>
							<div className="item-box m16-s">item2</div>
							<div className="item-box m16-s">item3</div>
							<div className="item-box m16-s">item4</div>
							<div className="item-box m16-s">item5</div>
							<div className="item-box m16-s">item6</div>
							<div className="item-box m16-s">item7</div>
							<div className="item-box m16-s">item8</div>
							<div className="item-box m16-s">item9</div>
							<div className="item-box m16-s">item10</div>
						</div>
						<p className="main-text">
							<code>flex-wrap: wrap-reverse</code> es tambien
							multilinea pero de abajo a arriba, al contrario que
							wrap.
						</p>
						<pre className="language-html code-toolbar">
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">div&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">f-container fwwr-s</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									<code className="language-html">
										<span>&nbsp;&nbsp;&nbsp;</span>
										<span className="token punctuation">
											&lt;
										</span>
										<span className="token tag">
											div&nbsp;
										</span>
										<span className="token attr-name">
											class
										</span>
										<span className="token punctuation">
											="
										</span>
										<span className="token attr-value">
											item-box m16-s
										</span>
										<span className="token punctuation">
											"
										</span>
										<span className="token punctuation">
											&gt;
										</span>
										<span className="token html-value">
											item1
										</span>
										<span className="token punctuation">
											&lt;/
										</span>
										<span className="token tag">div</span>
										<span className="token punctuation">
											&gt;
										</span>
									</code>{" "}
									<code className="language-html">
										<span>&nbsp;&nbsp;&nbsp;</span>
										<span className="token punctuation">
											&lt;
										</span>
										<span className="token tag">
											div&nbsp;
										</span>
										<span className="token attr-name">
											class
										</span>
										<span className="token punctuation">
											="
										</span>
										<span className="token attr-value">
											item-box m16-s
										</span>
										<span className="token punctuation">
											"
										</span>
										<span className="token punctuation">
											&gt;
										</span>
										<span className="token html-value">
											item2
										</span>
										<span className="token punctuation">
											&lt;/
										</span>
										<span className="token tag">div</span>
										<span className="token punctuation">
											&gt;
										</span>
									</code>{" "}
									<code className="language-html">
										<span>&nbsp;&nbsp;&nbsp;</span>
										<span className="token punctuation">
											&lt;
										</span>
										<span className="token tag">
											div&nbsp;
										</span>
										<span className="token attr-name">
											class
										</span>
										<span className="token punctuation">
											="
										</span>
										<span className="token attr-value">
											item-box m16-s
										</span>
										<span className="token punctuation">
											"
										</span>
										<span className="token punctuation">
											&gt;
										</span>
										<span className="token html-value">
											item3
										</span>
										<span className="token punctuation">
											&lt;/
										</span>
										<span className="token tag">div</span>
										<span className="token punctuation">
											&gt;
										</span>
									</code>{" "}
									<code className="language-html">
										<span>&nbsp;&nbsp;&nbsp;</span>
										<span className="token punctuation">
											&lt;
										</span>
										<span className="token tag">
											div&nbsp;
										</span>
										<span className="token attr-name">
											class
										</span>
										<span className="token punctuation">
											="
										</span>
										<span className="token attr-value">
											item-box m16-s
										</span>
										<span className="token punctuation">
											"
										</span>
										<span className="token punctuation">
											&gt;
										</span>
										<span className="token html-value">
											item4
										</span>
										<span className="token punctuation">
											&lt;/
										</span>
										<span className="token tag">div</span>
										<span className="token punctuation">
											&gt;
										</span>
									</code>{" "}
									<code className="language-html">
										<span>&nbsp;&nbsp;&nbsp;</span>
										<span className="token punctuation">
											&lt;
										</span>
										<span className="token tag">
											div&nbsp;
										</span>
										<span className="token attr-name">
											class
										</span>
										<span className="token punctuation">
											="
										</span>
										<span className="token attr-value">
											item-box m16-s
										</span>
										<span className="token punctuation">
											"
										</span>
										<span className="token punctuation">
											&gt;
										</span>
										<span className="token html-value">
											item5
										</span>
										<span className="token punctuation">
											&lt;/
										</span>
										<span className="token tag">div</span>
										<span className="token punctuation">
											&gt;
										</span>
									</code>{" "}
									<code className="language-html">
										<span>&nbsp;&nbsp;&nbsp;</span>
										<span className="token punctuation">
											&lt;
										</span>
										<span className="token tag">
											div&nbsp;
										</span>
										<span className="token attr-name">
											class
										</span>
										<span className="token punctuation">
											="
										</span>
										<span className="token attr-value">
											item-box m16-s
										</span>
										<span className="token punctuation">
											"
										</span>
										<span className="token punctuation">
											&gt;
										</span>
										<span className="token html-value">
											item6
										</span>
										<span className="token punctuation">
											&lt;/
										</span>
										<span className="token tag">div</span>
										<span className="token punctuation">
											&gt;
										</span>
									</code>{" "}
									<code className="language-html">
										<span>&nbsp;&nbsp;&nbsp;</span>
										<span className="token punctuation">
											&lt;
										</span>
										<span className="token tag">
											div&nbsp;
										</span>
										<span className="token attr-name">
											class
										</span>
										<span className="token punctuation">
											="
										</span>
										<span className="token attr-value">
											item-box m16-s
										</span>
										<span className="token punctuation">
											"
										</span>
										<span className="token punctuation">
											&gt;
										</span>
										<span className="token html-value">
											item7
										</span>
										<span className="token punctuation">
											&lt;/
										</span>
										<span className="token tag">div</span>
										<span className="token punctuation">
											&gt;
										</span>
									</code>{" "}
									<code className="language-html">
										<span>&nbsp;&nbsp;&nbsp;</span>
										<span className="token punctuation">
											&lt;
										</span>
										<span className="token tag">
											div&nbsp;
										</span>
										<span className="token attr-name">
											class
										</span>
										<span className="token punctuation">
											="
										</span>
										<span className="token attr-value">
											item-box m16-s
										</span>
										<span className="token punctuation">
											"
										</span>
										<span className="token punctuation">
											&gt;
										</span>
										<span className="token html-value">
											item8
										</span>
										<span className="token punctuation">
											&lt;/
										</span>
										<span className="token tag">div</span>
										<span className="token punctuation">
											&gt;
										</span>
									</code>
									<code className="language-html">
										<span>&nbsp;&nbsp;&nbsp;</span>
										<span className="token punctuation">
											&lt;
										</span>
										<span className="token tag">
											div&nbsp;
										</span>
										<span className="token attr-name">
											class
										</span>
										<span className="token punctuation">
											="
										</span>
										<span className="token attr-value">
											item-box m16-s
										</span>
										<span className="token punctuation">
											"
										</span>
										<span className="token punctuation">
											&gt;
										</span>
										<span className="token html-value">
											item9
										</span>
										<span className="token punctuation">
											&lt;/
										</span>
										<span className="token tag">div</span>
										<span className="token punctuation">
											&gt;
										</span>
									</code>
									<code className="language-html">
										<span>&nbsp;&nbsp;&nbsp;</span>
										<span className="token punctuation">
											&lt;
										</span>
										<span className="token tag">
											div&nbsp;
										</span>
										<span className="token attr-name">
											class
										</span>
										<span className="token punctuation">
											="
										</span>
										<span className="token attr-value">
											item-box m16-s
										</span>
										<span className="token punctuation">
											"
										</span>
										<span className="token punctuation">
											&gt;
										</span>
										<span className="token html-value">
											item10
										</span>
										<span className="token punctuation">
											&lt;/
										</span>
										<span className="token tag">div</span>
										<span className="token punctuation">
											&gt;
										</span>
									</code>
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">div</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<div className="toolbar">
								<div className="toolbar-item">
									<span>HTML</span>
								</div>
							</div>
						</pre>
						<div className="f-container-demo fwwr-s">
							<div className="item-box m16-s">item1</div>
							<div className="item-box m16-s">item2</div>
							<div className="item-box m16-s">item3</div>
							<div className="item-box m16-s">item4</div>
							<div className="item-box m16-s">item5</div>
							<div className="item-box m16-s">item6</div>
							<div className="item-box m16-s">item7</div>
							<div className="item-box m16-s">item8</div>
							<div className="item-box m16-s">item9</div>
							<div className="item-box m16-s">item10</div>
						</div>
					</div>
					<Order />
				</section>
			</main>
		</>
	);
};

export default Flexbox;
