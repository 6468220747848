import React from "react";
import Aside from "../Aside";

const Position = () => {
	return (
		<>
			<main className="main-content g-container gtc4-s gcg1-s">
				<Aside />
				<section className="main-section gcss3-s">
					<div className="l-block-2"></div>
					<h2>Bordes</h2>
					<div className="main-block">
						<p className="main-text">
							Clases de posicion disponibles:
							<ul>
								<li>relative</li>
								<li>absolute</li>
								<li>static</li>
								<li>sticky</li>
							</ul>
							Se pueden utilizar con breakpoints agregandoles la
							medida hasta la que queremos que se apliquen o sin
							breakpoint. Por ejemplo <code>absolute-lg</code> que
							se aplicaría hasta el tamaño lg, o{" "}
							<code>absolute</code>
							que se aplicaría siempre.{" "}
						</p>{" "}
						<pre className="language-html code-toolbar">
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">div&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">
									relative
								</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									position: relative
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">div</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">div&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">
									absolute
								</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									position: absolute
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">div</span>
								<span className="token punctuation">&gt;</span>
							</code>{" "}
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">div&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">static</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									position: static
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">div</span>
								<span className="token punctuation">&gt;</span>
							</code>{" "}
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">div&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">sticky</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									position: sticky
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">div</span>
								<span className="token punctuation">&gt;</span>
							</code>{" "}
							<div className="toolbar">
								<div className="toolbar-item">
									<span>HTML</span>
								</div>
							</div>
						</pre>
						<p className="main-text">
							Para la clase absolute tambien disponemos de las
							clases <code>top, bottom, left, right</code> más el
							número que se quiere aplicar. Por ejemplo, si
							queremos colocar una elemento a 50px del borde superior, la clase
							para hacerlo seria <code>top50</code>, o si se le
							añade breakpoint <code>top50-ipad</code>. El rango
							de los pixeles para estas clases van de{" "}
							<code>0 a 300</code> en cualquiera de las cuatro
							clases.
						</p>
					</div>
				</section>
			</main>
		</>
	);
};

export default Position;
