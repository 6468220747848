import React from "react";
import Home from "./main-content/Home";
import { Route, Switch } from "react-router-dom";
import Alignment from "./main-content/Alignment";
import Button from "./main-content/Button";
import Border from "./main-content/Border";
import Display from "./main-content/Display";
import Flexbox from "./main-content/Flexbox";
import Grid from "./main-content/Grid";
import Margin from "./main-content/Margin";
import Padding from "./main-content/Padding";
import Size from "./main-content/Size";
import Text from "./main-content/Text";
import Typography from "./main-content/Typography";
import Table from "./main-content/Table";
import Form from "./main-content/Form";
import Query from "./main-content/Query";
import Functions from "./main-content/Functions";
import Instalation from "./main-content/Instalation";
import Position from "./main-content/Position";

const Main = () => {
	return (
		<>
			<Switch>
				<Route exact path="/" component={Home} />
				<Route exact path="/instalation" component={Instalation} />
				<Route exact path="/alignment" component={Alignment} />
				<Route exact path="/border" component={Border} />
				<Route exact path="/mediaquery" component={Query} />
				<Route exact path="/display" component={Display} />
				<Route exact path="/flexbox" component={Flexbox} />
				<Route exact path="/grid" component={Grid} />
				<Route exact path="/margin" component={Margin} />
				<Route exact path="/padding" component={Padding} />
				<Route exact path="/position" component={Position} />
				<Route exact path="/size" component={Size} />
				<Route exact path="/text" component={Text} />
				<Route exact path="/typography" component={Typography} />
				<Route exact path="/functions" component={Functions} />
				<Route exact path="/button" component={Button} />
				<Route exact path="/table" component={Table} />
				<Route exact path="/form" component={Form} />
			</Switch>
		</>
	);
};

export default Main;
