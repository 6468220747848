import React from "react";
import Aside from "../Aside";

const Table = () => {
	return (
		<>
			<main className="main-content g-container gtc4-s gcg1-s">
				<Aside />
				<section className="main-section gcss3-s">
						<h2>Tablas</h2>
					<div className="l-block-2"></div>
					<div className="main-block">
						<p className="main-text">Se agregararán proximamente.</p>
					</div>
				</section>
			</main>
		</>
	);
};

export default Table;
