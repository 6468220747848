import React from "react";
import Aside from "../Aside";

const Form = () => {
	return (
		<>
			<main className="main-content g-container gtc4-s gcg1-s">
				<Aside />
				<section className="main-section gcss3-s">
					<h2>Formulario</h2>
					<div className="main-block">
						<p className="main-text">
							La principal regla para utilizar componentes de
							formulario y aplicarles los estilos ui-form es que
							tienen que tener un componente padre con la clase
							<cpde>form</cpde>. El componente padre deberia de
							ser
							<code>form</code>, aunque funciona con otras
							etiquetas html. Para crear un formulario se disponen
							de varios componentes. Se les puede aplicar
							diferentes colores a los bordes y a los botones por
							separado, aplicando una clase como por ejemplo
							<code>red-form-color</code> o las de los botones
							<code>red-button-color</code>. La lista de colores
							esta al final de la página. Por defcto los bordes
							son grises y los botones azules. Los componentes
							disponibles para crear formularios y otros
							componentes, son los que se muestran a continuacion.
						</p>
						<h3>Tipo texto</h3>
						<form class="form">
							<input
								type="text"
								placeholder="Introduce texto"
								className="input-box"
							/>
						</form>
						<pre className="language-html code-toolbar">
							<code class="language-html">
								<span class="token punctuation">&lt;</span>
								<span class="token tag">form&nbsp;</span>
								<span class="token attr-name">class</span>
								<span class="token punctuation">="</span>
								<span class="token attr-value">form</span>
								<span class="token punctuation">"</span>
								<span class="token punctuation">&gt;</span>
								<span class="token html-value">
									<code className="language-html">
										<span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
										<span className="token punctuation">
											&lt;
										</span>
										<span className="token tag">
											input&nbsp;
										</span>
										<span className="token attr-name">
											type
										</span>
										<span className="token punctuation">
											="
										</span>
										<span className="token attr-value">
											text
										</span>
										<span className="token punctuation">
											"
										</span>
										<span className="token attr-name">
											&nbsp;placeholder
										</span>
										<span className="token punctuation">
											="
										</span>
										<span className="token attr-value">
											Introduce texto
										</span>
										<span className="token punctuation">
											"
										</span>
										<span className="token attr-name">
											&nbsp;class
										</span>
										<span className="token punctuation">
											="
										</span>
										<span className="token attr-value">
											input-box
										</span>
										<span className="token punctuation">
											"
										</span>
										<span className="token punctuation">
											/
										</span>
										<span className="token punctuation">
											&gt;
										</span>
									</code>
								</span>
								<span class="token punctuation">&lt;/</span>
								<span class="token tag">form</span>
								<span class="token punctuation">&gt;</span>
							</code>
							<div className="toolbar">
								<div className="toolbar-item">
									<span>HTML</span>
								</div>
							</div>
						</pre>
						<div className="l-block-2"></div>
						<h3>Tipo email</h3>
						<form class="form">
							<input
								type="email"
								placeholder="Introduce email"
								className="input-box"
							/>
						</form>
						<pre className="language-html code-toolbar">
							<code class="language-html">
								<span class="token punctuation">&lt;</span>
								<span class="token tag">form&nbsp;</span>
								<span class="token attr-name">class</span>
								<span class="token punctuation">="</span>
								<span class="token attr-value">form</span>
								<span class="token punctuation">"</span>
								<span class="token punctuation">&gt;</span>
								<span class="token html-value">
									<code className="language-html">
										<span>&nbsp;&nbsp;&nbsp;&nbsp;</span>{" "}
										<span className="token punctuation">
											&lt;
										</span>
										<span className="token tag">
											input&nbsp;
										</span>
										<span className="token attr-name">
											type
										</span>
										<span className="token punctuation">
											="
										</span>
										<span className="token attr-value">
											email
										</span>
										<span className="token punctuation">
											"
										</span>
										<span className="token attr-name">
											&nbsp;placeholder
										</span>
										<span className="token punctuation">
											="
										</span>
										<span className="token attr-value">
											Introduce email
										</span>
										<span className="token punctuation">
											"
										</span>
										<span className="token attr-name">
											&nbsp;class
										</span>
										<span className="token punctuation">
											="
										</span>
										<span className="token attr-value">
											input-box
										</span>
										<span className="token punctuation">
											"
										</span>
										<span className="token punctuation">
											/
										</span>
										<span className="token punctuation">
											&gt;
										</span>
									</code>
								</span>
								<span class="token punctuation">&lt;/</span>
								<span class="token tag">form</span>
								<span class="token punctuation">&gt;</span>
							</code>
							<div className="toolbar">
								<div className="toolbar-item">
									<span>HTML</span>
								</div>
							</div>
						</pre>
						<div className="l-block-2"></div>
						<h3>Tipo password</h3>
						<form class="form">
							<input
								type="password"
								placeholder="Contraseña"
								className="input-box"
							/>
						</form>
						<pre className="language-html code-toolbar">
							<code class="language-html">
								<span class="token punctuation">&lt;</span>
								<span class="token tag">form&nbsp;</span>
								<span class="token attr-name">class</span>
								<span class="token punctuation">="</span>
								<span class="token attr-value">form</span>
								<span class="token punctuation">"</span>
								<span class="token punctuation">&gt;</span>
								<span class="token html-value">
									<code className="language-html">
										<span>&nbsp;&nbsp;&nbsp;&nbsp;</span>{" "}
										<span className="token punctuation">
											&lt;
										</span>
										<span className="token tag">
											input&nbsp;
										</span>
										<span className="token attr-name">
											type
										</span>
										<span className="token punctuation">
											="
										</span>
										<span className="token attr-value">
											password
										</span>
										<span className="token punctuation">
											"
										</span>
										<span className="token attr-name">
											&nbsp;placeholder
										</span>
										<span className="token punctuation">
											="
										</span>
										<span className="token attr-value">
											Introduce contraseña
										</span>
										<span className="token punctuation">
											"
										</span>
										<span className="token attr-name">
											&nbsp;class
										</span>
										<span className="token punctuation">
											="
										</span>
										<span className="token attr-value">
											input-box
										</span>
										<span className="token punctuation">
											"
										</span>
										<span className="token punctuation">
											/
										</span>
										<span className="token punctuation">
											&gt;
										</span>
									</code>
								</span>
								<span class="token punctuation">&lt;/</span>
								<span class="token tag">form</span>
								<span class="token punctuation">&gt;</span>
							</code>
							<div className="toolbar">
								<div className="toolbar-item">
									<span>HTML</span>
								</div>
							</div>
						</pre>
						<div className="l-block-2"></div>
						<h3>Textarea</h3>
						<form class="form">
							<textarea
								name="textarea"
								rows="10"
								cols="50"
								className="input-box"
								placeholder="Escribe tu texto"
							/>
						</form>
						<pre className="language-html code-toolbar">
							<code class="language-html">
								<span class="token punctuation">&lt;</span>
								<span class="token tag">form&nbsp;</span>
								<span class="token attr-name">class</span>
								<span class="token punctuation">="</span>
								<span class="token attr-value">form</span>
								<span class="token punctuation">"</span>
								<span class="token punctuation">&gt;</span>
								<span class="token html-value">
									<code className="language-html">
										<span>&nbsp;&nbsp;&nbsp;&nbsp;</span>{" "}
										<span className="token punctuation">
											&lt;
										</span>
										<span className="token tag">
											textarea&nbsp;
										</span>
										<span className="token attr-name">
											name
										</span>
										<span className="token punctuation">
											="
										</span>
										<span className="token attr-value">
											textarea
										</span>
										<span className="token punctuation">
											"
										</span>
										<span className="token attr-name">
											&nbsp;rows
										</span>
										<span className="token punctuation">
											="
										</span>
										<span className="token attr-value">
											10
										</span>
										<span className="token punctuation">
											"
										</span>
										<span className="token attr-name">
											&nbsp;cols
										</span>
										<span className="token punctuation">
											="
										</span>
										<span className="token attr-value">
											50
										</span>
										<span className="token punctuation">
											"
										</span>
										<span className="token attr-name">
											&nbsp;placeholder
										</span>
										<span className="token punctuation">
											="
										</span>
										<span className="token attr-value">
											Escribe tu texto
										</span>
										<span className="token punctuation">
											"
										</span>
										<span className="token attr-name">
											&nbsp;class
										</span>
										<span className="token punctuation">
											="
										</span>
										<span className="token attr-value">
											input-box
										</span>
										<span className="token punctuation">
											"
										</span>
										<span className="token punctuation">
											/
										</span>
										<span className="token punctuation">
											&gt;
										</span>
									</code>
								</span>
								<span class="token punctuation">&lt;/</span>
								<span class="token tag">form</span>
								<span class="token punctuation">&gt;</span>
							</code>
							<div className="toolbar">
								<div className="toolbar-item">
									<span>HTML</span>
								</div>
							</div>
						</pre>
						<div className="l-block-2"></div>
						<h3>Tipo radio</h3>
						<form class="form">
							<label className="label-radio">
								<input
									type="radio"
									name="radio-button"
									className="input"
								/>
								<span>Radio</span>
							</label>
						</form>
						<pre className="language-html code-toolbar">
							<code class="language-html">
								<span class="token punctuation">&lt;</span>
								<span class="token tag">form&nbsp;</span>
								<span class="token attr-name">class</span>
								<span class="token punctuation">="</span>
								<span class="token attr-value">form</span>
								<span class="token punctuation">"</span>
								<span class="token punctuation">&gt;</span>
								<span class="token html-value">
									<code className="language-html">
										<span>&nbsp;&nbsp;</span>{" "}
										<span className="token punctuation">
											&lt;
										</span>
										<span className="token tag">
											label&nbsp;
										</span>
										<span className="token attr-name">
											&nbsp;class
										</span>
										<span className="token punctuation">
											="
										</span>
										<span className="token attr-value">
											label-radio
										</span>
										<span className="token punctuation">
											"
										</span>
										<span className="token punctuation">
											&gt;
										</span>
									</code>
									<code className="language-html">
										<span>&nbsp;&nbsp;</span>{" "}
										<span className="token punctuation">
											&nbsp;&nbsp;&lt;
										</span>
										<span className="token tag">
											input&nbsp;
										</span>
										<span className="token attr-name">
											type
										</span>
										<span className="token punctuation">
											="
										</span>
										<span className="token attr-value">
											radio
										</span>
										<span className="token punctuation">
											"
										</span>
										<span className="token attr-name">
											&nbsp;name
										</span>
										<span className="token punctuation">
											="
										</span>
										<span className="token attr-value">
											radio-button
										</span>
										<span className="token punctuation">
											"
										</span>
										<span className="token attr-name">
											&nbsp;class
										</span>
										<span className="token punctuation">
											="
										</span>
										<span className="token attr-value">
											input
										</span>
										<span className="token punctuation">
											"
										</span>
										<span className="token punctuation">
											/
										</span>
										<span className="token punctuation">
											&gt;
										</span>
									</code>
									<code className="language-html">
										<span>
											&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
										</span>
										<span className="token punctuation">
											&lt;
										</span>
										<span className="token tag">span</span>
										<span className="token punctuation">
											&gt;
										</span>
										<span className="token html-value">
											Radio
										</span>
										<span className="token punctuation">
											&lt;
										</span>
										<span className="token punctuation">
											/
										</span>
										<span className="token tag">span</span>
										<span className="token punctuation">
											&gt;
										</span>
									</code>
									<code className="language-html">
										<span>&nbsp;&nbsp;&nbsp;</span>
										<span className="token punctuation">
											&lt;
										</span>
										<span className="token punctuation">
											/
										</span>
										<span className="token tag">label</span>
										<span className="token punctuation">
											&gt;
										</span>
									</code>
								</span>
								<span class="token punctuation">&lt;/</span>
								<span class="token tag">form</span>
								<span class="token punctuation">&gt;</span>
							</code>
							<div className="toolbar">
								<div className="toolbar-item">
									<span>HTML</span>
								</div>
							</div>
						</pre>
						<div className="l-block-2"></div>
						<h3>Tipo button</h3>
						<form class="form">
							<input
								type="button"
								value="button"
								className="button green-button-color w100 mtb16-s"
							/>
						</form>
						<pre className="language-html code-toolbar">
							<code class="language-html">
								<span class="token punctuation">&lt;</span>
								<span class="token tag">form&nbsp;</span>
								<span class="token attr-name">class</span>
								<span class="token punctuation">="</span>
								<span class="token attr-value">form</span>
								<span class="token punctuation">"</span>
								<span class="token punctuation">&gt;</span>
								<span class="token html-value">
									<code className="language-html">
										<span>&nbsp;&nbsp;&nbsp;&nbsp;</span>{" "}
										<span className="token punctuation">
											&lt;
										</span>
										<span className="token tag">
											input&nbsp;
										</span>
										<span className="token attr-name">
											type
										</span>
										<span className="token punctuation">
											="
										</span>
										<span className="token attr-value">
											button
										</span>
										<span className="token punctuation">
											"
										</span>
										<span className="token attr-name">
											&nbsp;value
										</span>
										<span className="token punctuation">
											="
										</span>
										<span className="token attr-value">
											button
										</span>
										<span className="token punctuation">
											"
										</span>
										<span className="token attr-name">
											&nbsp;class
										</span>
										<span className="token punctuation">
											="
										</span>
										<span className="token attr-value">
											button green-button-color w100
											mtb16-s
										</span>
										<span className="token punctuation">
											"
										</span>
										<span className="token punctuation">
											/
										</span>
										<span className="token punctuation">
											&gt;
										</span>
									</code>
								</span>
								<span class="token punctuation">&lt;/</span>
								<span class="token tag">form</span>
								<span class="token punctuation">&gt;</span>
							</code>
							<div className="toolbar">
								<div className="toolbar-item">
									<span>HTML</span>
								</div>
							</div>
						</pre>
						<div className="l-block-2"></div>
						<h3>Tipo button</h3>
						<form class="form">
							<label className="label-checkbox">
								<input type="checkbox" className="input" />
								<span>Checkbox</span>
							</label>
						</form>
						<pre className="language-html code-toolbar">
							<code class="language-html">
								<span class="token punctuation">&lt;</span>
								<span class="token tag">form&nbsp;</span>
								<span class="token attr-name">class</span>
								<span class="token punctuation">="</span>
								<span class="token attr-value">form</span>
								<span class="token punctuation">"</span>
								<span class="token punctuation">&gt;</span>
								<span class="token html-value">
									<code className="language-html">
										<span>&nbsp;&nbsp;</span>{" "}
										<span className="token punctuation">
											&lt;
										</span>
										<span className="token tag">
											label&nbsp;
										</span>
										<span className="token attr-name">
											&nbsp;class
										</span>
										<span className="token punctuation">
											="
										</span>
										<span className="token attr-value">
											label-checkbox
										</span>
										<span className="token punctuation">
											"
										</span>
										<span className="token punctuation">
											&gt;
										</span>
									</code>
									<code className="language-html">
										<span>&nbsp;&nbsp;&nbsp;&nbsp;</span>{" "}
										<span className="token punctuation">
											&lt;
										</span>
										<span className="token tag">
											input&nbsp;
										</span>
										<span className="token attr-name">
											type
										</span>
										<span className="token punctuation">
											="
										</span>
										<span className="token attr-value">
											checkbox
										</span>
										<span className="token punctuation">
											"
										</span>
										<span className="token attr-name">
											&nbsp;class
										</span>
										<span className="token punctuation">
											="
										</span>
										<span className="token attr-value">
											input
										</span>
										<span className="token punctuation">
											"
										</span>
										<span className="token punctuation">
											/
										</span>
										<span className="token punctuation">
											&gt;
										</span>
									</code>
									<code className="language-html">
										<span>&nbsp;&nbsp;&nbsp;&nbsp;</span>{" "}
										<span className="token punctuation">
											&lt;
										</span>
										<span className="token tag">span</span>
										<span className="token punctuation">
											&gt;
										</span>
										<span className="token html-value">
											Checkbox
										</span>
										<span className="token punctuation">
											&lt;
										</span>
										<span className="token punctuation">
											/
										</span>
										<span className="token tag">span</span>
										<span className="token punctuation">
											&gt;
										</span>
									</code>
									<code className="language-html">
										<span>&nbsp;&nbsp;</span>{" "}
										<span className="token punctuation">
											&lt;
										</span>
										<span className="token punctuation">
											/
										</span>
										<span className="token tag">label</span>
										<span className="token punctuation">
											&gt;
										</span>
									</code>
								</span>
								<span class="token punctuation">&lt;/</span>
								<span class="token tag">form</span>
								<span class="token punctuation">&gt;</span>
							</code>
							<div className="toolbar">
								<div className="toolbar-item">
									<span>HTML</span>
								</div>
							</div>
						</pre>
						<div className="l-block-2"></div>
						<h3>Tipo color</h3>
						<form class="form">
							<input type="color" className="input" />
						</form>
						<pre className="language-html code-toolbar">
							<code class="language-html">
								<span class="token punctuation">&lt;</span>
								<span class="token tag">form&nbsp;</span>
								<span class="token attr-name">class</span>
								<span class="token punctuation">="</span>
								<span class="token attr-value">form</span>
								<span class="token punctuation">"</span>
								<span class="token punctuation">&gt;</span>
								<span class="token html-value">
									<code className="language-html">
										<span>&nbsp;&nbsp;</span>{" "}
										<span className="token punctuation">
											&lt;
										</span>
										<span className="token tag">
											input&nbsp;
										</span>
										<span className="token attr-name">
											type
										</span>
										<span className="token punctuation">
											="
										</span>
										<span className="token attr-value">
											color
										</span>
										<span className="token punctuation">
											"
										</span>
										<span className="token attr-name">
											&nbsp;class
										</span>
										<span className="token punctuation">
											="
										</span>
										<span className="token attr-value">
											input
										</span>
										<span className="token punctuation">
											"
										</span>
										<span className="token punctuation">
											/
										</span>
										<span className="token punctuation">
											&gt;
										</span>
									</code>
								</span>
								<span class="token punctuation">&lt;/</span>
								<span class="token tag">form</span>
								<span class="token punctuation">&gt;</span>
							</code>
							<div className="toolbar">
								<div className="toolbar-item">
									<span>HTML</span>
								</div>
							</div>
						</pre>
						<div className="l-block-2"></div>
						<h3>Tipo date</h3>
						<form class="form">
							<input type="date" className="input-box" />
						</form>
						<pre className="language-html code-toolbar">
							<code class="language-html">
								<span class="token punctuation">&lt;</span>
								<span class="token tag">form&nbsp;</span>
								<span class="token attr-name">class</span>
								<span class="token punctuation">="</span>
								<span class="token attr-value">form</span>
								<span class="token punctuation">"</span>
								<span class="token punctuation">&gt;</span>
								<span class="token html-value">
									<code className="language-html">
										<span>&nbsp;&nbsp;</span>{" "}
										<span className="token punctuation">
											&lt;
										</span>
										<span className="token tag">
											input&nbsp;
										</span>
										<span className="token attr-name">
											type
										</span>
										<span className="token punctuation">
											="
										</span>
										<span className="token attr-value">
											date
										</span>
										<span className="token punctuation">
											"
										</span>
										<span className="token attr-name">
											&nbsp;class
										</span>
										<span className="token punctuation">
											="
										</span>
										<span className="token attr-value">
											input-box
										</span>
										<span className="token punctuation">
											"
										</span>
										<span className="token punctuation">
											/
										</span>
										<span className="token punctuation">
											&gt;
										</span>
									</code>
								</span>
								<span class="token punctuation">&lt;/</span>
								<span class="token tag">form</span>
								<span class="token punctuation">&gt;</span>
							</code>
							<div className="toolbar">
								<div className="toolbar-item">
									<span>HTML</span>
								</div>
							</div>
						</pre>
						<div className="l-block-2"></div>
						<h3>Tipo datetime-local</h3>
						<form class="form">
							<input
								type="datetime-local"
								className="input-box"
							/>
						</form>
						<pre className="language-html code-toolbar">
							<code class="language-html">
								<span class="token punctuation">&lt;</span>
								<span class="token tag">form&nbsp;</span>
								<span class="token attr-name">class</span>
								<span class="token punctuation">="</span>
								<span class="token attr-value">form</span>
								<span class="token punctuation">"</span>
								<span class="token punctuation">&gt;</span>
								<span class="token html-value">
									<code className="language-html">
										<span>&nbsp;&nbsp;</span>{" "}
										<span className="token punctuation">
											&lt;
										</span>
										<span className="token tag">
											input&nbsp;
										</span>
										<span className="token attr-name">
											type
										</span>
										<span className="token punctuation">
											="
										</span>
										<span className="token attr-value">
											datetime-local
										</span>
										<span className="token punctuation">
											"
										</span>
										<span className="token attr-name">
											&nbsp;class
										</span>
										<span className="token punctuation">
											="
										</span>
										<span className="token attr-value">
											input-box
										</span>
										<span className="token punctuation">
											"
										</span>
										<span className="token punctuation">
											/
										</span>
										<span className="token punctuation">
											&gt;
										</span>
									</code>
								</span>
								<span class="token punctuation">&lt;/</span>
								<span class="token tag">form</span>
								<span class="token punctuation">&gt;</span>
							</code>
							<div className="toolbar">
								<div className="toolbar-item">
									<span>HTML</span>
								</div>
							</div>
						</pre>
						<div className="l-block-2"></div>
						<h3>Tipo file</h3>
						<form class="form">
							<input type="file" className="input-box" />
						</form>
						<pre className="language-html code-toolbar">
							<code class="language-html">
								<span class="token punctuation">&lt;</span>
								<span class="token tag">form&nbsp;</span>
								<span class="token attr-name">class</span>
								<span class="token punctuation">="</span>
								<span class="token attr-value">form</span>
								<span class="token punctuation">"</span>
								<span class="token punctuation">&gt;</span>
								<span class="token html-value">
									<code className="language-html">
										<span>&nbsp;&nbsp;</span>{" "}
										<span className="token punctuation">
											&lt;
										</span>
										<span className="token tag">
											input&nbsp;
										</span>
										<span className="token attr-name">
											type
										</span>
										<span className="token punctuation">
											="
										</span>
										<span className="token attr-value">
											file
										</span>
										<span className="token punctuation">
											"
										</span>
										<span className="token attr-name">
											&nbsp;class
										</span>
										<span className="token punctuation">
											="
										</span>
										<span className="token attr-value">
											input-box
										</span>
										<span className="token punctuation">
											"
										</span>
										<span className="token punctuation">
											/
										</span>
										<span className="token punctuation">
											&gt;
										</span>
									</code>
								</span>
								<span class="token punctuation">&lt;/</span>
								<span class="token tag">form</span>
								<span class="token punctuation">&gt;</span>
							</code>
							<div className="toolbar">
								<div className="toolbar-item">
									<span>HTML</span>
								</div>
							</div>
						</pre>
						<div className="l-block-2"></div>
						<h3>Tipo month</h3>
						<form class="form">
							<input type="month" className="input-box" />
						</form>
						<pre className="language-html code-toolbar">
							<code class="language-html">
								<span class="token punctuation">&lt;</span>
								<span class="token tag">form&nbsp;</span>
								<span class="token attr-name">class</span>
								<span class="token punctuation">="</span>
								<span class="token attr-value">form</span>
								<span class="token punctuation">"</span>
								<span class="token punctuation">&gt;</span>
								<span class="token html-value">
									<code className="language-html">
										<span>&nbsp;&nbsp;</span>{" "}
										<span className="token punctuation">
											&lt;
										</span>
										<span className="token tag">
											input&nbsp;
										</span>
										<span className="token attr-name">
											type
										</span>
										<span className="token punctuation">
											="
										</span>
										<span className="token attr-value">
											month
										</span>
										<span className="token punctuation">
											"
										</span>
										<span className="token attr-name">
											&nbsp;class
										</span>
										<span className="token punctuation">
											="
										</span>
										<span className="token attr-value">
											input-box
										</span>
										<span className="token punctuation">
											"
										</span>
										<span className="token punctuation">
											/
										</span>
										<span className="token punctuation">
											&gt;
										</span>
									</code>
								</span>
								<span class="token punctuation">&lt;/</span>
								<span class="token tag">form</span>
								<span class="token punctuation">&gt;</span>
							</code>
							<div className="toolbar">
								<div className="toolbar-item">
									<span>HTML</span>
								</div>
							</div>
						</pre>
						<div className="l-block-2"></div>
						<h3>Tipo number</h3>
						<form class="form">
							<input type="number" className="input-box" />
						</form>
						<pre className="language-html code-toolbar">
							<code class="language-html">
								<span class="token punctuation">&lt;</span>
								<span class="token tag">form&nbsp;</span>
								<span class="token attr-name">class</span>
								<span class="token punctuation">="</span>
								<span class="token attr-value">form</span>
								<span class="token punctuation">"</span>
								<span class="token punctuation">&gt;</span>
								<span class="token html-value">
									<code className="language-html">
										<span>&nbsp;&nbsp;</span>{" "}
										<span className="token punctuation">
											&lt;
										</span>
										<span className="token tag">
											input&nbsp;
										</span>
										<span className="token attr-name">
											type
										</span>
										<span className="token punctuation">
											="
										</span>
										<span className="token attr-value">
											number
										</span>
										<span className="token punctuation">
											"
										</span>
										<span className="token attr-name">
											&nbsp;class
										</span>
										<span className="token punctuation">
											="
										</span>
										<span className="token attr-value">
											input-box
										</span>
										<span className="token punctuation">
											"
										</span>
										<span className="token punctuation">
											/
										</span>
										<span className="token punctuation">
											&gt;
										</span>
									</code>
								</span>
								<span class="token punctuation">&lt;/</span>
								<span class="token tag">form</span>
								<span class="token punctuation">&gt;</span>
							</code>
							<div className="toolbar">
								<div className="toolbar-item">
									<span>HTML</span>
								</div>
							</div>
						</pre>
						<div className="l-block-2"></div>
						<h3>Tipo reset</h3>
						<form class="form">
							<input
								type="reset"
								className="button red-button-color w100 mtb16-s"
							/>
						</form>
						<pre className="language-html code-toolbar">
							<code class="language-html">
								<span class="token punctuation">&lt;</span>
								<span class="token tag">form&nbsp;</span>
								<span class="token attr-name">class</span>
								<span class="token punctuation">="</span>
								<span class="token attr-value">form</span>
								<span class="token punctuation">"</span>
								<span class="token punctuation">&gt;</span>
								<span class="token html-value">
									<code className="language-html">
										<span>&nbsp;&nbsp;</span>{" "}
										<span className="token punctuation">
											&lt;
										</span>
										<span className="token tag">
											input&nbsp;
										</span>
										<span className="token attr-name">
											type
										</span>
										<span className="token punctuation">
											="
										</span>
										<span className="token attr-value">
											reset
										</span>
										<span className="token punctuation">
											"
										</span>
										<span className="token attr-name">
											&nbsp;class
										</span>
										<span className="token punctuation">
											="
										</span>
										<span className="token attr-value">
											button red-button-color w100 mtb16-s
										</span>
										<span className="token punctuation">
											"
										</span>
										<span className="token punctuation">
											/
										</span>
										<span className="token punctuation">
											&gt;
										</span>
									</code>
								</span>
								<span class="token punctuation">&lt;/</span>
								<span class="token tag">form</span>
								<span class="token punctuation">&gt;</span>
							</code>
							<div className="toolbar">
								<div className="toolbar-item">
									<span>HTML</span>
								</div>
							</div>
						</pre>
						<div className="l-block-2"></div>
						<h3>Tipo search</h3>
						<form class="form">
							<input type="search" className="input-box" />
						</form>
						<pre className="language-html code-toolbar">
							<code class="language-html">
								<span class="token punctuation">&lt;</span>
								<span class="token tag">form&nbsp;</span>
								<span class="token attr-name">class</span>
								<span class="token punctuation">="</span>
								<span class="token attr-value">form</span>
								<span class="token punctuation">"</span>
								<span class="token punctuation">&gt;</span>
								<span class="token html-value">
									<code className="language-html">
										<span>&nbsp;&nbsp;</span>{" "}
										<span className="token punctuation">
											&lt;
										</span>
										<span className="token tag">
											input&nbsp;
										</span>
										<span className="token attr-name">
											type
										</span>
										<span className="token punctuation">
											="
										</span>
										<span className="token attr-value">
											search
										</span>
										<span className="token punctuation">
											"
										</span>
										<span className="token attr-name">
											&nbsp;class
										</span>
										<span className="token punctuation">
											="
										</span>
										<span className="token attr-value">
											input-box
										</span>
										<span className="token punctuation">
											"
										</span>
										<span className="token punctuation">
											/
										</span>
										<span className="token punctuation">
											&gt;
										</span>
									</code>
								</span>
								<span class="token punctuation">&lt;/</span>
								<span class="token tag">form</span>
								<span class="token punctuation">&gt;</span>
							</code>
							<div className="toolbar">
								<div className="toolbar-item">
									<span>HTML</span>
								</div>
							</div>
						</pre>
						<div className="l-block-2"></div>
						<h3>Tipo tel</h3>
						<form class="form">
							<input type="tel" className="input-box" />
						</form>
						<pre className="language-html code-toolbar">
							<code class="language-html">
								<span class="token punctuation">&lt;</span>
								<span class="token tag">form&nbsp;</span>
								<span class="token attr-name">class</span>
								<span class="token punctuation">="</span>
								<span class="token attr-value">form</span>
								<span class="token punctuation">"</span>
								<span class="token punctuation">&gt;</span>
								<span class="token html-value">
									<code className="language-html">
										<span>&nbsp;&nbsp;</span>{" "}
										<span className="token punctuation">
											&lt;
										</span>
										<span className="token tag">
											input&nbsp;
										</span>
										<span className="token attr-name">
											type
										</span>
										<span className="token punctuation">
											="
										</span>
										<span className="token attr-value">
											tel
										</span>
										<span className="token punctuation">
											"
										</span>
										<span className="token attr-name">
											&nbsp;class
										</span>
										<span className="token punctuation">
											="
										</span>
										<span className="token attr-value">
											input-box
										</span>
										<span className="token punctuation">
											"
										</span>
										<span className="token punctuation">
											/
										</span>
										<span className="token punctuation">
											&gt;
										</span>
									</code>
								</span>
								<span class="token punctuation">&lt;/</span>
								<span class="token tag">form</span>
								<span class="token punctuation">&gt;</span>
							</code>
							<div className="toolbar">
								<div className="toolbar-item">
									<span>HTML</span>
								</div>
							</div>
						</pre>
						<div className="l-block-2"></div>
						<h3>Tipo time</h3>
						<form class="form">
							<input type="time" className="input-box" />
						</form>
						<pre className="language-html code-toolbar">
							<code class="language-html">
								<span class="token punctuation">&lt;</span>
								<span class="token tag">form&nbsp;</span>
								<span class="token attr-name">class</span>
								<span class="token punctuation">="</span>
								<span class="token attr-value">form</span>
								<span class="token punctuation">"</span>
								<span class="token punctuation">&gt;</span>
								<span class="token html-value">
									<code className="language-html">
										<span>&nbsp;&nbsp;</span>{" "}
										<span className="token punctuation">
											&lt;
										</span>
										<span className="token tag">
											input&nbsp;
										</span>
										<span className="token attr-name">
											type
										</span>
										<span className="token punctuation">
											="
										</span>
										<span className="token attr-value">
											time
										</span>
										<span className="token punctuation">
											"
										</span>
										<span className="token attr-name">
											&nbsp;class
										</span>
										<span className="token punctuation">
											="
										</span>
										<span className="token attr-value">
											input-box
										</span>
										<span className="token punctuation">
											"
										</span>
										<span className="token punctuation">
											/
										</span>
										<span className="token punctuation">
											&gt;
										</span>
									</code>
								</span>
								<span class="token punctuation">&lt;/</span>
								<span class="token tag">form</span>
								<span class="token punctuation">&gt;</span>
							</code>
							<div className="toolbar">
								<div className="toolbar-item">
									<span>HTML</span>
								</div>
							</div>
						</pre>
						<div className="l-block-2"></div>
						<h3>Tipo url</h3>
						<form class="form">
							<input type="url" className="input-box" />
						</form>
						<pre className="language-html code-toolbar">
							<code class="language-html">
								<span class="token punctuation">&lt;</span>
								<span class="token tag">form&nbsp;</span>
								<span class="token attr-name">class</span>
								<span class="token punctuation">="</span>
								<span class="token attr-value">form</span>
								<span class="token punctuation">"</span>
								<span class="token punctuation">&gt;</span>
								<span class="token html-value">
									<code className="language-html">
										<span>&nbsp;&nbsp;</span>{" "}
										<span className="token punctuation">
											&lt;
										</span>
										<span className="token tag">
											input&nbsp;
										</span>
										<span className="token attr-name">
											type
										</span>
										<span className="token punctuation">
											="
										</span>
										<span className="token attr-value">
											url
										</span>
										<span className="token punctuation">
											"
										</span>
										<span className="token attr-name">
											&nbsp;class
										</span>
										<span className="token punctuation">
											="
										</span>
										<span className="token attr-value">
											input-box
										</span>
										<span className="token punctuation">
											"
										</span>
										<span className="token punctuation">
											/
										</span>
										<span className="token punctuation">
											&gt;
										</span>
									</code>
								</span>
								<span class="token punctuation">&lt;/</span>
								<span class="token tag">form</span>
								<span class="token punctuation">&gt;</span>
							</code>
							<div className="toolbar">
								<div className="toolbar-item">
									<span>HTML</span>
								</div>
							</div>
						</pre>
						<div className="l-block-2"></div>
						<h3>Tipo week</h3>
						<form class="form">
							<input type="week" className="input-box" />
						</form>
						<pre className="language-html code-toolbar">
							<code class="language-html">
								<span class="token punctuation">&lt;</span>
								<span class="token tag">form&nbsp;</span>
								<span class="token attr-name">class</span>
								<span class="token punctuation">="</span>
								<span class="token attr-value">form</span>
								<span class="token punctuation">"</span>
								<span class="token punctuation">&gt;</span>
								<span class="token html-value">
									<code className="language-html">
										<span>&nbsp;&nbsp;</span>{" "}
										<span className="token punctuation">
											&lt;
										</span>
										<span className="token tag">
											input&nbsp;
										</span>
										<span className="token attr-name">
											type
										</span>
										<span className="token punctuation">
											="
										</span>
										<span className="token attr-value">
											week
										</span>
										<span className="token punctuation">
											"
										</span>
										<span className="token attr-name">
											&nbsp;class
										</span>
										<span className="token punctuation">
											="
										</span>
										<span className="token attr-value">
											input-box
										</span>
										<span className="token punctuation">
											"
										</span>
										<span className="token punctuation">
											/
										</span>
										<span className="token punctuation">
											&gt;
										</span>
									</code>
								</span>
								<span class="token punctuation">&lt;/</span>
								<span class="token tag">form</span>
								<span class="token punctuation">&gt;</span>
							</code>
							<div className="toolbar">
								<div className="toolbar-item">
									<span>HTML</span>
								</div>
							</div>
						</pre>
						<div className="l-block-2"></div>
						<h3>Tipo submit</h3>
						<form class="form">
							<input
								type="submit"
								className="button orange-button-color w100 mtb16-s"
								value="submit"
							/>
						</form>
						<pre className="language-html code-toolbar">
							<code class="language-html">
								<span class="token punctuation">&lt;</span>
								<span class="token tag">form&nbsp;</span>
								<span class="token attr-name">class</span>
								<span class="token punctuation">="</span>
								<span class="token attr-value">form</span>
								<span class="token punctuation">"</span>
								<span class="token punctuation">&gt;</span>
								<span class="token html-value">
									<code className="language-html">
										<span>&nbsp;&nbsp;</span>{" "}
										<span className="token punctuation">
											&lt;
										</span>
										<span className="token tag">
											input&nbsp;
										</span>
										<span className="token attr-name">
											type
										</span>
										<span className="token punctuation">
											="
										</span>
										<span className="token attr-value">
											submit
										</span>
										<span className="token punctuation">
											"
										</span>
										<span className="token attr-name">
											&nbsp;value
										</span>
										<span className="token punctuation">
											="
										</span>
										<span className="token attr-value">
											submit
										</span>
										<span className="token punctuation">
											"
										</span>
										<span className="token attr-name">
											&nbsp;class
										</span>
										<span className="token punctuation">
											="
										</span>
										<span className="token attr-value">
											button orange-button-color w100
											mtb16-s
										</span>
										<span className="token punctuation">
											"
										</span>
										<span className="token punctuation">
											/
										</span>
										<span className="token punctuation">
											&gt;
										</span>
									</code>
								</span>
								<span class="token punctuation">&lt;/</span>
								<span class="token tag">form</span>
								<span class="token punctuation">&gt;</span>
							</code>
							<div className="toolbar">
								<div className="toolbar-item">
									<span>HTML</span>
								</div>
							</div>
						</pre>
					</div>
					<div className="main-block">
						<h3>Colores disponibles para los bordes | botones</h3>
						<ul>
							<li>blue-form-color | blue-button-color</li>
							<li>green-form-color | green-button-color</li>
							<li>red-form-color | red-button-color</li>
							<li>celeste-form-color | celeste-button-color</li>
							<li>orange-form-color | orange-button-color</li>
							<li>dark-form-color | dark-button-color</li>
							<li>sea-form-color | sea-button-color</li>
							<li>gray-form-color | gray-button-color</li>
							<li>light-form-color | light-button-color</li>
						</ul>
					</div>
				</section>
			</main>
		</>
	);
};

export default Form;
