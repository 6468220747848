import React from "react";
import Aside from "../Aside";

const Typography = () => {
	return (
		<>
			<main className="main-content g-container gtc4-s gcg1-s">
				<Aside />
				<section className="main-section gcss3-s">
					<div className="l-block-2"></div>
					<h2>Tipografía</h2>
					<div className="main-block">
						<p className="main-text">
							La tipografía engloba la definición de todas las
							propiedades que nos permite formatear textos (
							<code>text content</code>) y títulos (
							<code>heading</code>) de nuestras páginas. <br />
							Nuestra librería nos permite aplicar estilos a la
							propiedad de tamaño <code>font-size</code>{" "}
							exclusivamente.
						</p>
						<h3>text content</h3>
						<p>
							Las propiedades de <code>text content</code> nos
							permiten establecer el tamaño para textos no
							incluidos entre las etiquetas de tipo{" "}
							<code>h1</code>...<code>h6</code>.
						</p>
						<p>
							Disponemos las siguientes clases:
							<br />
							<ul>
								<li>biggest</li>
								<li>bigger</li>
								<li>big-text</li>
								<li>normal</li>
								<li>small-text</li>
								<li>smaller</li>
								<li>supersmall</li>
							</ul>
						</p>
						<pre className="language-html code-toolbar">
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">div&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">
									biggest
								</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									Texto aplicando biggest
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">div</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">div&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">bigger</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									Texto aplicando bigger
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">div</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">div&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">
									big-text
								</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									Texto aplicando big-text
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">div</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">div&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">normal</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									Texto aplicando normal
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">div</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">div&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">
									small-text
								</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									Texto aplicando small-text
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">div</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">div&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">
									smaller
								</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									Texto aplicando smaller
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">div</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">div&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">
									supersmall
								</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									Texto aplicando supersmall
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">div</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<div className="toolbar">
								<div className="toolbar-item">
									<span>HTML</span>
								</div>
							</div>
						</pre>
						<div className="container-demo">
							<div className="biggest">
								Texto aplicando biggest
							</div>
							<div className="bigger">Texto aplicando bigger</div>
							<div className="big-text">
								Texto aplicando big-text
							</div>
							<div className="normal">Texto aplicando normal</div>
							<div className="small-text">
								Texto aplicando small-text
							</div>
							<div className="smaller">
								Texto aplicando smaller
							</div>
							<div className="supersmall">
								Texto aplicando supersmall
							</div>
						</div>
						<h3>heading</h3>
						<p>
							Las propiedades de <code>heading</code> nos
							permitirán formatear textos como título incluyendo
							textos en las etiquetas de HTML de <code>h1</code>
							...<code>h6</code>. O podemos aplicar de forma
							individual a cualquier texto aplicando las clases{" "}
							<code>t1</code>...<code>t6</code>.
						</p>
						<h4>Ejemplos títulos mediante clase</h4>
						<pre className="language-html code-toolbar">
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">div&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">t1</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									Texto aplicando título t1
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">div</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">div&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">t2</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									Texto aplicando título t2
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">div</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">div&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">t3</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									Texto aplicando título t3
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">div</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">div&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">t4</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									Texto aplicando título t4
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">div</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">div&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">t5</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									Texto aplicando título t5
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">div</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">div&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">t6</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									Texto aplicando título t6
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">div</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<div className="toolbar">
								<div className="toolbar-item">
									<span>HTML</span>
								</div>
							</div>
						</pre>
						<div className="container-demo">
							<div className="t1">Texto aplicando título t1</div>
							<div className="t2">Texto aplicando título t2</div>
							<div className="t3">Texto aplicando título t3</div>
							<div className="t4">Texto aplicando título t4</div>
							<div className="t5">Texto aplicando título t5</div>
							<div className="t6">Texto aplicando título t6</div>
						</div>
						<h4>Ejemplos títulos genéricos</h4>
						<pre className="language-html code-toolbar">
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">div&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">h1</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									Texto aplicando título h1
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">div</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">div&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">h2</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									Texto aplicando título h2
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">div</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">div&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">h3</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									Texto aplicando título h3
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">div</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">div&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">h4</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									Texto aplicando título h4
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">div</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">div&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">h5</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									Texto aplicando título h5
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">div</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">div&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">h6</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									Texto aplicando título h6
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">div</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<div className="toolbar">
								<div className="toolbar-item">
									<span>HTML</span>
								</div>
							</div>
						</pre>
						<div className="container-demo">
							<h1>Texto aplicando título h1</h1>
							<h2>Texto aplicando título h2</h2>
							<h3>Texto aplicando título h3</h3>
							<h4>Texto aplicando título h4</h4>
							<h5>Texto aplicando título h5</h5>
							<h6>Texto aplicando título h6</h6>
						</div>
						<div className="container-demo">
							<h3>Colores disponibles para la fuentes</h3>
							<p>
								Tenemos unas clases extra para cambiar el color
								de cualquier texto
							</p>
							<ul>
								<li>blue-font-color</li>
								<li>green-font-color</li>
								<li>red-font-color</li>
								<li>celeste-font-color</li>
								<li>orange-font-color</li>
								<li>dark-font-color</li>
								<li>sea-font-color</li>
								<li>gray-font-color</li>
								<li>light-font-color</li>
							</ul>
						</div>
						{/* <div className="container-demo">
							<h3>Grosores para la fuentes</h3>
							<p>
								Tenemos unas clases extra también para cambiar
								el grosor de los textos, incluidas con media
								query.
							</p>
							<ul>
								<li>weight100 | weight100-(tamaño query) </li>
								<li>weight200 | weight200-(tamaño query) </li>
								<li>weight300 | weight300-(tamaño query) </li>
								<li>weight400 | weight400-(tamaño query) </li>
								<li>weight500 | weight500-(tamaño query) </li>
								<li>weight600 | weight600-(tamaño query) </li>
								<li>weight700 | weight700-(tamaño query) </li>
								<li>weight800 | weight800-(tamaño query) </li>
								<li>weight900 | weight900-(tamaño query) </li>
							</ul>
						</div> */}
					</div>
				</section>
			</main>
		</>
	);
};

export default Typography;
