import React from "react";
import Aside from "../Aside";

const Instalation = () => {
	return (
		<>
			<main className="main-content g-container gtc4-s gcg1-s">
				<Aside />
				<section className="main-section gcss3-s">
					<div className="l-block-2"></div>
					<div className="main-block">
						<h2>Instalación</h2>
						<p className="main-text">
							Clickertigrid es un conjunto de clases css que sirve
							para poder realizar layouts responsive y mobile
							first para los proyectos de Clickerti.
						</p>
						<p class="main-text">
							Se pueden utilizar con la version sass o con version
							css
						</p>
						<h3>Instalación desde linea de comandos</h3>
						<p className="main-text">
							Para instalar la versión{" "}
							<code className="highlight">Sass</code> con npm:
						</p>
						<pre className="language-html code-toolbar">
							<code className="language-npm">
								<span className="token punctuation">
									npm i --save-dev clickertigrid
								</span>
							</code>
							<div className="toolbar">
								<div className="toolbar-item">
									<span>Bash</span>
								</div>
							</div>
						</pre>
						<p className="main-text">Así seria usando yarm:</p>
						<pre className="language-html code-toolbar">
							<code className="language-npm">
								<span className="token punctuation">
									yarn add clickertigrid --dev
								</span>
							</code>
							<div className="toolbar">
								<div className="toolbar-item">
									<span>Bash</span>
								</div>
							</div>
						</pre>
						<p className="main-text">
							Una vez instalado, hay que importarlo en los
							archivos <code>Sass</code>
						</p>
						<pre className="language-css code-toolbar">
							<code className="language-sass">
								{" "}
								<span className="token tag">@import</span>
								<span class="token puntuation">&nbsp;"</span>
								<span class="token string">
									~clickertigrid/clickertigrid
								</span>
								<span class="token puntuation">"</span>
								<span className="token punctuation">;</span>
							</code>
						</pre>
						<h3>Actualización desde linea de comandos</h3>
						<p className="main-text">
							Para actualizar a la última versión disponible con
							npm.
						</p>
						<pre className="language-html code-toolbar">
							<code className="language-npm">
								<span className="token punctuation">
									npm install clickertigrid@latest
								</span>
							</code>
							<div className="toolbar">
								<div className="toolbar-item">
									<span>Bash</span>
								</div>
							</div>
						</pre>
						<p className="main-text">
							Para actualizar a la úktima versión disponible con
							yarn.
						</p>
						<pre className="language-html code-toolbar">
							<code className="language-npm">
								<span className="token punctuation">
									yarn upgrade clickertigrid --latest
								</span>
							</code>
							<div className="toolbar">
								<div className="toolbar-item">
									<span>Bash</span>
								</div>
							</div>
						</pre>
					</div>
					<div className="main-block">
						<h3>Instalación desde cdn</h3>
						<p className="main-text">
							Se puede descargar la hoja de estilos e incorporarla
							como cualquier otra hoja de estilos. Otra de las
							opciones es importarlo de un <code>CDN</code>
						</p>
						<pre className="language-css code-toolbar">
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">link</span>
								<span className="token selector">
									&nbsp;href
								</span>
								<span className="token symbol">=</span>
								<span className="token punctuation">"</span>
								<span className="token attr-value">
									https://unpkg.com/clickertigrid@2.2.8/css/clickertigrid.min.css
								</span>
								<span className="token punctuation">"</span>
								<span className="token selector">
									&nbsp;rel
								</span>
								<span className="token symbol">=</span>
								<span className="token punctuation">"</span>
								<span className="token attr-value">
									stylesheet
								</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
							</code>
						</pre>
						{/* <link
							href="https://unpkg.com/clickertigrid@1.0.4/css/clickertigrid.min.css"
							rel="stylesheet"
						/> */}
					</div>
				</section>
			</main>
		</>
	);
};

export default Instalation;
