import React from "react";
import Aside from "../Aside";

const Functions = () => {
	return (
		<>
			<main className="main-content g-container gtc4-s gcg1-s">
				<Aside />
				<section className="main-section gcss3-s">
					<div className="l-block-2"></div>
					<h2>Funciones</h2>
					<div className="main-block">
						<p className="main-text">
							Para pasar entre unidades como px, rem y em
							disponemos de tres funciones que son aplicables en
							las hojas de estilo de sass. Para utilizarlas solo
							hay que llamarlas con <code>toPx(rem|em)</code>,{" "}
							<code>toRem(px)</code>, <code>toEm(px)</code>
						</p>
						<h3>toPx</h3>
						<p className="main-text">
							Con esta función se convierte <code>Rem</code> y{" "}
							<code>Em</code> en px. La forma de utilizarla es
							sustituir cualquier valor en las propiedades de css
							(width, height, padding, margin, etc...) por la
							función y dentro el valor numérico con la unidad.
							Por ejemplo, si queremos asignar un tamaño de
							padding a un elemento se haria con{" "}
							<code>padding: toPx(1rem);</code> o{" "}
							<code>padding: toPx(1em);</code>. Es necesario
							colocar la unidad ademas del número.
						</p>
						<pre className="language-sass code-toolbar">
							<code className="language-sass">
								<span className="token css-selector">.</span>
								<span className="token selector">
									item-funtions-px-rem
								</span>
								<span className="token puntuation-class">
									&#160;&#123;
								</span>
							</code>
							<code className="language-sass">
								<span className="token css-property">
									&#160;&#160;&#160;&#160;width
								</span>
								<span className="token punctuation">
									&#160;:
								</span>
								<spam className="token function">
									&#160;toPx
								</spam>
								<span className="token punctuation">(</span>
								<spam className="token css-value">
									&#160;15
								</spam>
								<span className="token unit">rem</span>{" "}
								<span className="token punctuation">)</span>
								<span className="token punctuation">;</span>
							</code>
							<code className="language-sass">
								<span className="token puntuation-class">
									&#125;
								</span>
							</code>
							<code className="language-sass">
								<span className="token css-selector">.</span>
								<span className="token selector">
									item-funtions-px-em
								</span>
								<span className="token puntuation-class">
									&#160;&#123;
								</span>
							</code>
							<code className="language-sass">
								<span className="token css-property">
									&#160;&#160;&#160;&#160;width
								</span>
								<span className="token punctuation">
									&#160;:
								</span>
								<spam className="token function">
									&#160;toPx
								</spam>
								<span className="token punctuation">(</span>
								<spam className="token css-value">
									&#160;20
								</spam>
								<span className="token unit">em</span>{" "}
								<span className="token punctuation">)</span>
								<span className="token punctuation">;</span>
							</code>
							<code className="language-sass">
								<span className="token puntuation-class">
									&#125;
								</span>
							</code>
							<code className="language-sass">
								<span className="token css-selector">.</span>
								<span className="token selector">
									item-funtions-em
								</span>
								<span className="token puntuation-class">
									&#160;&#123;
								</span>
							</code>
							<code className="language-sass">
								<span className="token css-property">
									&#160;&#160;&#160;&#160;width
								</span>
								<span className="token punctuation">
									&#160;:
								</span>
								<spam className="token function">
									&#160;toEm
								</spam>
								<span className="token punctuation">(</span>
								<spam className="token css-value">
									&#160;150
								</spam>
								<span className="token unit">px</span>{" "}
								<span className="token punctuation">)</span>
								<span className="token punctuation">;</span>
							</code>
							<code className="language-sass">
								<span className="token puntuation-class">
									&#125;
								</span>
							</code>
							<code className="language-sass">
								<span className="token css-selector">.</span>
								<span className="token selector">
									item-funtions-rem
								</span>
								<span className="token puntuation-class">
									&#160;&#123;
								</span>
							</code>
							<code className="language-sass">
								<span className="token css-property">
									&#160;&#160;&#160;&#160;width
								</span>
								<span className="token punctuation">
									&#160;:
								</span>
								<spam className="token function">
									&#160;toRem
								</spam>
								<span className="token punctuation">(</span>
								<spam className="token css-value">
									&#160;200
								</spam>
								<span className="token unit">px</span>{" "}
								<span className="token punctuation">)</span>
								<span className="token punctuation">;</span>
							</code>
							<code className="language-sass">
								<span className="token puntuation-class">
									&#125;
								</span>
							</code>
							<div className="toolbar">
								<div className="toolbar-item">
									<span>SCSS</span>
								</div>
							</div>
						</pre>
					</div>
					<div className="main-block">
						<p className="main-text">
							El resultado CSS de la compilación del código SCSS
							es la siguiente
						</p>
						<pre className="language-sass code-toolbar">
							<code className="language-css">
								<span className="token css-selector">.</span>
								<span className="token selector">
									item-funtions-px-rem
								</span>
								<span className="token puntuation-class">
									&#160;&#123;
								</span>
							</code>
							<code className="language-css">
								<span className="token css-property">
									&#160;&#160;&#160;&#160;width
								</span>
								<span className="token punctuation">
									&#160;:
								</span>
								<spam className="token css-value">
									&#160;240
								</spam>
								<span className="token unit">px</span>
								<span className="token punctuation">;</span>
							</code>
							<code className="language-css">
								<span className="token puntuation-class">
									&#125;
								</span>
							</code>
							<code className="language-css">
								<span className="token css-selector">.</span>
								<span className="token selector">
									item-funtions-px-em
								</span>
								<span className="token puntuation-class">
									&#160;&#123;
								</span>
							</code>
							<code className="language-css">
								<span className="token css-property">
									&#160;&#160;&#160;&#160;width
								</span>
								<span className="token punctuation">
									&#160;:
								</span>
								<spam className="token css-value">
									&#160;320
								</spam>
								<span className="token unit">px</span>
								<span className="token punctuation">;</span>
							</code>
							<code className="language-css">
								<span className="token puntuation-class">
									&#125;
								</span>
							</code>
							<code className="language-css">
								<span className="token css-selector">.</span>
								<span className="token selector">
									item-funtions-em
								</span>
								<span className="token puntuation-class">
									&#160;&#123;
								</span>
							</code>
							<code className="language-css">
								<span className="token css-property">
									&#160;&#160;&#160;&#160;width
								</span>
								<span className="token punctuation">
									&#160;:
								</span>
								<spam className="token css-value">
									&#160;9.375
								</spam>
								<span className="token unit">em</span>
								<span className="token punctuation">;</span>
							</code>
							<code className="language-css">
								<span className="token puntuation-class">
									&#125;
								</span>
							</code>
							<code className="language-css">
								<span className="token css-selector">.</span>
								<span className="token selector">
									item-funtions-rem
								</span>
								<span className="token puntuation-class">
									&#160;&#123;
								</span>
							</code>
							<code className="language-css">
								<span className="token css-property">
									&#160;&#160;&#160;&#160;width
								</span>
								<span className="token punctuation">
									&#160;:
								</span>
								<spam className="token css-value">
									&#160;12.5
								</spam>
								<span className="token unit">rem</span>
								<span className="token punctuation">;</span>
							</code>
							<code className="language-css">
								<span className="token puntuation-class">
									&#125;
								</span>
							</code>
							<div className="toolbar">
								<div className="toolbar-item">
									<span>CSS</span>
								</div>
							</div>
						</pre>
						<div className="container-demo df-s jcc-s">
							<div className="item-funtions-px-rem text-center mlr8-s">
								toPx(15rem)
							</div>
							<div className="item-funtions-px-em text-center mlr8-s">
								toPx(20em)
							</div>
						</div>
						<div className="container-demo dg-s jcc-s">
							<div className="item-funtions-rem text-center">
								toRem(200px)
							</div>
						</div>
						<div className="container-demo dg-s jcc-s">
							<div className="item-funtions-em text-center">
								toEm(150px)
							</div>
						</div>
					</div>
				</section>
			</main>
		</>
	);
};

export default Functions;
