import React from "react";
import Aside from "../Aside";

const Size = () => {
	return (
		<>
			<main className="main-content g-container gtc4-s gcg1-s">
				<Aside />
				<section className="main-section gcss3-s">
					<div className="l-block-2"></div>
					<h2>Height and Width</h2>
					<div className="main-block">
						<p className="main-text">
							Las propiedades <code>width</code> y{" "}
							<code>height</code> nos permiten definir la anchura
							y altura de un elemento.
						</p>
						<p>
							Para indicar su valor hemos dispuesto varios tipos
							de unidades de medida según sea la propiedad:
							<ul>
								<li>pixel</li>
								<li>%</li>
								<li>vw (width)</li>
								<li>vh (height)</li>
							</ul>
						</p>
						<p>
							Siempre teniendo en cuenta que el valor a aplicar
							podrá estar determinado por el ancho del dispositivo
							a través de la nomenclatura de las clases donde se
							indican los tamaños a aplicar: <code>-fold</code>,{" "}
							<code>-sm</code>, <code>-lm</code>,<code>-m</code>,{" "}
							<code>-xm</code>, <code>-ipad</code>,{" "}
							<code>-lg</code>, <code>-xl</code>.
						</p>
						{/* <p>
							Adicionalmente el nombre de nuestras clases
							incluirán "f" para aplicar a partir de ese tamaño (
							<i>from</i>), por ejemplo <code>.w97-sm</code>, e
							incluirán "t" aplicando el valor hasta que no se
							supere el tamaño de pantalla que incluye en nombre
							de la clase (<i>to</i>), por ejemplo{" "}
							<code>.w99-s</code>.
						</p> */}
						<h3>width</h3>
						<p>
							Esta propiedad, como su propia traducción indica,
							nos permitirá definir el ancho de un elemento
						</p>
						<pre className="language-html code-toolbar">
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">div&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">
									w485px-s
								</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									Caja con ancho indicado en pixels
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">div</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">div&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">w40-s</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									Caja con ancho indicado en %
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">div</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">div&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">
									w40vw-s
								</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									Caja con ancho en viewport
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">div</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<div className="toolbar">
								<div className="toolbar-item">
									<span>HTML</span>
								</div>
							</div>
						</pre>
						<div className="container-demo">
							<div className="w485px-s background-demo-wh">
								Caja con un ancho en pixels de 485px
							</div>
							<br />
							<div className="w40-s background-demo-wh">
								Caja con ancho en porcentaje de 40%
							</div>
							<br />
							<div className="w40vw-s background-demo-wh">
								Caja con ancho en viewport de 40vm
							</div>
							<br />
						</div>
						<h3>height</h3>
						<p>
							Esta propiedad nos permitirá definir el alto de un
							elemento
						</p>
						<pre className="language-html code-toolbar">
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">div&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">
									h50px-fold
								</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									Caja con un altura en pixels de 50px
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">div</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">div&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">
									h40-fold
								</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									Caja con una altura en porcentaje de 40%
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">div</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">div&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">
									h20vh-fold
								</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									Caja con una altura en viewport de 10vh
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">div</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<div className="toolbar">
								<div className="toolbar-item">
									<span>HTML</span>
								</div>
							</div>
						</pre>
						<div className="container-demo h60vh-s">
							<div className="h50px-fold background-demo-wh">
								Caja con un altura en pixels de 50px
							</div>
							<br />
							<div className="h40-fold background-demo-wh">
								Caja con una altura en porcentaje de 40%
							</div>
							<br />
							<div className="h15vh-fold background-demo-wh">
								Caja con una altura en viewport de 10vh
							</div>
							<br />
						</div>
						<p className="smaller ml8-s">
							Nota: el porcentaje únicamente se aplicará cuando el
							contener tenga indicada una altura.
						</p>
					</div>
				</section>
			</main>
		</>
	);
};

export default Size;
