import React from "react";
import Aside from "../Aside";

const Border = () => {
	return (
		<>
			<main className="main-content g-container gtc4-s gcg1-s">
				<Aside />
				<section className="main-section gcss3-s">
					<div className="l-block-2"></div>
					<h2>Bordes</h2>
					<p className="main-text">
						Para crear bordes tenemos clases que se aplican en las
						etiquetas html. Para crear bordes redondeados, tenemos
						dos opciones, utilizar clases para html o utilizar
						mixing en una clase dentro de una hoja de estilos sass.
					</p>
					<div className="main-block">
						<p className="main-text">
							Para crear bordes tenemos clases básicas con
							atributos por defecto y luego otras clases para el
							cambio de colores, grosor de borde y tipo de línea
							del borde.
						</p>
						<p className="main-text">
							Las clases base se forman de dos formas. La clase
							sin media query o con media query agregando el punto
							de ruptura. Además disponemos de clases para 10
							colores, 3 tipos de línea para el borde y 4 clases
							de grosor.
						</p>
						<p className="main-text">
							Las clases base son <code>tipo de borde</code> sin
							media query, <code>tipo de borde-tamaño</code>,{" "}
							<code>tipo de borde-tamaño</code>
						</p>
						<p className="main-text">
							También existe una clase para eliminar los bordes,
							la clase es <code>border-null</code>
						</p>
						<pre className="language-html code-toolbar">
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">div&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">
									border red-border
								</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">border</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">div</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">div&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">
									border-top green-border
								</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									border-top
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">div</span>
								<span className="token punctuation">&gt;</span>
							</code>{" "}
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">div&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">
									border-right celeste-border
								</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									border-right
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">div</span>
								<span className="token punctuation">&gt;</span>
							</code>{" "}
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">div&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">
									border-bottom blue-border
								</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									border-bottom
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">div</span>
								<span className="token punctuation">&gt;</span>
							</code>{" "}
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">div&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">
									border-left orange-border
								</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									border-left
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">div</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">div&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">
									border-null
								</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									border-null
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">div</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<div className="toolbar">
								<div className="toolbar-item">
									<span>HTML</span>
								</div>
							</div>
						</pre>
						<div className="g-container-demo gtc5-s gg1-s">
							<div className="box-demo background-demo-light border dg-s jcc-s acc-s red-border">
								border
							</div>
							<div className="box-demo background-demo-light border-top dg-s jcc-s acc-s green-border">
								border-top
							</div>
							<div className="box-demo background-demo-light border-right dg-s jcc-s acc-s celeste-border">
								border-right
							</div>
							<div className="box-demo background-demo-light border-bottom dg-s jcc-s acc-s blue-border">
								border-bottom
							</div>
							<div className="box-demo background-demo-light border-left dg-s jcc-s acc-s orange-border">
								border-left
							</div>
							<div className="box-demo background-demo-light border-null dg-s jcc-s acc-s">
								border-null
							</div>
						</div>
						<pre className="language-html code-toolbar">
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">div&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">
									border-s
								</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									border-s
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">div</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">div&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">
									border-top-s
								</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									border-top-s
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">div</span>
								<span className="token punctuation">&gt;</span>
							</code>{" "}
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">div&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">
									border-right-s
								</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									border-right-s
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">div</span>
								<span className="token punctuation">&gt;</span>
							</code>{" "}
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">div&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">
									border-bottom-s
								</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									border-bottom-s
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">div</span>
								<span className="token punctuation">&gt;</span>
							</code>{" "}
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">div&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">
									border-left-s
								</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									border-left-s
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">div</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<div className="toolbar">
								<div className="toolbar-item">
									<span>HTML</span>
								</div>
							</div>
						</pre>
						<div className="g-container-demo gtc5-s gg1-s">
							<div className="box-demo background-demo-light border-s dg-s jcc-s acc-s">
								border
							</div>
							<div className="box-demo background-demo-light border-top-s dg-s jcc-s acc-s">
								border-top
							</div>
							<div className="box-demo background-demo-light border-right-s dg-s jcc-s acc-s">
								border-right
							</div>
							<div className="box-demo background-demo-light border-bottom-s dg-s jcc-s acc-s">
								border-bottom
							</div>
							<div className="box-demo background-demo-light border-left-s dg-s jcc-s acc-s">
								border-left
							</div>
						</div>
					</div>
					<div className="main-block">
						<p className="main-text">
							Además del estilo sólido que es el predeterminado,
							podemos crear bordes con tres estilos adicionales
							agregando estas clases con las clases base. Las tres
							clases son <code>dotted</code>, <code>dashed</code>{" "}
							y <code>double</code>
						</p>{" "}
						<pre className="language-html code-toolbar">
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">div&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">
									border red-border dotted
								</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">dotted</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">div</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">div&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">
									border-top red-border dashed
								</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">dashed</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">div</span>
								<span className="token punctuation">&gt;</span>
							</code>{" "}
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">div&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">
									border-right red-border double
								</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">double</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">div</span>
								<span className="token punctuation">&gt;</span>
							</code>{" "}
							<div className="toolbar">
								<div className="toolbar-item">
									<span>HTML</span>
								</div>
							</div>
						</pre>
						<div className="g-container-demo gtc3-s gg1-s">
							<div className="box-demo background-demo-light border dg-s jcc-s acc-s red-border dotted">
								dotted
							</div>
							<div className="box-demo background-demo-light border dg-s jcc-s acc-s red-border dashed">
								dashed
							</div>
							<div className="box-demo background-demo-light border dg-s jcc-s acc-s red-border double">
								double
							</div>
						</div>
					</div>
					<div className="main-block">
						<p className="main-text">
							Existen clases adicionales para 10 colores además
							del predeterminado
						</p>{" "}
						<pre className="language-html code-toolbar">
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">div&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">
									border blue-border
								</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">blue</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">div</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">div&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">
									border green-border
								</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">green</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">div</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">div&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">
									border red-border
								</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">red</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">div</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">div&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">
									border celeste-border
								</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									celeste
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">div</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">div&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">
									border orange-border
								</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">orange</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">div</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">div&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">
									border dark-border
								</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">dark</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">div</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">div&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">
									border sea-border
								</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">sea</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">div</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">div&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">
									border text-border
								</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">text</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">div</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">div&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">
									border light-border
								</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">light</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">div</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">div&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">
									border gray-border
								</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">gray</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">div</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<div className="toolbar">
								<div className="toolbar-item">
									<span>HTML</span>
								</div>
							</div>
						</pre>
						<div className="g-container-demo gtc5-s gg1-s">
							<div className="box-demo background-demo-light border dg-s jcc-s acc-s blue-border">
								blue
							</div>
							<div className="box-demo background-demo-light border dg-s jcc-s acc-s green-border">
								green
							</div>
							<div className="box-demo background-demo-light border dg-s jcc-s acc-s red-border">
								red
							</div>
							<div className="box-demo background-demo-light border dg-s jcc-s acc-s celeste-border">
								celeste
							</div>
							<div className="box-demo background-demo-light border dg-s jcc-s acc-s orange-border">
								orange
							</div>
							<div className="box-demo background-demo-light border dg-s jcc-s acc-s dark-border">
								dark
							</div>
							<div className="box-demo background-demo-light border dg-s jcc-s acc-s sea-border">
								sea
							</div>
							<div className="box-demo background-demo-light border dg-s jcc-s acc-s text-border">
								text
							</div>
							<div className="box-demo background-demo-light border dg-s jcc-s acc-s light-border">
								light
							</div>
							<div className="box-demo background-demo-light border dg-s jcc-s acc-s gray-border">
								gray
							</div>
						</div>
					</div>
					<div className="main-block">
						<p className="main-text">
							Para hacer los border recondeados exite una clase
							para redondear las cuatro esquinas
							<code>border-rounded+tamaño</code>, y luego una
							clase por esquina. Para la esquina superior-derecha{" "}
							<code>border1-rounded+tamaño</code>, esquina
							superior-izquierda{" "}
							<code>border2-rounded+tamaño</code>, esquina
							inferior-derecha<code>border3-rounded+tamaño</code>{" "}
							y esquina inferior-derecha
							<code>border4-rounded+tamaño</code>.
						</p>
						<pre className="language-html code-toolbar">
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">div&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">
									border-s border-rounded-25
								</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									border-rounded
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">div</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">div&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">
									border-rounded1-25
								</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									border-rounded1
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">div</span>
								<span className="token punctuation">&gt;</span>
							</code>{" "}
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">div&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">
									border-rounded2-10
								</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									border-rounded2
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">div</span>
								<span className="token punctuation">&gt;</span>
							</code>{" "}
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">div&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">
									border-rounded-3-20
								</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									border-rounded-3
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">div</span>
								<span className="token punctuation">&gt;</span>
							</code>{" "}
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">div&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">
									border-rounded4-30
								</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									border-rounded4
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">div</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<div className="toolbar">
								<div className="toolbar-item">
									<span>HTML</span>
								</div>
							</div>
						</pre>
						<div className="g-container-demo gtc5-s gg1-s">
							{" "}
							<div className="box-demo background-demo-light border-s border-rounded-25 dg-s jcc-s acc-s ptb5-s">
								border-rounded
							</div>
							<div className="box-demo background-demo-light border-s border-rounded1-25 dg-s jcc-s acc-s">
								border1-rounded
							</div>
							<div className="box-demo background-demo-light border-s border-rounded2-10 dg-s jcc-s acc-s">
								border2-rounded
							</div>
							<div className="box-demo background-demo-light border-s border-rounded3-20 dg-s jcc-s acc-s">
								border3-rounded
							</div>
							<div className="box-demo background-demo-light border-s border-rounded4-30 dg-s jcc-s acc-s">
								border4-rounded
							</div>
						</div>
					</div>
					<div className="main-block">
						<h3>Bordes redondeados (Sass)</h3>
						<p className="main-text">
							Cuando queremos crear un borde redondeado con un
							mixing sass hay que hacerlo dentro de una clase scss
							y utilizar un compilador para convertirlo en css.
						</p>
						<p className="main-text">
							Primero creamos la clase en la hoja de estilos
							llamando al mixing con el <code>@include</code> más
							el nombre. Además dependiendo de la curva que se
							quiere conseguir se deben de agregar los valores de
							los ángulos. Cada esquina tiene dos ángulos. En el
							mixing el orden de los ángulos es en el sentido de
							las agujas del reloj empezando en el ángulo superior
							derecha. el último atributo del mixing es la unidad
							en la que se quiere crear la curva. Disponemos de
							dos unidades, el porcentaje <code>pc</code>, y los
							pixel <code>px</code>. Siendo el porcentaje la
							unidad por defecto, y no es obligatorio incluirla.
							La de pixel si es obligatoria si se quiere utilizar
							los pixel.
						</p>
						<pre className="language-sass code-toolbar">
							<code className="language-sass">
								<span className="token css-selector">.</span>
								<span className="token selector">
									border-rounded-demo
								</span>
								<span className="token puntuation-class">
									&#160;&#123;
								</span>
							</code>
							<code className="language-sass">
								<span className="token tag">
									&#160;&#160;&#160;&#160;@include{" "}
								</span>
								<span className="token name-function">
									border-rouded
								</span>
								<span className="token punctuation">
									&#160;&#40;
								</span>
								<spam className="token constant">&#160;20</spam>
								<span className="token punctuation">,</span>
								<spam className="token constant">&#160;20</spam>
								<span className="token punctuation">,</span>
								<spam className="token constant">&#160;50</spam>
								<span className="token punctuation">,</span>
								<spam className="token constant">&#160;50</spam>
								<span className="token punctuation">,</span>
								<spam className="token constant">&#160;20</spam>
								<span className="token punctuation">,</span>
								<spam className="token constant">&#160;20</spam>
								<span className="token punctuation">,</span>
								<spam className="token constant">&#160;20</spam>
								<span className="token punctuation">,</span>
								<spam className="token constant">&#160;50</spam>
								<span className="token punctuation">,</span>
								<spam className="token constant">&#160;50</spam>
								<span className="token punctuation">,</span>
								<spam className="token at-rule">&#160;pc</spam>
								<span className="token punctuation">
									&#160;&#41;
								</span>
								<span className="token punctuation">;</span>
							</code>
							<code className="language-sass">
								<span className="token puntuation-class">
									&#160;&#125;
								</span>
							</code>
							<code className="language-sass">
								<span className="token css-selector">.</span>
								<span className="token selector">
									border-rounded-demo1
								</span>
								<span className="token puntuation-class">
									&#160;&#123;
								</span>
							</code>
							<code className="language-sass">
								<span className="token tag">
									&#160;&#160;&#160;&#160;@include{" "}
								</span>
								<span className="token name-function">
									border-rouded1
								</span>
								<span className="token punctuation">
									&#160;&#40;
								</span>
								<spam className="token constant">&#160;40</spam>
								<span className="token punctuation">,</span>
								<spam className="token constant">&#160;40</spam>
								<span className="token punctuation">,</span>
								<spam className="token at-rule">&#160;px</spam>
								<span className="token punctuation">
									&#160;&#41;
								</span>
								<span className="token punctuation">;</span>
							</code>
							<code className="language-sass">
								<span className="token puntuation-class">
									&#160;&#125;
								</span>
							</code>
							<code className="language-sass">
								<span className="token css-selector">.</span>
								<span className="token selector">
									border-rounded-demo2
								</span>
								<span className="token puntuation-class">
									&#160;&#123;
								</span>
							</code>
							<code className="language-sass">
								<span className="token tag">
									&#160;&#160;&#160;&#160;@include{" "}
								</span>
								<span className="token name-function">
									border-rouded2
								</span>
								<span className="token punctuation">
									&#160;&#40;
								</span>
								<spam className="token constant">&#160;25</spam>
								<span className="token punctuation">,</span>
								<spam className="token constant">&#160;25</spam>
								<span className="token punctuation">,</span>
								<spam className="token at-rule">&#160;px</spam>
								<span className="token punctuation">
									&#160;&#41;
								</span>
								<span className="token punctuation">;</span>
							</code>
							<code className="language-sass">
								<span className="token puntuation-class">
									&#160;&#125;
								</span>
							</code>
							<code className="language-sass">
								<span className="token css-selector">.</span>
								<span className="token selector">
									border-rounded-demo3
								</span>
								<span className="token puntuation-class">
									&#160;&#123;
								</span>
							</code>
							<code className="language-sass">
								<span className="token tag">
									&#160;&#160;&#160;&#160;@include{" "}
								</span>
								<span className="token name-function">
									border-rouded3
								</span>
								<span className="token punctuation">
									&#160;&#40;
								</span>
								<spam className="token constant">&#160;25</spam>
								<span className="token punctuation">,</span>
								<spam className="token constant">&#160;50</spam>
								<span className="token punctuation">
									&#160;&#41;
								</span>
								<span className="token punctuation">;</span>
							</code>
							<code className="language-sass">
								<span className="token puntuation-class">
									&#160;&#125;
								</span>
							</code>
							<code className="language-sass">
								<span className="token css-selector">.</span>
								<span className="token selector">
									border-rounded-demo4
								</span>
								<span className="token puntuation-class">
									&#160;&#123;
								</span>
							</code>
							<code className="language-sass">
								<span className="token tag">
									&#160;&#160;&#160;&#160;@include{" "}
								</span>
								<span className="token name-function">
									border-rouded4
								</span>
								<span className="token punctuation">
									&#160;&#40;
								</span>
								<spam className="token constant">&#160;25</spam>
								<span className="token punctuation">,</span>
								<spam className="token constant">&#160;50</spam>
								<span className="token punctuation">
									&#160;&#41;
								</span>
								<span className="token punctuation">;</span>
							</code>
							<code className="language-sass">
								<span className="token puntuation-class">
									&#160;&#125;
								</span>
							</code>
							<div className="toolbar">
								<div className="toolbar-item">
									<span>SASS</span>
								</div>
							</div>
						</pre>
						<p className="main-text">
							Una vez compilado el sass con el mixing se crea este
							css
						</p>
						<pre className="language-css code-toolbar">
							<code className="language-css">
								<span className="token css-selector">.</span>
								<span className="token selector">
									border-rounded-demo
								</span>
								<span className="token puntuation-class">
									&#160;&#123;
								</span>
							</code>
							<code className="language-css">
								<span className="token css-property">
									&#160;&#160;&#160;&#160;border-radius
								</span>
								<span className="token punctuation">
									&#160;:
								</span>
								<spam className="token css-value">
									&#160;20
								</spam>
								<span className="token unit">%</span>
								<span>&#160;</span>
								<spam className="token css-value">
									&#160;20
								</spam>
								<span className="token unit">%</span>
								<span>&#160;</span>
								<spam className="token css-value">
									&#160;20
								</spam>
								<span className="token unit">%</span>
								<span>&#160;</span>
								<spam className="token css-value">
									&#160;20
								</spam>
								<span className="token unit">%</span>
								<span>&#160;</span>
								<span className="token puntiation"> &#47;</span>
								<spam className="token css-value">
									&#160;50
								</spam>
								<span className="token unit">%</span>
								<span>&#160;</span>
								<spam className="token css-value">
									&#160;50
								</spam>
								<span className="token unit">%</span>
								<span>&#160;</span>
								<spam className="token css-value">
									&#160;50
								</spam>
								<span className="token unit">%</span>
								<span>&#160;</span>
								<spam className="token css-value">
									&#160;50
								</spam>
								<span className="token unit">%</span>
								<span className="token punctuation">;</span>
							</code>
							<code className="language-css">
								<span className="token puntuation-class">
									&#160;&#125;
								</span>
							</code>
							<code className="language-css">
								<span className="token css-selector">.</span>
								<span className="token selector">
									border-rounded-demo1
								</span>
								<span className="token puntuation-class">
									&#160;&#123;
								</span>
							</code>
							<code className="language-css">
								<span className="token css-property">
									&#160;&#160;&#160;&#160;border-top-left-radius
								</span>
								<span className="token punctuation">
									&#160;:
								</span>
								<spam className="token css-value">
									&#160;40
								</spam>
								<span className="token unit">px</span>
								<span>&#160;</span>
								<spam className="token css-value">
									&#160;40
								</spam>
								<span className="token unit">px</span>
								<span className="token punctuation">;</span>
							</code>
							<code className="language-css">
								<span className="token puntuation-class">
									&#160;&#125;
								</span>
							</code>
							<code className="language-css">
								<span className="token css-selector">.</span>
								<span className="token selector">
									border-rounded-demo2
								</span>
								<span className="token puntuation-class">
									&#160;&#123;
								</span>
							</code>
							<code className="language-css">
								<span className="token css-property">
									&#160;&#160;&#160;&#160;border-top-right-radius
								</span>
								<span className="token punctuation">
									&#160;:
								</span>
								<spam className="token css-value">
									&#160;25
								</spam>

								<span className="token unit">px</span>
								<span>&#160;</span>
								<spam className="token css-value">
									&#160;25
								</spam>
								<span className="token unit">px</span>
								<span className="token punctuation">;</span>
							</code>
							<code className="language-css">
								<span className="token puntuation-class">
									&#160;&#125;
								</span>
							</code>
							<code className="language-css">
								<span className="token css-selector">.</span>
								<span className="token selector">
									border-rounded-demo3
								</span>
								<span className="token puntuation-class">
									&#160;&#123;
								</span>
							</code>
							<code className="language-css">
								<span className="token css-property">
									&#160;&#160;&#160;&#160;border-bottom-right-radius
								</span>
								<span className="token punctuation">
									&#160;:
								</span>
								<spam className="token css-value">
									&#160;25
								</spam>
								<span className="token unit">%</span>
								<span>&#160;</span>
								<spam className="token css-value">
									&#160;50
								</spam>
								<span className="token unit">%</span>
								<span className="token punctuation">;</span>
							</code>
							<code className="language-css">
								<span className="token puntuation-class">
									&#160;&#125;
								</span>
							</code>
							<code className="language-css">
								<span className="token css-selector">.</span>
								<span className="token selector">
									border-rounded-demo4
								</span>
								<span className="token puntuation-class">
									&#160;&#123;
								</span>
							</code>
							<code className="language-css">
								<span className="token css-property">
									&#160;&#160;&#160;&#160;border-bottom-left-radius
								</span>
								<span className="token punctuation">
									&#160;:
								</span>
								<spam className="token css-value">
									&#160;25
								</spam>
								<span className="token unit">%</span>
								<span>&#160;</span>
								<spam className="token css-value">
									&#160;50
								</spam>
								<span className="token unit">%</span>
								<span className="token punctuation">;</span>
							</code>
							<code className="language-css">
								<span className="token puntuation-class">
									&#160;&#125;
								</span>
							</code>
							<div className="toolbar">
								<div className="toolbar-item">
									<span>CSS</span>
								</div>
							</div>
						</pre>
						<p className="main-text">
							Despues de crear la clase, la agregamos en etiqueta
							HTML donde queremos utilizarla.
						</p>
						<pre className="language-html code-toolbar">
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">div&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">
									border-s border-rounded-demo
								</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									border-rounded
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">div</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">div&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">
									border-s border-rounded-demo1
								</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									border-rounded1
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">div</span>
								<span className="token punctuation">&gt;</span>
							</code>{" "}
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">div&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">
									border-s border-rounded-demo2
								</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									border-rounded2
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">div</span>
								<span className="token punctuation">&gt;</span>
							</code>{" "}
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">div&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">
									border-s border-rounded-demo3
								</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									border-rounded3
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">div</span>
								<span className="token punctuation">&gt;</span>
							</code>{" "}
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">div&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">
									border-s border-rounded-demo4
								</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									border-rounded4
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">div</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<div className="toolbar">
								<div className="toolbar-item">
									<span>HTML</span>
								</div>
							</div>
						</pre>
						<div className="g-container-demo gtc5-s gg1-s">
							{" "}
							<div className="box-demo background-demo-light border-s border-rounded-demo dg-s jcc-s acc-s ptb5-s">
								border-rounded
							</div>
							<div className="box-demo background-demo-light border-s border-rounded-demo1 dg-s jcc-s acc-s">
								border-rounded1
							</div>
							<div className="box-demo background-demo-light border-s border-rounded-demo2 dg-s jcc-s acc-s">
								border-rounded2
							</div>
							<div className="box-demo background-demo-light border-s border-rounded-demo3 dg-s jcc-s acc-s">
								border-rounded3
							</div>
							<div className="box-demo background-demo-light border-s border-rounded-demo4 dg-s jcc-s acc-s">
								border-rounded4
							</div>
						</div>
					</div>
				</section>
			</main>
		</>
	);
};

export default Border;
