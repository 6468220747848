import React from "react";
import Aside from "../Aside";

const Query = () => {
	return (
		<>
			<main className="main-content g-container gtc4-s gcg1-s">
				<Aside />
				<section className="main-section gcss3-s">
					<div className="l-block-2"></div>
					<h2>Media Query</h2>
					<div className="main-block">
						<h3>Breakpoints</h3>
						<p className="main-text">
							Los breakpoints son los puntos de ruptura donde el
							layout cambia. Nuestras clases son Mobile first. Los
							siguientes breakpoints son los que tenemos
							definidos. Estos mismos breakpoints son los que se
							utilizan para crear las clases con lo puntos de
							ruptura. Por ejemplo, con la clase
							<code>w20px-lm</code> aplicamos al componente
							<code>width:20px</code> desde los 360px en adelante.
							También podríamos combinar la clase anterior con la
							clase <code>w5px-s</code> a este mismo componente y
							le estariamos aplicando 5px desde 0 hasta el tamaño
							de 360px.
						</p>
						<pre className="language-sass code-toolbar">
							<code className="language-sass">
								<span className="token variable-scss">
									$breakpoints
								</span>
								<span className="token punctuation">:</span>
								<span className="token puntuation-class">
									<span>&#160;</span>&#40;
								</span>
							</code>
							<code className="language-sass">
								<span className="token map-key">
									<span>&#160;</span>
									<span>&#160;</span>s
								</span>
								<span>&#160;</span>
								<span>&#160;</span>
								<span>&#160;</span>
								<span>&#160;</span>
								<span className="token punctuation">:</span>
								<span className="token variable-map">
									<span>&#160;</span>0
								</span>
								<span className="token punctuation">,</span>
								<span className="token comment">
									<span>&#160;</span>
									&#47;&#47; Todos los tamaños
								</span>
							</code>{" "}
							<code className="language-sass">
								<span className="token map-key">
									<span>&#160;</span>
									<span>&#160;</span>fold
								</span>
								<span>&#160;</span>
								<span className="token punctuation">:</span>
								<span className="token variable-map">
									<span>&#160;</span>280
								</span>
								<span className="token unit">px</span>
								<span className="token punctuation">,</span>
								<span className="token comment">
									<span>&#160;</span>
									<span>&#160;</span>
									&#47;&#47; ~ 17.5rem
								</span>
							</code>
							<code className="language-sass">
								<span className="token map-key">
									<span>&#160;</span>
									<span>&#160;</span>sm
								</span>
								<span>&#160;</span>
								<span>&#160;</span>
								<span>&#160;</span>
								<span className="token punctuation">:</span>
								<span className="token variable-map">
									<span>&#160;</span>320
								</span>
								<span className="token unit">px</span>
								<span className="token punctuation">,</span>
								<span className="token comment">
									<span>&#160;</span>
									<span>&#160;</span>
									&#47;&#47; ~ 20rem
								</span>
							</code>{" "}
							<code className="language-sass">
								<span className="token map-key">
									<span>&#160;</span>
									<span>&#160;</span>lm
								</span>
								<span>&#160;</span>
								<span>&#160;</span>
								<span>&#160;</span>
								<span className="token punctuation">:</span>
								<span className="token variable-map">
									<span>&#160;</span>360
								</span>
								<span className="token unit">px</span>
								<span className="token punctuation">,</span>
								<span className="token comment">
									<span>&#160;</span>
									<span>&#160;</span>
									&#47;&#47; ~ 22.5rem
								</span>
							</code>{" "}
							<code className="language-sass">
								<span className="token map-key">
									<span>&#160;</span>
									<span>&#160;</span>m
								</span>
								<span>&#160;</span>
								<span>&#160;</span>
								<span>&#160;</span>
								<span>&#160;</span>
								<span className="token punctuation">:</span>
								<span className="token variable-map">
									<span>&#160;</span>410
								</span>
								<span className="token unit">px</span>
								<span className="token punctuation">,</span>
								<span className="token comment">
									<span>&#160;</span>
									<span>&#160;</span>
									&#47;&#47; ~ 25.625rem
								</span>
							</code>{" "}
							<code className="language-sass">
								<span className="token map-key">
									<span>&#160;</span>
									<span>&#160;</span>xm
								</span>
								<span>&#160;</span>
								<span>&#160;</span>
								<span>&#160;</span>
								<span className="token punctuation">:</span>
								<span className="token variable-map">
									<span>&#160;</span>640
								</span>
								<span className="token unit">px</span>
								<span className="token punctuation">,</span>
								<span className="token comment">
									<span>&#160;</span>
									<span>&#160;</span>
									&#47;&#47; ~ 40rem
								</span>
							</code>{" "}
							<code className="language-sass">
								<span className="token map-key">
									<span>&#160;</span>
									<span>&#160;</span>ipad
								</span>
								<span>&#160;</span>
								<span className="token punctuation">:</span>
								<span className="token variable-map">
									<span>&#160;</span>768
								</span>
								<span className="token unit">px</span>
								<span className="token punctuation">,</span>
								<span className="token comment">
									<span>&#160;</span>
									<span>&#160;</span>
									&#47;&#47; ~ 48 rem
								</span>
							</code>{" "}
							<code className="language-sass">
								<span className="token map-key">
									<span>&#160;</span>
									<span>&#160;</span>lg
								</span>
								<span>&#160;</span>
								<span>&#160;</span>
								<span>&#160;</span>
								<span className="token punctuation">:</span>
								<span className="token variable-map">
									<span>&#160;</span>1024
								</span>
								<span className="token unit">px</span>
								<span className="token punctuation">,</span>
								<span className="token comment">
									<span>&#160;</span>
									&#47;&#47; ~ 64rem
								</span>
							</code>{" "}
							<code className="language-sass">
								<span>&#160;</span>
								<span>&#160;</span>
								<span className="token map-key">xl</span>
								<span>&#160;</span>
								<span>&#160;</span>
								<span>&#160;</span>
								<span className="token punctuation">:</span>
								<span className="token variable-map">
									<span>&#160;</span>1440
								</span>
								<span className="token unit">px</span>
								<span className="token punctuation">,</span>
								<span>&#160;</span>
								<span className="token comment">
									&#47;&#47; ~ 90rem
								</span>
							</code>
							<code className="language-sass"></code>
							<div className="toolbar">
								<div className="toolbar-item">
									<span>SASS</span>
								</div>
							</div>
						</pre>
					</div>
					<div class="main-block">
						<h3>Clases con breakpoints</h3>
						<p class="main-text">
							{" "}
							Nuestro modele es Mobile first, lo que significa que
							el estilo definido en un breakpoint será heredado en
							breakpoints superiores salvo que sea sobreescrito.
							En el siguiente ejemplo, el elemento medirá 100px de
							ancho <code>w100px-s</code> en todos los tamaños
							hasta el siguiente breakpoint <code>w200px-lm</code>{" "}
							que sobrescribe desde <code>lm</code> y cambia a
							200px de ancho. Y este es sobreescrito por el
							siguiente breakpoint <code>w400px-xm</code> a partir
							del tamaño <code>xm</code> que tendra un valor de
							400px de ancho.
						</p>
						<pre className="language-html code-toolbar">
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">div&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">
									w100px-s w200px-lm w400px-xm
								</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									Query (CLASS)
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">div</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<div className="toolbar">
								<div className="toolbar-item">
									<span>HTML</span>
								</div>
							</div>
						</pre>
						<div className="container-demo">
							<div className="center-cross db-s">
								<div className="query-demo-class w100px-s w200px-lm w400px-xm jcc-s acc-s">
									<div>Query (CLASS)</div>
								</div>
							</div>
						</div>
					</div>
					<div className="main-block">
						<h3>Mixing from, to y fromTo (Sass)</h3>
						<p className="main-text">
							Para crear puntos de ruptura ademas de las clases
							css, hay mixing que se utilizan directamente en una
							hoja de estilos sass o scss. Los mixing se aplican
							dentro de la clase que queremos que sea responsive.
							Hay tres mixing <code>from($size)</code>,{" "}
							<code>to($size)</code> y{" "}
							<code>fromTo($size-1, $size-2)</code>. El tamaño del
							punto de ruptura siempre debe de ser en pixel.
						</p>
						<pre className="language-sass code-toolbar">
							<code className="language-sass">
								<span className="token css-selector">.</span>
								<span className="token selector">
									query-demo
								</span>
								<span className="token puntuation-class">
									&#160;&#123;
								</span>
							</code>
							<code className="language-sass">
								<span className="token css-property">
									&#160;&#160;&#160;&#160;height
								</span>
								<span className="token punctuation">
									&#160;:
								</span>
								<spam className="token css-value">
									&#160;50
								</spam>
								<span className="token unit">px</span>
								<span className="token punctuation">;</span>
							</code>
							<code className="language-sass">
								<span className="token tag">
									&#160;&#160;&#160;&#160;@include{" "}
								</span>
								<span className="token name-function">to</span>
								<span className="token punctuation">
									&#160;&#40;
								</span>
								<spam className="token constant">360</spam>
								<spam className="token unit">px</spam>
								<span className="token punctuation">&#41;</span>
								<span className="token puntuation-class">
									&#160;&#123;
								</span>
							</code>
							<code className="language-sass">
								<span className="token css-property">
									&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;width
								</span>
								<span className="token punctuation">
									&#160;:
								</span>
								<spam className="token css-value">
									&#160;100
								</spam>
								<span className="token unit">px</span>
								<span className="token punctuation">;</span>
							</code>
							<code className="language-sass">
								<span className="token puntuation-class">
									&#160;&#160;&#160;&#160;&#125;
								</span>
							</code>
							<code className="language-sass">
								<span className="token tag">
									&#160;&#160;&#160;&#160;@include{" "}
								</span>
								<span className="token name-function">
									fromTo
								</span>
								<span className="token punctuation">
									&#160;&#40;
								</span>
								<spam className="token constant">360</spam>
								<spam className="token unit">px</spam>
								<span className="token punctuation">
									,&#160;
								</span>
								<spam className="token constant">640</spam>
								<spam className="token unit">px</spam>
								<span className="token punctuation">&#41;</span>
								<span className="token puntuation-class">
									&#160;&#123;
								</span>
							</code>
							<code className="language-sass">
								<span className="token css-property">
									&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;width
								</span>
								<span className="token punctuation">
									&#160;:
								</span>
								<spam className="token css-value">
									&#160;200
								</spam>
								<span className="token unit">px</span>
								<span className="token punctuation">;</span>
							</code>
							<code className="language-sass">
								<span className="token puntuation-class">
									&#160;&#160;&#160;&#160;&#125;
								</span>
							</code>
							<code className="language-sass">
								<span className="token tag">
									&#160;&#160;&#160;&#160;@include{" "}
								</span>
								<span className="token name-function">
									from
								</span>
								<span className="token punctuation">
									&#160;&#40;
								</span>
								<spam className="token constant">640</spam>
								<spam className="token unit">px</spam>
								<span className="token punctuation">&#41;</span>
								<span className="token puntuation-class">
									&#160;&#123;
								</span>
							</code>
							<code className="language-sass">
								<span className="token css-property">
									&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;width
								</span>
								<span className="token punctuation">
									&#160;:
								</span>
								<spam className="token css-value">
									&#160;400
								</spam>
								<span className="token unit">px</span>
								<span className="token punctuation">;</span>
							</code>
							<code className="language-sass">
								<span className="token puntuation-class">
									&#160;&#160;&#160;&#160;&#125;
								</span>
							</code>
							<code className="language-sass">
								<span className="token puntuation-class">
									&#125;
								</span>
							</code>
							<div className="toolbar">
								<div className="toolbar-item">
									<span>SCSS</span>
								</div>
							</div>
						</pre>
					</div>
					<div className="main-block">
						<p className="main-text">
							El resultado CSS de la compilación del código SCSS
							es la siguiente
						</p>
						<pre className="language-css code-toolbar">
							<code className="language-sass">
								<span className="token css-selector">.</span>
								<span className="token selector">
									query-demo
								</span>
								<span className="token puntuation-class">
									&#160;&#123;
								</span>
							</code>
							<code className="language-sass">
								<span className="token css-property">
									&#160;&#160;&#160;&#160;height
								</span>
								<span className="token punctuation">
									&#160;:
								</span>
								<spam className="token css-value">
									&#160;50
								</spam>
								<span className="token unit">px</span>
								<span className="token punctuation">;</span>
								<span className="token puntuation-class">
									&#160;&#125;
								</span>
							</code>
							<code className="language-sass">
								<span className="token tag">
									&#160;&#160;&#160;&#160;@media{" "}
								</span>
								<span className="token variable-scss">
									screen and
								</span>
								<span className="token punctuation">
									&#160;&#40;
								</span>
								<span className="token attr-query">
									max-width:&#160;
								</span>
								<spam className="token constant">360</spam>
								<spam className="token unit">px</spam>
								<span className="token punctuation">&#41;</span>
								<span className="token puntuation-class">
									&#160;&#123;
								</span>
							</code>
							<code className="language-sass">
								<span className="token css-selector">
									&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;.
								</span>
								<span className="token selector">
									query-demo
								</span>
								<span className="token puntuation-class">
									&#160;&#123;
								</span>
							</code>
							<code className="language-sass">
								<span className="token css-property">
									&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;width
								</span>
								<span className="token punctuation">
									&#160;:
								</span>
								<spam className="token css-value">
									&#160;100
								</spam>
								<span className="token unit">px</span>
								<span className="token punctuation">;</span>
								<span className="token puntuation-class">
									&#160;&#125;
								</span>
							</code>
							<code className="language-sass">
								<span className="token puntuation-class">
									&#160;&#160;&#160;&#160;&#125;
								</span>
							</code>
							<code className="language-sass">
								<span className="token tag">
									&#160;&#160;&#160;&#160;@media{" "}
								</span>
								<span className="token variable-scss">
									screen and
								</span>
								<span className="token punctuation">
									&#160;&#40;
								</span>
								<span className="token attr-query">
									min-width:&#160;
								</span>
								<spam className="token constant">360</spam>
								<spam className="token unit">px</spam>
								<span className="token punctuation">&#41;</span>
								<span className="token variable-scss">
									&#160;and
								</span>
								<span className="token punctuation">
									&#160;&#40;
								</span>
								<span className="token attr-query">
									max-width:&#160;
								</span>
								<spam className="token constant">639</spam>
								<spam className="token unit">px</spam>
								<span className="token punctuation">&#41;</span>
								<span className="token puntuation-class">
									&#160;&#123;
								</span>
							</code>
							<code className="language-sass">
								<span className="token css-selector">
									&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;.
								</span>
								<span className="token selector">
									query-demo
								</span>
								<span className="token puntuation-class">
									&#160;&#123;
								</span>
							</code>
							<code className="language-sass">
								<span className="token css-property">
									&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;width
								</span>
								<span className="token punctuation">
									&#160;:
								</span>
								<spam className="token css-value">
									&#160;200
								</spam>
								<span className="token unit">px</span>
								<span className="token punctuation">;</span>
								<span className="token puntuation-class">
									&#160;&#125;
								</span>
							</code>
							<code className="language-sass">
								<span className="token puntuation-class">
									&#160;&#160;&#160;&#160;&#125;
								</span>
							</code>{" "}
							<code className="language-sass">
								<span className="token tag">
									&#160;&#160;&#160;&#160;@media{" "}
								</span>
								<span className="token variable-scss">
									screen and
								</span>
								<span className="token punctuation">
									&#160;&#40;
								</span>
								<span className="token attr-query">
									max-width:&#160;
								</span>
								<spam className="token constant">640</spam>
								<spam className="token unit">px</spam>
								<span className="token punctuation">&#41;</span>
								<span className="token puntuation-class">
									&#160;&#123;
								</span>
							</code>
							<code className="language-sass">
								<span className="token css-selector">
									&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;.
								</span>
								<span className="token selector">
									query-demo
								</span>
								<span className="token puntuation-class">
									&#160;&#123;
								</span>
							</code>
							<code className="language-sass">
								<span className="token css-property">
									&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;width
								</span>
								<span className="token punctuation">
									&#160;:
								</span>
								<spam className="token css-value">
									&#160;400
								</spam>
								<span className="token unit">px</span>
								<span className="token punctuation">;</span>
								<span className="token puntuation-class">
									&#160;&#125;
								</span>
							</code>
							<code className="language-sass">
								<span className="token puntuation-class">
									&#160;&#160;&#160;&#160;&#125;
								</span>
							</code>
							<code className="language-sass">
								<span className="token puntuation-class">
									&#125;
								</span>
							</code>
							<div className="toolbar">
								<div className="toolbar-item">
									<span>CSS</span>
								</div>
							</div>
						</pre>
						<div className="container-demo">
							<div className="center-cross db-s">
								<div className="query-demo-sass jcc-s acc-s">
									<div>Query (SASS)</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</main>
		</>
	);
};

export default Query;
