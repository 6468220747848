import React from "react";
import Aside from "../Aside";
import { NavLink } from "react-router-dom";
import GridLinesImg from "../../img/grid_lines.png";
import Order from "./Order";
const activeStyle = { color: "var(--text-list-color-hover)" };

const Grid = () => {
	return (
		<>
			<main className="main-content g-container gtc4-s gcg1-s">
				<Aside />
				<section className="main-section gcss3-s">
					<div className="l-block-2"></div>
					<h2>Grid</h2>
					<div className="main-block">
						<p className="main-text">
							Para crear layouts css grid disponemos de clases en
							los apartados de
							<NavLink to="/alignment" activeStyle={activeStyle}>
								&nbsp;alineaciones
							</NavLink>{" "}
							grid,{" "}
							<NavLink to="/alignment" activeStyle={activeStyle}>
								&nbsp;display&nbsp;
							</NavLink>
							y además 3 tipos más de clases en este apartado.
						</p>
						<p className="main-text">
							Para poder crear un contenedor grid con unas
							caracterïsticas predeterminadas disponemos de la
							clase <code>g-container</code>. Por defecto esta
							clase aplica una columna .
							<code>grid-template-columns: 100%</code>, un{" "}
							<code>gap:1rem</code> y un ancho mínimo de 1200px (
							<code>max-width: 1200px</code>) cuando el ancho es
							superior a esa tamaño.
						</p>
					</div>
					<div class="main-block">
						<h3>Grid columns</h3>
						<p className="main-text">
							Para crear un layout css grid hay que seguir estas
							reglas:
						</p>
						<ul>
							<li>
								El contenedor es donde se define la cantidad de
								columnas en cada breakpoint. Por defecto{" "}
								<code>g.container</code> crea una sola columna.
								Para crear ms columnas hay que utilizar junto
								con el <code>g-container</code>
								la clase{" "}
								<code>gtc + el número de columnas</code>. Para
								crear las tres columnas del ejemplo que tenemos
								debajo, la clase necesaria seria{" "}
								<code>gtc3-s</code>, para crear 4{" "}
								<code>gtc4-s</code>, donde el número equivale a
								las columnas deseadas. El brealpoint -s tambien
								se puede elegir con los diferentes tamaños
								existentes.
							</li>
							<li>
								En los item se definen las columnas que
								ocuparán.
							</li>
							<li>
								Solo el contenedor tiene una clase definida
								<code>g-container</code>. Los items pueden ser
								cualquier elemento hijo directo de{" "}
								<code>g-container</code>.
							</li>
							<li>
								CSS Grid utiliza la propiedad <code>gap</code>{" "}
								para definir la separación entre los items. Por
								defecto siempre <code>g-container</code> tiene
								1rem, en vertical (<code>columns</code>) y
								horizontal (<code>rows</code>).
							</li>
						</ul>
						<pre className="language-html code-toolbar">
							<code className="language-html">
								<span className="token comment">
									&#60;&#33;&#45;&#45;&#32;
								</span>
								<span className="token comment">
									Layout de tres columnas donde cada caja
									ocupa una columna.
								</span>
							</code>
							<code class="language-html code-toolbar">
								<span className="token comment">
									No tiene que hacer nada en los items. Solo
									definir las columnas en el contenedor.
								</span>
							</code>
							<code class="language-html code-toolbar">
								<span className="token comment">
									Pero en este ejemplo lo hemos aplicado para
									ver su uso en los items
								</span>
								<span className="token comment">
									&#45;&#45;&#62;
								</span>
							</code>
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">div&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">
									g-container gtc3-s jcc-s
								</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									<code className="language-html">
										<span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
										<span className="token punctuation">
											&lt;
										</span>
										<span className="token tag">
											div&nbsp;
										</span>
										<span className="token attr-name">
											class
										</span>
										<span className="token punctuation">
											="
										</span>
										<span className="token attr-value">
											gces1-s
										</span>
										<span className="token punctuation">
											"
										</span>
										<span className="token punctuation">
											&gt;
										</span>
										<span className="token html-value">
											1
										</span>
										<span className="token punctuation">
											&lt;/
										</span>
										<span className="token tag">div</span>
										<span className="token punctuation">
											&gt;
										</span>
									</code>
									<code className="language-html">
										<span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
										<span className="token punctuation">
											&lt;
										</span>
										<span className="token tag">
											div&nbsp;
										</span>
										<span className="token attr-name">
											class
										</span>
										<span className="token punctuation">
											="
										</span>
										<span className="token attr-value">
											gces1-s
										</span>
										<span className="token punctuation">
											"
										</span>
										<span className="token punctuation">
											&gt;
										</span>
										<span className="token html-value">
											2
										</span>
										<span className="token punctuation">
											&lt;/
										</span>
										<span className="token tag">div</span>
										<span className="token punctuation">
											&gt;
										</span>
									</code>
									<code className="language-html">
										<span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
										<span className="token punctuation">
											&lt;
										</span>
										<span className="token tag">
											div&nbsp;
										</span>
										<span className="token attr-name">
											class
										</span>
										<span className="token punctuation">
											="
										</span>
										<span className="token attr-value">
											gces1-s
										</span>
										<span className="token punctuation">
											"
										</span>
										<span className="token punctuation">
											&gt;
										</span>
										<span className="token html-value">
											3
										</span>
										<span className="token punctuation">
											&lt;/
										</span>
										<span className="token tag">div</span>
										<span className="token punctuation">
											&gt;
										</span>
									</code>
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">div</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<div className="toolbar">
								<div className="toolbar-item">
									<span>HTML</span>
								</div>
							</div>
						</pre>
						<div class="g-container-demo gtc3-s jcc-s">
							<div class="item-box text-center-s gces1-s">1</div>
							<div class="item-box text-center-s gces1-s">2</div>
							<div class="item-box text-center-s gces1-s">3</div>
						</div>
					</div>
					<div class="main-block">
						<h3>Grid rows</h3>
						<p className="main-text">
							Igual que para crear columnas en layouts, para crear
							filas tenemos las mismas opciones:
						</p>
						<ul>
							<li>
								El contenedor es donde se define la cantidad de
								filas en cada breakpoint. Por defecto{" "}
								<code>g.container</code> crea una sola fila.
							</li>
							<li>
								En los item se definen las filas que ocuparán.
							</li>
							<li>
								Solo el contenedor tiene una clase definida
								<code>g-container</code>. Los items pueden ser
								cualquier elemento hijo directo de{" "}
								<code>g-container</code>.
							</li>
							<li>
								CSS Grid utiliza la propiedad <code>gap</code>{" "}
								para definir la separación entre los items. Por
								defecto siempre <code>g-container</code> tiene
								1rem, en vertical (<code>columns</code>) y
								horizontal (<code>rows</code>).
							</li>
						</ul>
						<pre className="language-html code-toolbar">
							<code className="language-html">
								<span className="token comment">
									&#60;&#33;&#45;&#45;&#32;
								</span>
								<span className="token comment">
									Layout de tres filas donde cada caja ocupa
									una fila.
								</span>
							</code>
							<code class="language-html code-toolbar">
								<span className="token comment">
									No tiene que hacer nada en los items. Solo
									definir las filas en el contenedor.{" "}
								</span>
							</code>{" "}
							<code class="language-html code-toolbar">
								<span className="token comment">
									Pero en este ejemplo lo hemos aplicado para
									ver su uso en los items
								</span>
								<span className="token comment">
									&#45;&#45;&#62;
								</span>
							</code>
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">div&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">
									g-container
								</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									<code className="language-html">
										<span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
										<span className="token punctuation">
											&lt;
										</span>
										<span className="token tag">
											div&nbsp;
										</span>
										<span className="token attr-name">
											class
										</span>
										<span className="token punctuation">
											="
										</span>
										<span className="token attr-value">
											gres1-s
										</span>
										<span className="token punctuation">
											"
										</span>
										<span className="token punctuation">
											&gt;
										</span>
										<span className="token html-value">
											1
										</span>
										<span className="token punctuation">
											&lt;/
										</span>
										<span className="token tag">div</span>
										<span className="token punctuation">
											&gt;
										</span>
									</code>
									<code className="language-html">
										<span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
										<span className="token punctuation">
											&lt;
										</span>
										<span className="token tag">
											div&nbsp;
										</span>
										<span className="token attr-name">
											class
										</span>
										<span className="token punctuation">
											="
										</span>
										<span className="token attr-value">
											gres1-s
										</span>
										<span className="token punctuation">
											"
										</span>
										<span className="token punctuation">
											&gt;
										</span>
										<span className="token html-value">
											2
										</span>
										<span className="token punctuation">
											&lt;/
										</span>
										<span className="token tag">div</span>
										<span className="token punctuation">
											&gt;
										</span>
									</code>
									<code className="language-html">
										<span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
										<span className="token punctuation">
											&lt;
										</span>
										<span className="token tag">
											div&nbsp;
										</span>
										<span className="token attr-name">
											class
										</span>
										<span className="token punctuation">
											="
										</span>
										<span className="token attr-value">
											gres1-s
										</span>
										<span className="token punctuation">
											"
										</span>
										<span className="token punctuation">
											&gt;
										</span>
										<span className="token html-value">
											3
										</span>
										<span className="token punctuation">
											&lt;/
										</span>
										<span className="token tag">div</span>
										<span className="token punctuation">
											&gt;
										</span>
									</code>
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">div</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<div className="toolbar">
								<div className="toolbar-item">
									<span>HTML</span>
								</div>
							</div>
						</pre>
						<div class="g-container-demo">
							<div class="item-box text-center-s gres1-s">1</div>
							<div class="item-box text-center-s gres1-s">2</div>
							<div class="item-box text-center-s gres1-s">3</div>
						</div>
					</div>
					<div class="main-block">
						<h3>Column-Rows start, end y span</h3>
						<p className="main-text">
							Cuando creas un contenedor grid con{" "}
							<code>g-contaniner</code> o con la clase{" "}
							<code>dg</code> con ms de una columna o fila,
							siempre hay que asignar a cada item las columnas o
							filas que ocupen. Todo esto se ve en los ejemplos
							anteriores que siguen las cuatro reglas que tenemos
							para crear layouts de más de una columna o fila.
						</p>
						<p className="main-text">
							Para indicar al item que tamaño deben ocupar
							disponemos de dos formas:
						</p>
						<ul>
							<li>
								La primera opción le indica al item las columnas
								o filas que se tiene que expandir. Para ello
								utilizamos <code>gce2-s</code> que nos agrega{" "}
								<code>grid-column-end: span 2</code>,
								<code>gcs3-s</code> que nos agrega
								<code>grid-column-start: span 3</code>,{" "}
								<code>grs3-s</code> que nos agrega
								<code>grid-rows-start: span 3</code>, donde el
								número equivale a las columnas o filas que
								ocupara el item. Los ejemplos anteriores se
								basan en esta primera opción con{" "}
								<code>span</code>.
							</li>
							<li>
								La segunda opción hay que indicar al item en que
								intersección (Line) inicia y en la que termina.
								En este caso si tenemos tres columnas,
								dispondremos de 4 interserciones (Line).
							</li>
						</ul>
						<img
							className="center-cross w50"
							src={GridLinesImg}
							alt=""
						/>
						<div className="l-block-2"></div>
						<p className="main-text">
							Layout de tres columnas con{" "}
							<code>grid-column-start</code> y{" "}
							<code>grid-column-end</code>
						</p>
						<pre className="language-html code-toolbar">
							<code className="language-html">
								<span className="token comment">
									&#60;&#33;&#45;&#45;&#32;
								</span>
								<span className="token comment">
									Layout de tres columnas donde cada caja
									ocupa una columna.
								</span>
							</code>
							<code class="language-html code-toolbar">
								<span className="token comment">
									No tiene que hacer nada en los items. Solo
									definir las columnas en el contenedor.
								</span>
							</code>
							<code class="language-html code-toolbar">
								<span className="token comment">
									Pero en este ejemplo lo hemos aplicado para
									ver su uso en los items
								</span>
								<span className="token comment">
									&#45;&#45;&#62;
								</span>
							</code>
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">div&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">
									g-container gtc3-s jcc-s
								</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									<code className="language-html">
										<span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
										<span className="token punctuation">
											&lt;
										</span>
										<span className="token tag">
											div&nbsp;
										</span>
										<span className="token attr-name">
											class
										</span>
										<span className="token punctuation">
											="
										</span>
										<span className="token attr-value">
											gcs1-s gce2-s
										</span>
										<span className="token punctuation">
											"
										</span>
										<span className="token punctuation">
											&gt;
										</span>
										<span className="token html-value">
											1
										</span>
										<span className="token punctuation">
											&lt;/
										</span>
										<span className="token tag">div</span>
										<span className="token punctuation">
											&gt;
										</span>
									</code>
									<code className="language-html">
										<span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
										<span className="token punctuation">
											&lt;
										</span>
										<span className="token tag">
											div&nbsp;
										</span>
										<span className="token attr-name">
											class
										</span>
										<span className="token punctuation">
											="
										</span>
										<span className="token attr-value">
											gcs2-s gce3-s
										</span>
										<span className="token punctuation">
											"
										</span>
										<span className="token punctuation">
											&gt;
										</span>
										<span className="token html-value">
											2
										</span>
										<span className="token punctuation">
											&lt;/
										</span>
										<span className="token tag">div</span>
										<span className="token punctuation">
											&gt;
										</span>
									</code>
									<code className="language-html">
										<span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
										<span className="token punctuation">
											&lt;
										</span>
										<span className="token tag">
											div&nbsp;
										</span>
										<span className="token attr-name">
											class
										</span>
										<span className="token punctuation">
											="
										</span>
										<span className="token attr-value">
											gcs3-s gce4-s
										</span>
										<span className="token punctuation">
											"
										</span>
										<span className="token punctuation">
											&gt;
										</span>
										<span className="token html-value">
											3
										</span>
										<span className="token punctuation">
											&lt;/
										</span>
										<span className="token tag">div</span>
										<span className="token punctuation">
											&gt;
										</span>
									</code>
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">div</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<div className="toolbar">
								<div className="toolbar-item">
									<span>HTML</span>
								</div>
							</div>
						</pre>
						<div class="g-container-demo gtc3-s jcc-s">
							<div class="item-box text-center-s gcs1-s gce2-s">
								1
							</div>
							<div class="item-box text-center-s gcs2-s gce3-s">
								2
							</div>
							<div class="item-box text-center-s gcs3-s gce4-s">
								3
							</div>
						</div>
						<div class="l-block-2"></div>
						<p className="main-text">
							Layout de seis columnas con{" "}
							<code>grid-column-start</code> y{" "}
							<code>grid-column-end</code> con diferentes tamaños
							para los items.
						</p>
						<pre className="language-html code-toolbar">
							<code className="language-html">
								<span className="token comment">
									&#60;&#33;&#45;&#45;&#32;
								</span>
								<span className="token comment">
									Layout de seis columnas,
								</span>
							</code>
							<code class="language-html code-toolbar">
								<span className="token comment">
									Se define grid line inicial y el final en
									los items.
								</span>
								<span className="token comment">
									&#45;&#45;&#62;
								</span>
							</code>
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">div&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">
									g-container gtc6-s
								</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									<code className="language-html">
										<span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
										<span className="token punctuation">
											&lt;
										</span>
										<span className="token tag">
											div&nbsp;
										</span>
										<span className="token attr-name">
											class
										</span>
										<span className="token punctuation">
											="
										</span>
										<span className="token attr-value">
											gcs1-s gce2-s
										</span>
										<span className="token punctuation">
											"
										</span>
										<span className="token punctuation">
											&gt;
										</span>
										<span className="token html-value">
											1
										</span>
										<span className="token punctuation">
											&lt;/
										</span>
										<span className="token tag">div</span>
										<span className="token punctuation">
											&gt;
										</span>
									</code>
									<code className="language-html">
										<span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
										<span className="token punctuation">
											&lt;
										</span>
										<span className="token tag">
											div&nbsp;
										</span>
										<span className="token attr-name">
											class
										</span>
										<span className="token punctuation">
											="
										</span>
										<span className="token attr-value">
											gcs2-s gce5-s
										</span>
										<span className="token punctuation">
											"
										</span>
										<span className="token punctuation">
											&gt;
										</span>
										<span className="token html-value">
											2
										</span>
										<span className="token punctuation">
											&lt;/
										</span>
										<span className="token tag">div</span>
										<span className="token punctuation">
											&gt;
										</span>
									</code>
									<code className="language-html">
										<span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
										<span className="token punctuation">
											&lt;
										</span>
										<span className="token tag">
											div&nbsp;
										</span>
										<span className="token attr-name">
											class
										</span>
										<span className="token punctuation">
											="
										</span>
										<span className="token attr-value">
											gcs5-s gce7-s
										</span>
										<span className="token punctuation">
											"
										</span>
										<span className="token punctuation">
											&gt;
										</span>
										<span className="token html-value">
											3
										</span>
										<span className="token punctuation">
											&lt;/
										</span>
										<span className="token tag">div</span>
										<span className="token punctuation">
											&gt;
										</span>
									</code>
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">div</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<div className="toolbar">
								<div className="toolbar-item">
									<span>HTML</span>
								</div>
							</div>
						</pre>
						<div class="g-container-demo gtc6-s">
							<div class="item-box text-center-s gcs1-s gce2-s">
								1
							</div>
							<div class="item-box text-center-s gcs2-s gce5-s">
								2
							</div>
							<div class="item-box text-center-s gcs5-s gce7-s">
								3
							</div>
						</div>
						<p className="main-text">
							Estos mismos métodos se aplican a las{" "}
							<code>rows</code> con las clases correspondientes a
							las filas <code>grid-template-rows</code>,{" "}
							<code>grid-rows-start</code> y{" "}
							<code>grid-rows-end</code> o{" "}
							<code>grid-rows-star: span number</code>
							<code>grid-rows-end: span number</code>.
						</p>
					</div>
					<Order />
				</section>
			</main>
		</>
	);
};

export default Grid;
