import React from "react";
import Aside from "../Aside";

const Button = (location) => {
	return (
		<>
			<main className="main-content g-container gtc4-s gcg1-s">
				<Aside />
				<section className="main-section gcss3-s">
					<div className="l-block-2"></div>
					<div className="main-block">
						<h2>Botones</h2>
						<p className="main-text">
							La clase para crear un boton es <code>button</code>.
						</p>
						<pre className="language-html code-toolbar">
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">a&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">button</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									Primer boton
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">a</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<div className="toolbar">
								<div className="toolbar-item">
									<span>HTML</span>
								</div>
							</div>
						</pre>
						<p>
							<a href={location.pathname} className="button">
								Primer boton
							</a>
						</p>
						<p>
							Para separar botones se pueden utilizar las clases
							para aplicar margenes de clickerti-css
						</p>
						<pre className="language-html code-toolbar">
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">a&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">
									button&nbsp;
								</span>
								<span className="token attr-value">
									mr16-s mb16-s
								</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									Primer boton
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">a</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">a&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">button</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									Segundo boton
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">a</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<div className="toolbar">
								<div className="toolbar-item">
									<span>HTML</span>
								</div>
							</div>
						</pre>
						<p>
							<a
								href={location.pathname}
								className="button mr16-s mb16-s"
							>
								Primer boton
							</a>
							<a href={location.pathname} className="button">
								Segundo boton
							</a>
						</p>
					</div>
					<div className="main-block">
						<h3>1. Botones fantasma (ghost)</h3>
						<p className="main-text">
							La clase para crear un boton de tipo ghost es
							<code>ghost</code>. Los botones fantasma tienen
							fondo transparente y solo constan de borde y texto.
						</p>
						<pre className="language-html code-toolbar">
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">a&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">
									button ghost
								</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									.button.ghost
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">a</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<div className="toolbar">
								<div className="toolbar-item">
									<span>HTML</span>
								</div>
							</div>
						</pre>
						<p>
							<a
								href={location.pathname}
								className="button ghost"
							>
								.button.ghost
							</a>
						</p>
					</div>
					<div className="main-block">
						<h3>2. Botones redondos</h3>
						<p className="main-text">
							Tenemos varios tamaños para los botones redondos.
							Los tamaños son 16, 24, 32, 40, 48 y 64. Para crear
							un boton redondo hay que utilizar la clase round mas
							el tamaño. Las clases para utilizar los diferentes
							tamaños predeterminados son <code>round-16</code>,{" "}
							<code>round-24</code>,<code>round-32</code>,
							<code>round-40</code>,<code>round-48</code> y
							<code>round-64</code>
						</p>
						<p className="main-text">
							Existe una clase <code>round</code> que por defecto
							crea un botón de 32px. Permite agregar tamaño
							especial pero creando una clase con el padding
							específico.
						</p>
						<pre className="language-html code-toolbar">
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">a&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">
									button round
								</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">+</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">a</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<div className="toolbar">
								<div className="toolbar-item">
									<span>HTML</span>
								</div>
							</div>
						</pre>
						<p className="df-s">
							<a
								href={location.pathname}
								className="button round"
							>
								+
							</a>
						</p>
						<p className="main-text">
							Diferentes tamaños existentes:
						</p>
						<pre className="language-html code-toolbar">
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">a&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">
									button round-16
								</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">+</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">a</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">a&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">
									button round-24
								</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">+</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">a</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">a&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">
									button round-32
								</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">+</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">a</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">a&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">
									button round-40
								</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">+</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">a</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">a&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">
									button round-48
								</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">+</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">a</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">a&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">
									button round-64
								</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">+</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">a</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<div className="toolbar">
								<div className="toolbar-item">
									<span>HTML</span>
								</div>
							</div>
						</pre>
						<p className="df-s aic-s">
							<a
								href={location.pathname}
								className="button round-16 mr8-s mb16-s"
							>
								+
							</a>
							<a
								href={location.pathname}
								className="button round-24 mr8-s mb16-s"
							>
								+
							</a>
							<a
								href={location.pathname}
								className="button round-32 mr8-s mb16-s"
							>
								+
							</a>
							<a
								href={location.pathname}
								className="button round-40 mr8-s mb16-s"
							>
								+
							</a>
							<a
								href={location.pathname}
								className="button round-48 mr8-s mb16-s"
							>
								+
							</a>
							<a
								href={location.pathname}
								className="button round-64 mr8-s mb16-s"
							>
								+
							</a>
						</p>
					</div>
					<div className="main-block">
						<h3>3. Colores de los botones</h3>
						<p className="main-text">
							Use las clases <code>green-button-color</code>,
							<code>red-button-color</code>,{" "}
							<code>celeste-button-color</code>,
							<code>orange-button-color</code>,{" "}
							<code>dark-button-color</code>,
							<code>sea-button-color</code>,{" "}
							<code>gray-button-color</code>,
							<code>light-button-color</code> para colorear el
							boton. Por defecto, la clase <code>button</code> da
							un botón azul, pero si se necesita forzar el color
							azul use <code>blue-button-color</code>. Las clases
							pueden combinarse con todas las bases de boton
							disponibles, <code>button</code>,{" "}
							<code>button ghost</code> o{" "}
							<code>button round</code>.
						</p>
						<pre className="language-html code-toolbar">
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">a&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">
									button blue-button-color
								</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									.button.blue-button-color
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">a</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">a&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">
									button green-button-color
								</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									.button.green-button-color
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">a</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">a&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">
									button red-button-color
								</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									.button.red-button-color
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">a</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">a&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">
									button sea-button-color
								</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									.button.sea-button-color
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">a</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">a&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">
									button celeste-button-color
								</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									.button.celeste-button-color
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">a</span>
								<span className="token punctuation">&gt;</span>
							</code>{" "}
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">a&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">
									button orange-button-color
								</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									.button.orange-button-color
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">a</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">a&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">
									button dark-button-color
								</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									.button.dark-button-color
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">a</span>
								<span className="token punctuation">&gt;</span>
							</code>{" "}
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">a&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">
									button gray-button-color
								</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									.button.gray-button-color
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">a</span>
								<span className="token punctuation">&gt;</span>
							</code>{" "}
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">a&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">
									button light-button-color
								</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									.button.light-button-color
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">a</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">a&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">
									button softgray-button-color
								</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									.button.softgray-button-color
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">a</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<div className="toolbar">
								<div className="toolbar-item">
									<span>HTML</span>
								</div>
							</div>
						</pre>
						<p className="df-s fww-s">
							<a
								href={location.pathname}
								className="button blue-button-color mr8-s mb16-s"
							>
								.button.blue-button-color
							</a>
							<a
								href={location.pathname}
								className="button green-button-color mr8-s mb16-s"
							>
								.button.green-button-color
							</a>
							<a
								href={location.pathname}
								className="button red-button-color mr8-s mb16-s"
							>
								.button.red-button-color
							</a>
							<a
								href={location.pathname}
								className="button sea-button-color mr8-s mb16-s"
							>
								.button.sea-button-color
							</a>{" "}
							<a
								href={location.pathname}
								className="button celeste-button-color mr8-s mb16-s"
							>
								.button.celeste-button-color
							</a>{" "}
							<a
								href={location.pathname}
								className="button orange-button-color mr8-s mb16-s"
							>
								.button.orange-button-color
							</a>{" "}
							<a
								href={location.pathname}
								className="button dark-button-color mr8-s mb16-s"
							>
								.button.dark-button-color
							</a>{" "}
							<a
								href={location.pathname}
								className="button gray-button-color mr8-s mb16-s"
							>
								.button.gray-button-color
							</a>{" "}
							<a
								href={location.pathname}
								className="button light-button-color mr8-s mb16-s"
							>
								.button.light-button-color
							</a>
							<a
								href={location.pathname}
								className="button softgray-button-color mr8-s mb16-s"
							>
								.button.softgray-button-color
							</a>
						</p>
					</div>
					<div className="main-block">
						<h3>4. Tipos de redondeados</h3>
						<p className="main-text">
							Existen tres clases de redondeo para los dos tipos
							de botones <code>button</code>,{" "}
							<code>button ghost</code>. Las clases disponibles
							para el redondear las esquinas son{" "}
							<code>radius-small</code>,{" "}
							<code>radius-medium</code>,{" "}
							<code>radius-rounded</code> para redondear un boton.
						</p>
						<pre className="language-html code-toolbar">
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">a&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">
									button radius-small
								</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									.button.radius-small
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">a</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">a&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">
									button radius-medium
								</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									.button.radius-medium
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">a</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">a&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">
									button radius-rounded
								</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									.button.radius-rounded
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">a</span>
								<span className="token punctuation">&gt;</span>
							</code>{" "}
							<div className="toolbar">
								<div className="toolbar-item">
									<span>HTML</span>
								</div>
							</div>
						</pre>
						<p className="df-s fww-s">
							<a
								href={location.pathname}
								className="button radius-small mr8-s mb16-s"
							>
								.button.radius-small
							</a>
							<a
								href={location.pathname}
								className="button radius-medium mr8-s mb16-s"
							>
								.button.radius-medium
							</a>
							<a
								href={location.pathname}
								className="button radius-rounded mr8-s mb16-s"
							>
								.button.radius-rounded
							</a>
						</p>
						<pre className="language-html code-toolbar">
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">a&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">
									button ghost radius-small
								</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									.button.ghost.radius-small
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">a</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">a&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">
									button ghost radius-medium
								</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									.button.ghost.radius-medium
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">a</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">a&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">
									button ghost radius-rounded
								</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									.button.ghost.radius-rounded
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">a</span>
								<span className="token punctuation">&gt;</span>
							</code>{" "}
							<div className="toolbar">
								<div className="toolbar-item">
									<span>HTML</span>
								</div>
							</div>
						</pre>
						<p className="df-s fww-s">
							<a
								href={location.pathname}
								className="button ghost radius-small mr8-s mb16-s"
							>
								.button.ghost.radius-small
							</a>
							<a
								href={location.pathname}
								className="button ghost radius-medium mr8-s mb16-s"
							>
								.button.ghost.radius-medium
							</a>
							<a
								href={location.pathname}
								className="button ghost radius-rounded mr8-s mb16-s"
							>
								.button.ghost.radius-rounded
							</a>
						</p>
						<p className="main-text">
							También se pueden utilizar para redondear cualquier
							caja que tenga una propiedad <code>background</code>{" "}
							o <code>border</code>.
						</p>
						<pre className="language-html code-toolbar">
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">a&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">
									radius-small
								</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									.backgroung-ejem.radius-small
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">a</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">a&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">
									border-ejem radius-rounded
								</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									.border-ejem.radius-rounded
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">a</span>
								<span className="token punctuation">&gt;</span>
							</code>
						</pre>
						<p className="df-s fww-s jcc-s">
							<div className="background-demo-center radius-small mr8-s mb16-s">
								radius-small
							</div>
							<div className="border-demo-flex jc-cf-s radius-rounded mr8-s mb16-s">
								radius-rounded
							</div>
						</p>
					</div>
					<div className="main-block">
						<h3>5. Tamaños de los botones</h3>
						<p className="main-text">
							Existen cinco clases para el tamaño para usar con
							los dos tipos de botones <code>button</code>,{" "}
							<code>button ghost</code>. Las clases disponibles
							para controlar el tamaño son <code>big</code>,{" "}
							<code>small</code>, <code>tiny</code>,{" "}
							<code>micro</code>. La clase <code>button</code> es
							el 5 tamaño y el que hay que usar para el tamaño
							normal por defecto.
						</p>
						<pre className="language-html code-toolbar">
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">a&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">
									button big
								</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									.button.big
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">a</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">a&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">button</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									.button
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">a</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">a&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">
									button small
								</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									.button.small
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">a</span>
								<span className="token punctuation">&gt;</span>
							</code>{" "}
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">a&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">
									button tiny
								</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									.button.tiny
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">a</span>
								<span className="token punctuation">&gt;</span>
							</code>{" "}
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">a&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">
									button micro
								</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									.button.micro
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">a</span>
								<span className="token punctuation">&gt;</span>
							</code>{" "}
							<div className="toolbar">
								<div className="toolbar-item">
									<span>HTML</span>
								</div>
							</div>
						</pre>
						<p className="df-s fww-s aic-s">
							{" "}
							<div>
								{" "}
								<a
									href={location.pathname}
									className="button big mr8-s mb16-s"
								>
									.button.big
								</a>
							</div>
							<div>
								<a
									href={location.pathname}
									className="button mr8-s mb16-s"
								>
									.button
								</a>{" "}
							</div>
							<div>
								<a
									href={location.pathname}
									className="button small mr8-s mb16-s"
								>
									.button.small
								</a>{" "}
							</div>
							<div>
								<a
									href={location.pathname}
									className="button tiny mr8-s mb16-s"
								>
									.button.tiny
								</a>{" "}
							</div>
							<div>
								<a
									href={location.pathname}
									className="button micro mr8-s mb16-s"
								>
									.button.micro
								</a>
							</div>
						</p>
						<p className="main-text">
							Ejemplo de las cinco clases de tamaño para usar con
							el boton de tipo <code>button ghost</code>.
						</p>
						<pre className="language-html code-toolbar">
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">a&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">
									button ghost big
								</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									.button.ghost.big
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">a</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">a&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">
									button ghost
								</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									.button.ghost
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">a</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">a&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">
									button ghost small
								</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									.button.ghost.small
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">a</span>
								<span className="token punctuation">&gt;</span>
							</code>{" "}
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">a&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">
									button ghost tiny
								</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									.button.ghost.tiny
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">a</span>
								<span className="token punctuation">&gt;</span>
							</code>{" "}
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">a&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">
									button ghost micro
								</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									.button.ghost.micro
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">a</span>
								<span className="token punctuation">&gt;</span>
							</code>{" "}
							<div className="toolbar">
								<div className="toolbar-item">
									<span>HTML</span>
								</div>
							</div>
						</pre>
						<p className="df-s fww-s aic-s">
							{" "}
							<div>
								{" "}
								<a
									href={location.pathname}
									className="button ghost big mr8-s mb16-s"
								>
									.button.big
								</a>
							</div>
							<div>
								<a
									href={location.pathname}
									className="button ghost mr8-s mb16-s"
								>
									.button
								</a>{" "}
							</div>
							<div>
								<a
									href={location.pathname}
									className="button ghost small mr8-s mb16-s"
								>
									.button.small
								</a>{" "}
							</div>
							<div>
								<a
									href={location.pathname}
									className="button ghost tiny mr8-s mb16-s"
								>
									.button.tiny
								</a>{" "}
							</div>
							<div>
								<a
									href={location.pathname}
									className="button ghost micro mr8-s mb16-s"
								>
									.button.micro
								</a>
							</div>
						</p>
					</div>
					<div className="main-block">
						<h3>6. Deshabilitar los botones (disabled)</h3>
						<p className="main-text">
							Para deshabilitar un boton hay dos formas. Una clase{" "}
							<code>disabled</code> o utilizar{" "}
							<code>disabled</code> como atributo en la etiqueta
							del boton.
						</p>
						<pre className="language-html code-toolbar">
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">a&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">
									button disabled
								</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									.button.disabled
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">a</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">a&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">
									button ghost
								</span>
								<span className="token punctuation">"</span>
								<span className="token attr-name">
									&nbsp;disabled
								</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									.button.ghost disabled
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">a</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">a&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">
									button ghost red-button-color disabled
								</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									.button.ghost.red-button-color.disabled
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">a</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">a&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">
									button red-button-color disabled
								</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									.button.red-button-color.disabled
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">a</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<div className="toolbar">
								<div className="toolbar-item">
									<span>HTML</span>
								</div>
							</div>
						</pre>
						<p className="df-s fww-s aic-s">
							{" "}
							<div>
								{" "}
								<a
									href={location.pathname}
									className="button disabled mr8-s mb16-s"
								>
									.button.disabled
								</a>
							</div>
							<div>
								{" "}
								<a
									href={location.pathname}
									className="button ghost mr8-s mb16-s"
									disabled
								>
									.button.ghost disabled
								</a>
							</div>
							<div>
								{" "}
								<a
									href={location.pathname}
									className="button ghost disabled red-button-color mr8-s mb16-s"
								>
									.button.ghost.ghost.red-button-color
								</a>
							</div>{" "}
							<div>
								{" "}
								<a
									href={location.pathname}
									className="button red-button-color disabled mr8-s mb16-s"
								>
									.button.red-button-color.disabled
								</a>
							</div>
						</p>
					</div>
				</section>
			</main>
		</>
	);
};

export default Button;
