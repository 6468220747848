import React from "react";

const Order = () => {
	return (
		<>
			<div class="main-block">
				<h3>Order</h3>
				<p className="main-text">
					Cuando creamos layouts flex y grid en alguna ocasión hay que
					cambiar el orden que los componentes que componen el
					layouts. Para esto esta la propiedad{" "}
					<code>orderNumber-tamaño</code>. Para aplicarla con una
					clase html tenemos la clase <code>order1-xs</code>, donde el
					número es la posición que queremos para el componente.
				</p>
				<p className="main-text">Como se utiliza con grid</p>
				<pre className="language-html code-toolbar">
					<code className="language-html">
						<span className="token punctuation">&lt;</span>
						<span className="token tag">div&nbsp;</span>
						<span className="token attr-name">class</span>
						<span className="token punctuation">="</span>
						<span className="token attr-value">g-container gtc3-s</span>
						<span className="token punctuation">"</span>
						<span className="token punctuation">&gt;</span>
						<span className="token html-value">
							<code className="language-html">
								<span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
								<span className="token punctuation">&lt;</span>
								<span className="token tag">div&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">
									order2-s
								</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">1</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">div</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<code className="language-html">
								<span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
								<span className="token punctuation">&lt;</span>
								<span className="token tag">div&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">
									order3-s
								</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">2</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">div</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<code className="language-html">
								<span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
								<span className="token punctuation">&lt;</span>
								<span className="token tag">div&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">
									order1-s
								</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">3</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">div</span>
								<span className="token punctuation">&gt;</span>
							</code>
						</span>
						<span className="token punctuation">&lt;/</span>
						<span className="token tag">div</span>
						<span className="token punctuation">&gt;</span>
					</code>
					<div className="toolbar">
						<div className="toolbar-item">
							<span>HTML</span>
						</div>
					</div>
				</pre>
				<div class="g-container-demo gtc3-s">
					<div class="item-box text-center-s order2-s">1</div>
					<div class="item-box text-center-s order3-s">2</div>
					<div class="item-box text-center-s order1-s">3</div>
				</div>
				<p className="main-text">Como se utiliza con flex.</p>
				<pre className="language-html code-toolbar">
					<code className="language-html">
						<span className="token punctuation">&lt;</span>
						<span className="token tag">div&nbsp;</span>
						<span className="token attr-name">class</span>
						<span className="token punctuation">="</span>
						<span className="token attr-value">f-container jcsb-s</span>
						<span className="token punctuation">"</span>
						<span className="token punctuation">&gt;</span>
						<span className="token html-value">
							<code className="language-html">
								<span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
								<span className="token punctuation">&lt;</span>
								<span className="token tag">div&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">
									order2-s
								</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">1</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">div</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<code className="language-html">
								<span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
								<span className="token punctuation">&lt;</span>
								<span className="token tag">div&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">
									order3-s
								</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">2</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">div</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<code className="language-html">
								<span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
								<span className="token punctuation">&lt;</span>
								<span className="token tag">div&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">
									order1-s
								</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">3</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">div</span>
								<span className="token punctuation">&gt;</span>
							</code>
						</span>
						<span className="token punctuation">&lt;/</span>
						<span className="token tag">div</span>
						<span className="token punctuation">&gt;</span>
					</code>
					<div className="toolbar">
						<div className="toolbar-item">
							<span>HTML</span>
						</div>
					</div>
				</pre>
				<div class="f-container-demo jcsb-s">
					<div class="item-box text-center-s w30-s order2-s mlr2-s">
						1
					</div>
					<div class="item-box text-center-s w30-s order3-s mlr2-s">
						2
					</div>
					<div class="item-box text-center-s w30-s order1-s mlr2-s">
						3
					</div>
				</div>
			</div>
		</>
	);
};

export default Order;
