import React from "react";
import Aside from "../Aside";

const Display = () => {
	return (
		<>
			<main className="main-content g-container gtc4-s gcg1-s">
				<Aside />
				<section className="main-section gcss3-s">
					<div className="l-block-2"></div>
					<h2>Display</h2>
					<div className="main-block">
						<p className="main-text">
							Mediante estas clases podremos modificar la
							propiedad <code>display</code> de los controles en
							nuestras web. Esta nos permite indicar en todo
							momento el espacio a ocupar y como de los elementos
							de la web, por defecto la mayoria de elementos
							tendrá por defecto los valores <code>block</code> y{" "}
							<code>inline</code>.
						</p>
						<h3>display: di (inline)</h3>
						<p className="main-text">
							Un elemento en línea no comienza en una nueva línea
							y solo ocupa el ancho necesario.
							<br />
							Ejemplo de elementos en línea:
						</p>
						<pre className="language-html code-toolbar">
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">div&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">
									di-s mr8-s
								</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">HTML</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">div</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">div&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">
									di-s mr8-s
								</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">CSS</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">div</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">div&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">
									di-s mr8-s
								</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									JavaScript
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">div</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<div className="toolbar">
								<div className="toolbar-item">
									<span>HTML</span>
								</div>
							</div>
						</pre>
						<div className="container-demo">
							<div className="di-s mr8-s">HTML</div>
							<div className="di-s mr8-s">CSS</div>
							<div className="di-s mr8-s">JavaScript</div>
						</div>
						<p className="smaller ml8-s">
							Nota: incluye margen a la derecha para visualizar
							correctamente.
						</p>
						<h3>display: db (block)</h3>
						<p className="main-text">
							Un elemento a nivel de bloque siempre comienza en
							una nueva línea y ocupa todo el ancho disponible (se
							extiende hacia la izquierda y hacia la derecha tanto
							como puede).
							<br />
							Ejemplo a nivel de bloque:
						</p>
						<pre className="language-html code-toolbar">
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">div&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">db-s</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									A display property with a value of "block"
									results in
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">div</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">div&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">db-s</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									a line break between the two elements.
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">div</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<div className="toolbar">
								<div className="toolbar-item">
									<span>HTML</span>
								</div>
							</div>
						</pre>
						<div className="container-demo">
							<span className="db-s">
								A display property with a value of "block"
								results in
							</span>
							<span className="db-s">
								a line break between the two elements.
							</span>
						</div>
						<h3>Más opciones</h3>
						<ul>
							<li> dc: (display: contents)</li>
							<li> df: (display: flex)</li>
							<li> dfr: (display: flow-root)</li>
							<li> dg: (display: display: grid)</li>
							<li> dib: (display: inline-block)</li>
							<li> dif: (display: inline-flex)</li>
							<li> dit: (display: inline-table)</li>
							<li> dli: (display: list-item)</li>
							<li> dn: (display: none)</li>
							<li> dh: (visibility: hidden)</li>
						</ul>
					</div>
				</section>
			</main>
		</>
	);
};

export default Display;
