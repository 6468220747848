import React from "react";
import Aside from "../Aside";

const Margin = () => {
	return (
		<>
			<main className="main-content g-container gtc4-s gcg1-s">
				<Aside />
				<section className="main-section gcss3-s">
					<div className="l-block-2"></div>
					<h2>Márgenes</h2>
					<div className="main-block">
						<p className="main-text">
							La propiedad <code>margin</code> se utiliza para
							definir el espacio alrededor de los elementos fuera
							de los bordes definidos.
						</p>
						<p>
							Con esta propiedad tenemos un control total sobre
							los márgenes de forma global, a través del uso de
							propiedades que se refieren a la propiedad{" "}
							<code>margin</code> como sería el caso de aquellas
							clases definidas con la nomenclaruta{" "}
							<code>m0-###</code>, o podemos trabajar con clases
							específicas para cada uno de los cuatro lados que
							tiene un elemento:
							<br />
							<ul>
								<li>
									superior (top), siempre comienzan por{" "}
									<code>mt</code> correspondiente a{" "}
									<code>margin-top</code>.
								</li>
								<li>
									derecha (right), siempre comienzan por{" "}
									<code>mr</code> correspondiente a{" "}
									<code>margin-right</code>.
								</li>
								<li>
									inferior (bottom), siempre comienzan por{" "}
									<code>mb</code> correspondiente a{" "}
									<code>margin-bottom</code>.
								</li>
								<li>
									izquierda (left), siempre comienzan por{" "}
									<code>ml</code> correspondiente a{" "}
									<code>margin-left</code>.
								</li>
							</ul>
						</p>
						<p>
							También hemos dispuesto clases que nos permiten
							aplicar márgenes de forma pareada como las
							siguientes:
							<br />
							<ul>
								<li>
									<code>mtb</code> para definir espacios de{" "}
									<code>margin-top</code> y{" "}
									<code>margin-bottom</code>.
								</li>
								<li>
									<code>mlr</code> para definir espacios de{" "}
									<code>margin-left</code> y{" "}
									<code>margin-right</code>.
								</li>
							</ul>
						</p>
						<p>
							Por cada una de las nomenclaturas mencionadas
							encontramos nombres de clases desde
							<code>m#0</code> hasta <code>m#100</code> (donde #
							equivale a <i>t</i>, <i>r</i>,<i>b</i>, <i>l</i>,{" "}
							<i>tb</i> o <i>lr</i>). El valor de las clases se
							encuentra expresado en unidades <code>rem</code>{" "}
							para un mejor ajuste en dispositivos móviles, y por
							cada unidad que incrementamos en el nombre estamos
							incrementando 1px el espacio que se aplica.
						</p>
						<p>
							Además de las clases ya indicadas, contamos con dos
							clases especiales para que el navegador pueda
							calcular el margen a aplicar:
							<br />
							<ul>
								<li>
									<code>ma-s</code> define{" "}
									<code>margin: auto</code> desde el tamaño
									indicado
								</li>
								{/* <li>
									<code>ma-xl</code> define{" "}
									<code>margin: auto</code> hasta el tamaño
									indicado
								</li> */}
							</ul>
						</p>
						<pre className="language-html code-toolbar">
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">div&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">m20-s</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									margin: m20-s
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">div</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">div&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">mt30-s</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									margin-top: mt30-s
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">div</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">div&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">mr20-s</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									margin-right: mt20-s
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">div</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">div&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">mb40-s</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									margin-bottom: mb40-s
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">div</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">div&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">ml40-s</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									margin-left: ml40-s
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">div</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">div&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">ma-s</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									margin (auto): ma-s
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">div</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<div className="toolbar">
								<div className="toolbar-item">
									<span>HTML</span>
								</div>
							</div>
						</pre>
						<div className="df-s fw-s">
							<div className="background-demo-blue m10-s">
								<p className="m10-s">margin: m10-s</p>
							</div>
							<div className="background-demo-blue m10-s">
								<p className="mt30-s">margin-top: mt30-s</p>
							</div>
							<div className="background-demo-blue m10-s">
								<p className="mr20-s text-left-s">
									margin-right: mr20-s
								</p>
							</div>
							<div className="background-demo-blue m10-s">
								<p className="mb40-s">margin-bottom: mb40-s</p>
							</div>
							<div className="background-demo-blue m10-s">
								<p className="ml40-s">margin-left: ml40-s</p>
							</div>
							<div className="background-demo-blue m10-s">
								<p className="center-vertical center-cross ma-s">
									margin (auto): ma-s
								</p>
							</div>
						</div>
					</div>
				</section>
			</main>
		</>
	);
};

export default Margin;