import React from "react";
import Aside from "../Aside";

const Padding = () => {
	return (
		<>
			<main className="main-content g-container gtc4-s gcg1-s">
				<Aside />
				<section className="main-section gcss3-s">
					<div className="l-block-2"></div>
					<h2>Padding</h2>
					<div className="main-block">
						<p className="main-text">
							La propiedad <code>padding</code> se utiliza para
							definir el espacio alrededor de los elementos dentro
							de los bordes definidos.
						</p>
						<p>
							Con esta propiedad tenemos un control total sobre
							los márgenes de forma global, a través del uso de
							propiedades que se refieren a la propiedad{" "}
							<code>padding</code> como sería el caso de aquellas
							clases definidas con la nomenclaruta{" "}
							<code>p0-###</code>, o podemos trabajar con clases
							específicas para cada uno de los cuatro lados que
							tiene un elemento:
							<br />
							<ul>
								<li>
									superior (top), siempre comienzan por{" "}
									<code>pt</code> correspondiente a{" "}
									<code>padding-top</code>.
								</li>
								<li>
									derecha (right), siempre comienzan por{" "}
									<code>pr</code> correspondiente a{" "}
									<code>padding-right</code>.
								</li>
								<li>
									inferior (bottom), siempre comienzan por{" "}
									<code>pb</code> correspondiente a{" "}
									<code>padding-bottom</code>.
								</li>
								<li>
									izquierda (left), siempre comienzan por{" "}
									<code>pl</code> correspondiente a{" "}
									<code>padding-left</code>.
								</li>
							</ul>
						</p>
						<p>
							También hemos dispuesto clases que nos permiten
							aplicar márgenes de forma pareada como las
							siguientes:
							<br />
							<ul>
								<li>
									<code>ptb</code> para definir espacios de{" "}
									<code>padding-top</code> y{" "}
									<code>padding-bottom</code>.
								</li>
								<li>
									<code>plr</code> para definir espacios de{" "}
									<code>padding-left</code> y{" "}
									<code>padding-right</code>.
								</li>
							</ul>
						</p>
						<p>
							Por cada una de las nomenclaturas mencionadas
							encontramos nombres de clases desde
							<code>m#0</code> hasta <code>m#100</code> (donde #
							equivale a <i>t</i>, <i>r</i>,<i>b</i>, <i>l</i>,{" "}
							<i>tb</i> o <i>lr</i>). El valor de las clases se
							encuentra expresado en unidades <code>rem</code>{" "}
							para un mejor ajuste en dispositivos móviles, y por
							cada unidad que incrementamos en el nombre estamos
							incrementando 1px el espacio que se aplica.
						</p>
						<p>
							Además de las clases ya indicadas, contamos con dos
							clases especiales para que el navegador pueda
							calcular el margen a aplicar:
							<br />
							<ul>
								<li>
									<code>pa-s</code> define{" "}
									<code>padding: auto</code> desde el tamaño
									indicado
								</li>
								{/* <li>
									<code>pa-t</code> define{" "}
									<code>padding: auto</code> hasta el tamaño
									indicado
								</li> */}
							</ul>
						</p>
						<pre className="language-html code-toolbar">
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">div&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">p20-s</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									padding: p20-s
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">div</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">div&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">pt30-s</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									padding-top: pt30-s
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">div</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">div&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">pr20-s</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									padding-right: mt20-s
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">div</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">div&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">pb40-s</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									padding-bottom: pb40-s
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">div</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">div&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">pl40-s</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									padding-left: pl40-s
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">div</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">div&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">pa-s</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									padding (auto): pa-s
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">div</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<div className="toolbar">
								<div className="toolbar-item">
									<span>HTML</span>
								</div>
							</div>
						</pre>
						<div className="df-s fw-s">
							<div className="background-demo-blue m10-s">
								<p className="p10-s">padding: p10-s</p>
							</div>
							<div className="background-demo-blue m10-s">
								<p className="pt30-s">padding-top: pt30-s</p>
							</div>
							<div className="background-demo-blue m10-s">
								<p className="pr20-s text-left-s">
									padding-right: pr20-s
								</p>
							</div>
							<div className="background-demo-blue m10-s">
								<p className="pb40-s">padding-bottom: pb40-s</p>
							</div>
							<div className="background-demo-blue m10-s">
								<p className="pl40-s">padding-left: pl40-s</p>
							</div>
							<div className="background-demo-blue m10-s">
								<p className="center-vertical center-cross pa-s">
									padding (auto): pa-s
								</p>
							</div>
						</div>
					</div>
				</section>
			</main>
		</>
	);
};

export default Padding;
