import React from "react";
import Aside from "../Aside";
import { NavLink } from "react-router-dom";
const activeStyle = { color: "var(--text-list-color-hover)" };

const Alignment = () => {
	return (
		<>
			<main className="main-content g-container gtc4-s gcg1-s">
				<Aside />
				<section className="main-section gcss3-s">
					<div className="l-block-2"></div>
					<div className="main-block">
						<h2>Alineación</h2>
						<p className="main-text">
							Para alinear diferente contenido hay que tener en
							cuenta el display que tiene el objeto a alinear.
							Podemos tener centrado de cualquier tipo de objetos
							con display <code>flex</code> o <code>grid</code>.
							Para el resto disponemos de alguna clase de
							centrado, principalmente para texto. Las clases son
							una combinacion de las iniciales de propiedad-valor
							 mas el tamaño de ruptura.
							Quedando la combinacion de la clase de la siguiente
							forma para <code>jcfs-lg</code>. Se pueden combinar
							varias clases para utilizar una alineación para
							móvil y otra para desktop, por ejemplo{" "}
							<code>jcfc-s jcfs-lg</code>. Consultar los tamaños
							de pantalla en{" "}
							<NavLink to="/mediaquery" activeStyle={activeStyle}>
								Media Query
							</NavLink>
						</p>
						<h3>1. Alineación horizontal con Flex</h3>
						<p className="main-text">
							Para <code>flex</code> existe una sola propiedad
							para alinear contenido horizontalmente. Siempre es{" "}
							<code>justify-content</code> y utiliza con los
							valores <code>flex-start</code>,{" "}
							<code>flex-end</code> y <code>center</code>. La
							propiedad
							<code>Justify-content:center</code> es compartido
							por <code>flex</code> y <code>grid</code>. Las 3
							clases que podemos utilizar empiezan por{" "}
							<code>jcfs</code>, <code>jcc</code> y{" "}
							<code>jcfe</code>.
						</p>
						<pre className="language-html code-toolbar">
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">p&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">jcfs-s</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									justify-content:flex-start
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">p</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">p&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">jcc-s</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									justify-content:center
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">p</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">p&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">jcfe-s</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									justify-content:flex-end
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">p</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<div className="toolbar">
								<div className="toolbar-item">
									<span>HTML</span>
								</div>
							</div>
						</pre>
						<div className="f-container-demo jcsb-s">
							<div className="background-demo-blue df-s jcfs-s aic-s">
								justify-content:flex-start
							</div>
							<div className="background-demo-blue df-s jcc-s aic-s">
								justify-content:center
							</div>
							<div className="background-demo-blue df-s jcfe-s aic-s">
								justify-content:flex-end
							</div>
						</div>
						<div className="l-block-2"></div>
						<h3>2. Alineación horizontal con Grid</h3>
						<p className="main-text">
							Para <code>grid</code> es posible alinear contenido
							horizontalmente con tres propiedades. La primera es
							con <code>justify-content</code> como en flex y
							funciona exactamente igual. <code>flex-item</code>
						</p>
						<pre className="language-html code-toolbar">
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">p&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">jcs-s</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									justify-content:start
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">p</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">p&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">jcc-s</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									justify-content:center
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">p</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">p&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">jce-s</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									justify-content:end
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">p</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<div className="toolbar">
								<div className="toolbar-item">
									<span>HTML</span>
								</div>
							</div>
						</pre>
						<div className="f-container-demo jcsb-s">
							<div className="background-demo-blue dg-s jcs-s aic-s">
								justify-content:start
							</div>
							<div className="background-demo-blue dg-s jcc-s aic-s">
								justify-content:center
							</div>
							<div className="background-demo-blue dg-s jce-s aic-s">
								justify-content:end
							</div>
						</div>
						<p className="main-text">
							La segunda es con <code>justify-items</code> y se
							aplica al contenedor con varios items y se aplica a
							todos por igual.
						</p>
						<pre className="language-html code-toolbar">
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">p&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">jis-s</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									justify-items:start
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">p</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">p&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">jic-s</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									justify-items:center
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">p</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">p&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">jie-s</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									justify-items:end
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">p</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<div className="toolbar">
								<div className="toolbar-item">
									<span>HTML</span>
								</div>
							</div>
						</pre>
						<div className="f-container-demo jcsb-s">
							<div className="background-demo-blue dg-s jis-s aic-s">
								justify-items:start
							</div>
							<div className="background-demo-blue dg-s jic-s aic-s">
								justify-items:center
							</div>
							<div className="background-demo-blue dg-s jie-s aic-s">
								justify-items:end
							</div>
						</div>
						<p className="main-text">
							La tercera es con <code>justify-self</code> y se
							aplica al contenedor hijo de un contenedor grid
							padre.
						</p>
						<pre className="language-html code-toolbar">
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">div&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">
									dg-s aic-s
								</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									<code className="language-html">
										<span className="token punctuation">
											&nbsp;&lt;
										</span>
										<span className="token tag">
											div&nbsp;
										</span>
										<span className="token attr-name">
											class
										</span>
										<span className="token punctuation">
											="
										</span>
										<span className="token attr-value">
											jss-s
										</span>
										<span className="token punctuation">
											"
										</span>
										<span className="token punctuation">
											&gt;
										</span>
										<span className="token html-value">
											justify-self:start
										</span>
										<span className="token punctuation">
											&lt;/
										</span>
										<span className="token tag">div</span>
										<span className="token punctuation">
											&gt;
										</span>
									</code>
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">div</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">div&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">
									dg-s aic-s
								</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									<code className="language-html">
										<span className="token punctuation">
											&nbsp;&lt;
										</span>
										<span className="token tag">
											div&nbsp;
										</span>
										<span className="token attr-name">
											class
										</span>
										<span className="token punctuation">
											="
										</span>
										<span className="token attr-value">
											jsc-s
										</span>
										<span className="token punctuation">
											"
										</span>
										<span className="token punctuation">
											&gt;
										</span>
										<span className="token html-value">
											justify-self:center
										</span>
										<span className="token punctuation">
											&lt;/
										</span>
										<span className="token tag">div</span>
										<span className="token punctuation">
											&gt;
										</span>
									</code>
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">div</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">div&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">
									dg-s aic-s
								</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									<code className="language-html">
										<span className="token punctuation">
											&nbsp;&lt;
										</span>
										<span className="token tag">
											div&nbsp;
										</span>
										<span className="token attr-name">
											class
										</span>
										<span className="token punctuation">
											="
										</span>
										<span className="token attr-value">
											jse-s
										</span>
										<span className="token punctuation">
											"
										</span>
										<span className="token punctuation">
											&gt;
										</span>
										<span className="token html-value">
											justify-self:end
										</span>
										<span className="token punctuation">
											&lt;/
										</span>
										<span className="token tag">div</span>
										<span className="token punctuation">
											&gt;
										</span>
									</code>
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">div</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<div className="toolbar">
								<div className="toolbar-item">
									<span>HTML</span>
								</div>
							</div>
						</pre>
						<div className="f-container-demo jcsb-s">
							<div className="background-demo-blue dg-s aic-s">
								<div className="jss-s">justify-self:start</div>
							</div>
							<div className="background-demo-blue dg-s aic-s">
								<div className="jsc-s">justify-self:center</div>
							</div>
							<div className="background-demo-blue dg-s aic-s">
								<div className="jse-s">justify-self:end</div>
							</div>
						</div>
						<div className="l-block-2"></div>
						<h3>3. Distribución horizontal para Grid y Flex</h3>
						<p className="main-text">
							Existen tres clases para distribuir horizontalmente
							las cajas hijas de una caja padre grid o flex. Las
							tres son <code>jcsb</code>, <code>jcse</code>,{" "}
							<code>jcsa</code>. Las clases se aplican al padre
							para que distribuya el espacio de los hijos.
						</p>
						<h4>justify-content: space-between</h4>
						<p className="main-text">
							La clase <code>jcsb</code> sirve para repartir todo
							el espacio entre las zonas intermedias pegando las
							cajas laterales a los extremos.
						</p>
						<pre className="language-html code-toolbar">
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">div&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">jcsb-s</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									<code className="language-html">
										<span className="token punctuation">
											&nbsp;&lt;
										</span>
										<span className="token tag">
											div&nbsp;
										</span>
										<span className="token attr-name">
											class
										</span>
										<span className="token punctuation">
											="
										</span>
										<span className="token attr-value">
											df-s jcc-s aic-s
										</span>
										<span className="token punctuation">
											"
										</span>
										<span className="token punctuation">
											&gt;
										</span>
										<span className="token html-value">
											space-between
										</span>
										<span className="token punctuation">
											&lt;/
										</span>
										<span className="token tag">div</span>
										<span className="token punctuation">
											&gt;
										</span>
									</code>
									<code className="language-html">
										<span className="token punctuation">
											&nbsp;&lt;
										</span>
										<span className="token tag">
											div&nbsp;
										</span>
										<span className="token attr-name">
											class
										</span>
										<span className="token punctuation">
											="
										</span>
										<span className="token attr-value">
											df-s jcc-s aic-s
										</span>
										<span className="token punctuation">
											"
										</span>
										<span className="token punctuation">
											&gt;
										</span>
										<span className="token html-value">
											space-between
										</span>
										<span className="token punctuation">
											&lt;/
										</span>
										<span className="token tag">div</span>
										<span className="token punctuation">
											&gt;
										</span>
									</code>
									<code className="language-html">
										<span className="token punctuation">
											&nbsp;&lt;
										</span>
										<span className="token tag">
											div&nbsp;
										</span>
										<span className="token attr-name">
											class
										</span>
										<span className="token punctuation">
											="
										</span>
										<span className="token attr-value">
											df-s jcc-s aic-s
										</span>
										<span className="token punctuation">
											"
										</span>
										<span className="token punctuation">
											&gt;
										</span>
										<span className="token html-value">
											space-between
										</span>
										<span className="token punctuation">
											&lt;/
										</span>
										<span className="token tag">div</span>
										<span className="token punctuation">
											&gt;
										</span>
									</code>
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">div</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<div className="toolbar">
								<div className="toolbar-item">
									<span>HTML</span>
								</div>
							</div>
						</pre>
						<div className="f-container-demo jcsb-s">
							<div className="background-demo-blue background-demo-w20 df-s jcc-s aic-s">
								space-between
							</div>
							<div className="background-demo-blue background-demo-w20 df-s jcc-s aic-s">
								space-between
							</div>
							<div className="background-demo-blue background-demo-w20 df-s jcc-s aic-s">
								space-between
							</div>
						</div>
						<h4>justify-content: space-around</h4>
						<p className="main-text">
							Los items se alinean uniformemente con la clase{" "}
							<code>jcse</code> de tal manera que el espacio entre
							dos items adyacentes es el mismo. El espacio vacío
							anterior al primer item y posterior al último item
							equivale a la mitad del espacio entre dos items
							adyacentes
						</p>
						<pre className="language-html code-toolbar">
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">div&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">jcse-s</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									<code className="language-html">
										<span className="token punctuation">
											&nbsp;&lt;
										</span>
										<span className="token tag">
											div&nbsp;
										</span>
										<span className="token attr-name">
											class
										</span>
										<span className="token punctuation">
											="
										</span>
										<span className="token attr-value">
											df-s jcc-s aic-s
										</span>
										<span className="token punctuation">
											"
										</span>
										<span className="token punctuation">
											&gt;
										</span>
										<span className="token html-value">
											space-around
										</span>
										<span className="token punctuation">
											&lt;/
										</span>
										<span className="token tag">div</span>
										<span className="token punctuation">
											&gt;
										</span>
									</code>
									<code className="language-html">
										<span className="token punctuation">
											&nbsp;&lt;
										</span>
										<span className="token tag">
											div&nbsp;
										</span>
										<span className="token attr-name">
											class
										</span>
										<span className="token punctuation">
											="
										</span>
										<span className="token attr-value">
											df-s jcc-s aic-s
										</span>
										<span className="token punctuation">
											"
										</span>
										<span className="token punctuation">
											&gt;
										</span>
										<span className="token html-value">
											space-around
										</span>
										<span className="token punctuation">
											&lt;/
										</span>
										<span className="token tag">div</span>
										<span className="token punctuation">
											&gt;
										</span>
									</code>
									<code className="language-html">
										<span className="token punctuation">
											&nbsp;&lt;
										</span>
										<span className="token tag">
											div&nbsp;
										</span>
										<span className="token attr-name">
											class
										</span>
										<span className="token punctuation">
											="
										</span>
										<span className="token attr-value">
											df-s jcc-s aic-s
										</span>
										<span className="token punctuation">
											"
										</span>
										<span className="token punctuation">
											&gt;
										</span>
										<span className="token html-value">
											space-around
										</span>
										<span className="token punctuation">
											&lt;/
										</span>
										<span className="token tag">div</span>
										<span className="token punctuation">
											&gt;
										</span>
									</code>
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">div</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<div className="toolbar">
								<div className="toolbar-item">
									<span>HTML</span>
								</div>
							</div>
						</pre>
						<div className="f-container-demo jcsa-s">
							<div className="background-demo-blue background-demo-w20 df-s jcc-s aic-s">
								space-around
							</div>
							<div className="background-demo-blue background-demo-w20 df-s jcc-s aic-s">
								space-around
							</div>
							<div className="background-demo-blue background-demo-w20 df-s jcc-s aic-s">
								space-around
							</div>
						</div>
						<h4>justify-content: space-evenly</h4>
						<p className="main-text">
							Con la clase <code>jcsa</code> los elementos se
							distribuyen de modo que el espacio entre dos sujetos
							de alineación adyacentes, antes del primer sujeto de
							alineación y después del último sujeto de alineación
							sea el mismo
						</p>
						<pre className="language-html code-toolbar">
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">div&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">jcsa-s</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									<code className="language-html">
										<span className="token punctuation">
											&nbsp;&lt;
										</span>
										<span className="token tag">
											div&nbsp;
										</span>
										<span className="token attr-name">
											class
										</span>
										<span className="token punctuation">
											="
										</span>
										<span className="token attr-value">
											df-s jcc-s aic-s
										</span>
										<span className="token punctuation">
											"
										</span>
										<span className="token punctuation">
											&gt;
										</span>
										<span className="token html-value">
											space-evenly
										</span>
										<span className="token punctuation">
											&lt;/
										</span>
										<span className="token tag">div</span>
										<span className="token punctuation">
											&gt;
										</span>
									</code>
									<code className="language-html">
										<span className="token punctuation">
											&nbsp;&lt;
										</span>
										<span className="token tag">
											div&nbsp;
										</span>
										<span className="token attr-name">
											class
										</span>
										<span className="token punctuation">
											="
										</span>
										<span className="token attr-value">
											df-s jcc-s aic-s
										</span>
										<span className="token punctuation">
											"
										</span>
										<span className="token punctuation">
											&gt;
										</span>
										<span className="token html-value">
											space-evenly
										</span>
										<span className="token punctuation">
											&lt;/
										</span>
										<span className="token tag">div</span>
										<span className="token punctuation">
											&gt;
										</span>
									</code>
									<code className="language-html">
										<span className="token punctuation">
											&nbsp;&lt;
										</span>
										<span className="token tag">
											div&nbsp;
										</span>
										<span className="token attr-name">
											class
										</span>
										<span className="token punctuation">
											="
										</span>
										<span className="token attr-value">
											df-s jcc-s aic-s
										</span>
										<span className="token punctuation">
											"
										</span>
										<span className="token punctuation">
											&gt;
										</span>
										<span className="token html-value">
											space-evenly
										</span>
										<span className="token punctuation">
											&lt;/
										</span>
										<span className="token tag">div</span>
										<span className="token punctuation">
											&gt;
										</span>
									</code>
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">div</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<div className="toolbar">
								<div className="toolbar-item">
									<span>HTML</span>
								</div>
							</div>
						</pre>
						<div className="f-container-demo jcse-s">
							<div className="background-demo-blue background-demo-w20 df-s jcc-s aic-s">
								space-evenly
							</div>
							<div className="background-demo-blue background-demo-w20 df-s jcc-s aic-s">
								space-evenly
							</div>
							<div className="background-demo-blue background-demo-w20 df-s jcc-s aic-s">
								space-evenly
							</div>
						</div>
						<div className="l-block-2"></div>
						<h3>4. Alineación sin Grid ni Flex</h3>
						<p className="main-text">
							Para el centrado de diferentes items sin{" "}
							<code>grid</code> y <code>flex</code> tenemos varias
							clases. Las clases son <code>center-cross</code>,{" "}
							<code>center-vertical</code>,{" "}
							<code>center-full</code>. Ademas para alinear a
							ambos lados y al centro tenemos las clases{" "}
							<code>text-center</code>, <code>text-right</code>,{" "}
							<code>text-left</code>
						</p>
						<pre className="language-html code-toolbar">
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">div&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">
									center-cross
								</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									Center horizontal
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">div</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<div className="toolbar">
								<div className="toolbar-item">
									<span>HTML</span>
								</div>
							</div>
						</pre>
						<div className="container-demo h10vh-s">
							<div className="center-cross">
								Center horizontal
							</div>
						</div>
						<pre className="language-html code-toolbar">
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">div&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">
									center-full
								</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									Center absoluto
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">div</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<div className="toolbar">
								<div className="toolbar-item">
									<span>HTML</span>
								</div>
							</div>
						</pre>
						<div className="container-demo h10vh-s">
							<div className="center-full">Center absoluto</div>
						</div>
						<pre className="language-html code-toolbar">
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">div&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">
									center-vertical
								</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									Center vertical
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">div</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<div className="toolbar">
								<div className="toolbar-item">
									<span>HTML</span>
								</div>
							</div>
						</pre>
						<div className="container-demo h10vh-s">
							<div className="center-vertical">
								Center vertical
							</div>
						</div>
						<pre className="language-html code-toolbar">
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">div&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">
									text-right-s
								</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									Text aling start
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">div</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<div className="toolbar">
								<div className="toolbar-item">
									<span>HTML</span>
								</div>
							</div>
						</pre>
						<div className="container-demo h10vh-s">
							<div className="text-right-s center-vertical w100">
								Text aling start
							</div>
						</div>
						<pre className="language-html code-toolbar">
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">div&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">
									text-center-s
								</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									Text aling center
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">div</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<div className="toolbar">
								<div className="toolbar-item">
									<span>HTML</span>
								</div>
							</div>
						</pre>
						<div className="container-demo h10vh-s">
							<div className="text-center-s center-vertical w100">
								Text aling center
							</div>
						</div>
						<pre className="language-html code-toolbar">
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">div&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">
									text-left-s
								</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									Text aling end
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">div</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<div className="toolbar">
								<div className="toolbar-item">
									<span>HTML</span>
								</div>
							</div>
						</pre>
						<div className="container-demo h10vh-s">
							<div className="text-left-s center-vertical w100">
								Text aling end
							</div>
						</div>
						<div className="l-block-2"></div>
						<h3>5. Alineación vertical con flex y grid</h3>
						<p className="main-text">
							Para alinear verticalmente en <code>flex</code> y{" "}
							<code>grid</code> se utilizan las mismas
							propiedades. La única diferencia es que el valor de
							las propiedades de flex van precedidas de un{" "}
							<code>flex</code>. Las propiedades que se pueden
							utilizar son <code>align-content</code>,{" "}
							<code>align-items</code> y <code>align-self</code>
						</p>
						<h4>Display flex</h4>
						<p className="main-text">
							Para alinear verticalmente en <code>flex</code> hay
							que revisar la altura de la caja hija. Por defecto
							cuando aplicas un <code>display:flex</code> a una
							caja padre siempre el hijo se ajusta a la altura del
							padre. Cuando se usa <code>align-content</code> la
							solución para que la clase funcione es combinar la
							clase <code>align-content</code> con{" "}
							<code>flex-wrap: wrap</code>.
						</p>
						<pre className="language-html code-toolbar">
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">div&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">
									f-container-demo jcsb-s acc-s
								</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									<code className="language-html">
										<span className="token punctuation">
											&nbsp;&lt;
										</span>
										<span className="token tag">
											div&nbsp;
										</span>
										<span className="token attr-name">
											class
										</span>
										<span className="token punctuation">
											="
										</span>
										<span className="token attr-value">
											df-s jcc-s fw-s acfs-s
										</span>
										<span className="token punctuation">
											"
										</span>
										<span className="token punctuation">
											&gt;
										</span>
										<span className="token html-value">
											align-content:flex-start
										</span>
										<span className="token punctuation">
											&lt;/
										</span>
										<span className="token tag">div</span>
										<span className="token punctuation">
											&gt;
										</span>
									</code>
									<code className="language-html">
										<span className="token punctuation">
											&nbsp;&lt;
										</span>
										<span className="token tag">
											div&nbsp;
										</span>
										<span className="token attr-name">
											class
										</span>
										<span className="token punctuation">
											="
										</span>
										<span className="token attr-value">
											df-s jcc-s fw-s acc-s
										</span>
										<span className="token punctuation">
											"
										</span>
										<span className="token punctuation">
											&gt;
										</span>
										<span className="token html-value">
											align-content:center
										</span>
										<span className="token punctuation">
											&lt;/
										</span>
										<span className="token tag">div</span>
										<span className="token punctuation">
											&gt;
										</span>
									</code>
									<code className="language-html">
										<span className="token punctuation">
											&nbsp;&lt;
										</span>
										<span className="token tag">
											div&nbsp;
										</span>
										<span className="token attr-name">
											class
										</span>
										<span className="token punctuation">
											="
										</span>
										<span className="token attr-value">
											df-s jcc-s fw-s acfe-s
										</span>
										<span className="token punctuation">
											"
										</span>
										<span className="token punctuation">
											&gt;
										</span>
										<span className="token html-value">
											align-content:flex-end
										</span>
										<span className="token punctuation">
											&lt;/
										</span>
										<span className="token tag">div</span>
										<span className="token punctuation">
											&gt;
										</span>
									</code>
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">div</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<div className="toolbar">
								<div className="toolbar-item">
									<span>HTML</span>
								</div>
							</div>
						</pre>
						<div className="f-container-demo jcsb-s acc-s">
							<div className="background-demo-blue df-s jcc-s fw-s acfs-s">
								align-content:flex-start
							</div>
							<div className="background-demo-blue df-s jcc-s fw-s acc-s">
								align-content:center
							</div>
							<div className="background-demo-blue df-s jcc-s fw-s acfe-s">
								align-content:flex-end
							</div>
						</div>
						<p className="main-text">
							Si no se puede usar la clase{" "}
							<code>flex-content</code> junto a{" "}
							<code>flex-wrap: wrap</code> la opcion seria usar la
							clase para usar <code>align-items</code>.
						</p>
						<pre className="language-html code-toolbar">
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">div&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">
									f-container-demo jcsb-s acc-s
								</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									<code className="language-html">
										<span className="token punctuation">
											&nbsp;&lt;
										</span>
										<span className="token tag">
											div&nbsp;
										</span>
										<span className="token attr-name">
											class
										</span>
										<span className="token punctuation">
											="
										</span>
										<span className="token attr-value">
											df-s jcc-s aifs-s
										</span>
										<span className="token punctuation">
											"
										</span>
										<span className="token punctuation">
											&gt;
										</span>
										<span className="token html-value">
											align-items:flex-start
										</span>
										<span className="token punctuation">
											&lt;/
										</span>
										<span className="token tag">div</span>
										<span className="token punctuation">
											&gt;
										</span>
									</code>
									<code className="language-html">
										<span className="token punctuation">
											&nbsp;&lt;
										</span>
										<span className="token tag">
											div&nbsp;
										</span>
										<span className="token attr-name">
											class
										</span>
										<span className="token punctuation">
											="
										</span>
										<span className="token attr-value">
											df-s jcc-s aic-s
										</span>
										<span className="token punctuation">
											"
										</span>
										<span className="token punctuation">
											&gt;
										</span>
										<span className="token html-value">
											align-items:center
										</span>
										<span className="token punctuation">
											&lt;/
										</span>
										<span className="token tag">div</span>
										<span className="token punctuation">
											&gt;
										</span>
									</code>
									<code className="language-html">
										<span className="token punctuation">
											&nbsp;&lt;
										</span>
										<span className="token tag">
											div&nbsp;
										</span>
										<span className="token attr-name">
											class
										</span>
										<span className="token punctuation">
											="
										</span>
										<span className="token attr-value">
											jcc-s aife-s
										</span>
										<span className="token punctuation">
											"
										</span>
										<span className="token punctuation">
											&gt;
										</span>
										<span className="token html-value">
											align-items:flex-end
										</span>
										<span className="token punctuation">
											&lt;/
										</span>
										<span className="token tag">div</span>
										<span className="token punctuation">
											&gt;
										</span>
									</code>
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">div</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<div className="toolbar">
								<div className="toolbar-item">
									<span>HTML</span>
								</div>
							</div>
						</pre>
						<div className="f-container-demo jcsb-s acc-s">
							<div className="background-demo-blue df-s jcc-s aifs-s">
								align-items:flex-start
							</div>
							<div className="background-demo-blue df-s jcc-s aic-s">
								align-items:center
							</div>
							<div className="background-demo-blue df-s jcc-s aife-s">
								align-items:flex-end
							</div>
						</div>
						<p className="main-text">
							Para alinear verticalmente con{" "}
							<code>align-self</code> hay que utilizar un item
							hijo donde se aplicar la clase y este item hijo va
							dentro de un padre que sea <code>display:flex</code>
							.
						</p>
						<pre className="language-html code-toolbar">
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">div&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">
									f-container-demo jcsb-s acc-s
								</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									<code className="language-html">
										<span className="token punctuation">
											&nbsp;&lt;
										</span>
										<span className="token tag">
											div&nbsp;
										</span>
										<span className="token attr-name">
											class
										</span>
										<span className="token punctuation">
											="
										</span>
										<span className="token attr-value">
											df-s jcc-s
										</span>
										<span className="token punctuation">
											"
										</span>
										<span className="token punctuation">
											&gt;
										</span>
										<span className="token html-value">
											<code className="language-html">
												<span className="token punctuation">
													&nbsp;&nbsp;&lt;
												</span>
												<span className="token tag">
													div&nbsp;
												</span>
												<span className="token attr-name">
													class
												</span>
												<span className="token punctuation">
													="
												</span>
												<span className="token attr-value">
													asfs-s
												</span>
												<span className="token punctuation">
													"
												</span>
												<span className="token punctuation">
													&gt;
												</span>
												<span className="token html-value">
													align-self:flex-start
												</span>
												<span className="token punctuation">
													&lt;/
												</span>
												<span className="token tag">
													div
												</span>
												<span className="token punctuation">
													&gt;
												</span>
											</code>
										</span>
										<span className="token punctuation">
											&nbsp;&lt;/
										</span>
										<span className="token tag">div</span>
										<span className="token punctuation">
											&gt;
										</span>
									</code>
									<code className="language-html">
										<span className="token punctuation">
											&nbsp;&lt;
										</span>
										<span className="token tag">
											div&nbsp;
										</span>
										<span className="token attr-name">
											class
										</span>
										<span className="token punctuation">
											="
										</span>
										<span className="token attr-value">
											df-s jcc-s
										</span>
										<span className="token punctuation">
											"
										</span>
										<span className="token punctuation">
											&gt;
										</span>
										<span className="token html-value">
											<code className="language-html">
												<span className="token punctuation">
													&nbsp;&nbsp;&lt;
												</span>
												<span className="token tag">
													div&nbsp;
												</span>
												<span className="token attr-name">
													class
												</span>
												<span className="token punctuation">
													="
												</span>
												<span className="token attr-value">
													asc-s
												</span>
												<span className="token punctuation">
													"
												</span>
												<span className="token punctuation">
													&gt;
												</span>
												<span className="token html-value">
													align-self:center
												</span>
												<span className="token punctuation">
													&lt;/
												</span>
												<span className="token tag">
													div
												</span>
												<span className="token punctuation">
													&gt;
												</span>
											</code>
										</span>
										<span className="token punctuation">
											&nbsp;&lt;/
										</span>
										<span className="token tag">div</span>
										<span className="token punctuation">
											&gt;
										</span>
									</code>{" "}
									<code className="language-html">
										<span className="token punctuation">
											&nbsp;&lt;
										</span>
										<span className="token tag">
											div&nbsp;
										</span>
										<span className="token attr-name">
											class
										</span>
										<span className="token punctuation">
											="
										</span>
										<span className="token attr-value">
											df-s jcc-s
										</span>
										<span className="token punctuation">
											"
										</span>
										<span className="token punctuation">
											&gt;
										</span>
										<span className="token html-value">
											<code className="language-html">
												<span className="token punctuation">
													&nbsp;&nbsp;&lt;
												</span>
												<span className="token tag">
													div&nbsp;
												</span>
												<span className="token attr-name">
													class
												</span>
												<span className="token punctuation">
													="
												</span>
												<span className="token attr-value">
													asfe-s
												</span>
												<span className="token punctuation">
													"
												</span>
												<span className="token punctuation">
													&gt;
												</span>
												<span className="token html-value">
													align-self:flex-end
												</span>
												<span className="token punctuation">
													&lt;/
												</span>
												<span className="token tag">
													div
												</span>
												<span className="token punctuation">
													&gt;
												</span>
											</code>
										</span>
										<span className="token punctuation">
											&nbsp;&lt;/
										</span>
										<span className="token tag">div</span>
										<span className="token punctuation">
											&gt;
										</span>
									</code>
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">div</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<div className="toolbar">
								<div className="toolbar-item">
									<span>HTML</span>
								</div>
							</div>
						</pre>
						<div className="f-container-demo jcsb-s acc-s">
							<div className="background-demo-blue df-s jcc-s">
								<div className="asfs-s">
									align-self:flex-start
								</div>
							</div>
							<div className="background-demo-blue df-s jcc-s">
								<div className="asc-s">align-self:center</div>
							</div>
							<div className="background-demo-blue df-s jcc-s">
								<div className="asfe-s">
									align-self:flex-end
								</div>
							</div>
						</div>
						<h4>Display grid</h4>
						<p className="main-text">
							Con display grid la alineación vertical solo tiene
							una peculiaridad con la clase que aplica{" "}
							<code>aling-self</code>. Hay que aplicar la clase a
							un item hijo de un padre que tenga la propiedad{" "}
							<code>display grid</code>. Las otras dos clases que
							aplican <code>aling-content</code> y{" "}
							<code>align-items</code> se usan en un item tenga la
							propiedad también <code>display: grid</code>.
						</p>
						<pre className="language-html code-toolbar">
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">div&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">
									dg-s gcss1-s acfs-s jcc-s
								</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									align-content:start
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">div</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">div&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">
									dg-s gcss1-s acc-s jcc-s
								</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									align-content:center
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">div</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">div&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">
									dg-s gcss1-s acfe-s jcc-s
								</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									align-content:end
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">div</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<div className="toolbar">
								<div className="toolbar-item">
									<span>HTML</span>
								</div>
							</div>
						</pre>
						<div className="f-container-demo jcsb-s">
							<div className="background-demo-blue dg-s gcss1-s acfs-s jcc-s">
								align-content:start
							</div>
							<div className=" background-demo-blue dg-s gcss1-s acc-s jcc-s">
								align-content:center
							</div>
							<div className="background-demo-blue dg-s gcss1-s acfe-s jcc-s">
								align-content:end
							</div>
						</div>
						<pre className="language-html code-toolbar">
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">div&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">
									dg-s gcss1-s aifs-s jcc-s
								</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									align-items:start
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">div</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">div&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">
									dg-s gcss1-s aic-s jcc-s
								</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									align-items:center
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">div</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">div&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">
									dg-s gcss1-s aife-s jcc-s
								</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									align-items:end
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">div</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<div className="toolbar">
								<div className="toolbar-item">
									<span>HTML</span>
								</div>
							</div>
						</pre>
						<div className="f-container-demo jcsb-s">
							<div className="background-demo-blue dg-s gcss1-s aifs-s jcc-s">
								align-items:start
							</div>
							<div className=" background-demo-blue dg-s gcss1-s aic-s jcc-s">
								align-items:center
							</div>
							<div className="background-demo-blue dg-s gcss1-s aife-s jcc-s">
								align-items:end
							</div>
						</div>
						<pre className="language-html code-toolbar">
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">div&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">
									dg-s gcss1-s jcc-s
								</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									<code className="language-html">
										<span className="token punctuation">
											&nbsp;&lt;
										</span>
										<span className="token tag">
											div&nbsp;
										</span>
										<span className="token attr-name">
											class
										</span>
										<span className="token punctuation">
											="
										</span>
										<span className="token attr-value">
											ass-s
										</span>
										<span className="token punctuation">
											"
										</span>
										<span className="token punctuation">
											&gt;
										</span>
										<span className="token html-value">
											align-self:start
										</span>
										<span className="token punctuation">
											&lt;/
										</span>
										<span className="token tag">div</span>
										<span className="token punctuation">
											&gt;
										</span>
									</code>
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">div</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">div&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">
									dg-s gcss1-s jcc-s
								</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									<code className="language-html">
										<span className="token punctuation">
											&nbsp;&lt;
										</span>
										<span className="token tag">
											div&nbsp;
										</span>
										<span className="token attr-name">
											class
										</span>
										<span className="token punctuation">
											="
										</span>
										<span className="token attr-value">
											asc-s
										</span>
										<span className="token punctuation">
											"
										</span>
										<span className="token punctuation">
											&gt;
										</span>
										<span className="token html-value">
											align-self:center
										</span>
										<span className="token punctuation">
											&lt;/
										</span>
										<span className="token tag">div</span>
										<span className="token punctuation">
											&gt;
										</span>
									</code>
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">div</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">div&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">
									dg-s gcss1-s jcc-s
								</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									<code className="language-html">
										<span className="token punctuation">
											&nbsp;&lt;
										</span>
										<span className="token tag">
											div&nbsp;
										</span>
										<span className="token attr-name">
											class
										</span>
										<span className="token punctuation">
											="
										</span>
										<span className="token attr-value">
											ase-s
										</span>
										<span className="token punctuation">
											"
										</span>
										<span className="token punctuation">
											&gt;
										</span>
										<span className="token html-value">
											align-self:end
										</span>
										<span className="token punctuation">
											&lt;/
										</span>
										<span className="token tag">div</span>
										<span className="token punctuation">
											&gt;
										</span>
									</code>
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">div</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<div className="toolbar">
								<div className="toolbar-item">
									<span>HTML</span>
								</div>
							</div>
						</pre>
						<div className="f-container-demo gc3-s jcsb-s">
							<div className="background-demo-blue dg-s gcss1-s jcc-s">
								<div className="ass-s">align-self:start</div>
							</div>
							<div className="background-demo-blue dg-s gcss1-s jcc-s">
								<div className="asc-s">align-self:center</div>
							</div>
							<div className="background-demo-blue dg-s gcss1-s ase-s jcc-s">
								<div className="ase-s">align-self:end</div>
							</div>
						</div>
					</div>
				</section>
			</main>
		</>
	);
};

export default Alignment;
