import React from "react";

const MainBanner = ({ title }) => {
	return (
		<>
			<div className="main-banner p48">
				<img
					className="img-banner tac-s"
					src="https://images.pexels.com/photos/3137076/pexels-photo-3137076.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940"
					alt=""
				/>
				<div className="data-banner tac-s fw600-s">
					<p className="t2 ls10-s fw600-s heading-font">
						Clickerti UI-Styles
					</p>
					<p className="normal fw300-s pb8-s">
						Esta guia define los diferentes componentes y estilos
						que sirven para la implementación de los productos
						realizados por Clickerti.
					</p>

					<h3>{title}</h3>
				</div>
			</div>
		</>
	);
};

export default MainBanner;
