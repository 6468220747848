import React from "react";
import Aside from "../Aside";

const Text = () => {
	return (
		<>
			<main className="main-content g-container gtc4-s gcg1-s">
				<Aside />
				<section className="main-section gcss3-s">
					<div className="l-block-2"></div>
					<h2>Texto</h2>
					<div className="main-block">
						<p className="main-text">
							El texto cuenta con gran cantidad de propiedades que
							podemos modificar, en nuestro caso contamos con
							clases que aplican cambios principalmente sobre los
							atributos <code>font-weight</code> y{" "}
							<code>letter-spacing</code>.
						</p>
						<h3>font-weight</h3>
						<p>
							La propiedad <code>font-weight</code> nos permite
							definir el grosor de los caracteres en un texto.
						</p>
						<p>
							Disponemos hasta un total de 9 clases de grosor que
							nos permitirán determinar ese grosor de los
							caracteres comenzando el mínimo de 100 como por
							ejemplo <code>.fw100-fold</code> hasta un máximo de
							900 como la clase <code>.fw100-fold</code>, su valor
							incrementará en 100 respecto del valor de la clase
							anterior.
						</p>
						<pre className="language-html code-toolbar">
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">div&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">
									fw100-fold
								</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									Texto aplicando un font-weight
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">div</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">div&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">
									fw400-fold
								</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									Texto aplicando un font-weight
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">div</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">div&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">
									fw900-fold
								</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									Texto aplicando un font-weight
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">div</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<div className="toolbar">
								<div className="toolbar-item">
									<span>HTML</span>
								</div>
							</div>
						</pre>
						<div className="container-demo">
							<div className="fw100-fold">
								Texto aplicando un font-weight
							</div>
							<div className="fw400-fold">
								Texto aplicando un font-weight
							</div>
							<div className="fw900-fold">
								Texto aplicando un font-weight
							</div>
						</div>
						<p>
							Además disponemos de distinas clases aplicadas por
							el tamaño de la pantalla disponiendo de clases para
							los tamaños:
						</p>
						<ul>
							<li>fold: 280px</li>
							<li>sm: 321px</li>
							<li>lm: 360px</li>
							<li>m: 411px</li>
							<li>xm: 640px</li>
							<li>ipad: 768px</li>
							<li>lg: 1024px</li>
							<li>xl: 1440px</li>
						</ul>
						<p>
							Las clases disponibles son clases del tipo{" "}
							<code>fw100-fold</code> o <code>fw100-fold</code>{" "}
							donde "f" significa <code>from</code> y "t"
							significa <code>to</code>. En nuestras clases esos
							atributos indicarán para el caso de{" "}
							<code>from</code> que la clase de aplicará a partir
							de ese tamaño y en el caso de <code>to</code> que la
							clase se aplicará hasta el tamaño indicado.
						</p>
						<p>
							Así podemos encontrar clases como por ejemplo:
							<ul>
								<li>fw100-fold</li>
								<li>fw100-sm</li>
								<li>fw100-lm</li>
								<li>fw100-m</li>
								<li>...</li>
							</ul>
						</p>
						<h3>letter-spacing</h3>
						<p>
							La propiedad <code>letter-spacing</code> nos permite
							definir el espacio que habrá entre los caracteres en
							un texto.
						</p>
						<p>
							Hemos dispuesto hasta un total de 50 clases que nos
							permitirán determinar ese espacio entre caracteres
							comenzando por un espacio de 0.1px clase{" "}
							<code>.ls1-s</code> hasta un máximo de 5px clase{" "}
							<code>.ls50-s</code>, incrementando el valor en
							0.1px con respecto del anterior.
						</p>
						<pre className="language-html code-toolbar">
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">div&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">
									ls01-fold
								</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									Texto aplicando un letter-spacing
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">div</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">div&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">
									ls10-fold
								</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									Texto aplicando un letter-spacing
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">div</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<code className="language-html">
								<span className="token punctuation">&lt;</span>
								<span className="token tag">div&nbsp;</span>
								<span className="token attr-name">class</span>
								<span className="token punctuation">="</span>
								<span className="token attr-value">
									ls20-fold
								</span>
								<span className="token punctuation">"</span>
								<span className="token punctuation">&gt;</span>
								<span className="token html-value">
									Texto aplicando un letter-spacing
								</span>
								<span className="token punctuation">&lt;/</span>
								<span className="token tag">div</span>
								<span className="token punctuation">&gt;</span>
							</code>
							<div className="toolbar">
								<div className="toolbar-item">
									<span>HTML</span>
								</div>
							</div>
						</pre>
						<div className="container-demo">
							<div className="ls01-fold">
								Texto aplicando un letter-spacing
							</div>
							<div className="ls20-fold">
								Texto aplicando un letter-spacing
							</div>
							<div className="ls30-fold">
								Texto aplicando un letter-spacing
							</div>
						</div>
						<p>
							Además disponemos de distinas clases aplicadas por
							el tamaño de la pantalla disponiendo de clases para
							los tamaños:
						</p>
						<ul>
							<li>fold: 280px</li>
							<li>sm: 321px</li>
							<li>lm: 360px</li>
							<li>m: 411px</li>
							<li>xm: 640px</li>
							<li>ipad: 768px</li>
							<li>lg: 1024px</li>
							<li>xl: 1440px</li>
						</ul>
						{/* <p>
							Las clases disponibles son clases del tipo{" "}
							<code>ls01-fold</code> o <code>ls01-fold</code>{" "}
							donde "f" significa <code>from</code> y "t"
							significa <code>to</code>. En nuestras clases esos
							atributos indicarán para el caso de{" "}
							<code>from</code> que la clase de aplicará a partir
							de ese tamaño y en el caso de <code>to</code> que la
							clase se aplicará hasta el tamaño indicado.
						</p>
						<p>
							Así podemos encontrar clases como por ejemplo:
							<ul>
								<li>ls01-fold</li>
								<li>ls01-sm</li>
								<li>ls01-lm</li>
								<li>ls01-m</li>
								<li>...</li>
							</ul>
						</p> */}
					</div>
				</section>
			</main>
		</>
	);
};

export default Text;
