import React from "react";
import { NavLink } from "react-router-dom";
const activeStyle = { color: "var(--text-list-color-hover)" };

const Aside = () => {
	return (
		<>
			<aside className="main-aside gcss1-s">
				<nav>
					<div className="list-class">
						<div className="listTitle-aside">Clases Generales</div>
						<ul className="list-aside">
							<li className="item-aside">
								<NavLink
									to="/instalation"
									activeStyle={activeStyle}
								>
									Instalación
								</NavLink>
							</li>
							<li className="item-aside">
								<NavLink
									to="/alignment"
									activeStyle={activeStyle}
								>
									Alineación
								</NavLink>
							</li>
							<li className="item-aside">
								<NavLink to="/border" activeStyle={activeStyle}>
									Bordes
								</NavLink>
							</li>
							<li className="item-aside">
								<NavLink to="/position" activeStyle={activeStyle}>
									Posición
								</NavLink>
							</li>
							<li className="item-aside">
								<NavLink
									to="/mediaquery"
									activeStyle={activeStyle}
								>
									Media query
								</NavLink>
							</li>
							<li className="item-aside">
								<NavLink
									to="/display"
									activeStyle={activeStyle}
								>
									Display
								</NavLink>
							</li>
							<li className="item-aside">
								<NavLink
									to="/flexbox"
									activeStyle={activeStyle}
								>
									Flexbox
								</NavLink>
							</li>
							<li className="item-aside">
								<NavLink to="/grid" activeStyle={activeStyle}>
									Grid
								</NavLink>
							</li>
							<li className="item-aside">
								<NavLink to="/margin" activeStyle={activeStyle}>
									Márgenes
								</NavLink>
							</li>
							<li className="item-aside">
								<NavLink
									to="/padding"
									activeStyle={activeStyle}
								>
									Padding
								</NavLink>
							</li>
							<li className="item-aside">
								<NavLink to="/size" activeStyle={activeStyle}>
									Tamaños
								</NavLink>
							</li>
							<li className="item-aside">
								<NavLink to="/text" activeStyle={activeStyle}>
									Texto
								</NavLink>
							</li>
							<li className="item-aside">
								<NavLink
									to="/typography"
									activeStyle={activeStyle}
								>
									Tipografía
								</NavLink>
							</li>
							<li className="item-aside">
								<NavLink
									to="/functions"
									activeStyle={activeStyle}
								>
									Funciones
								</NavLink>
							</li>
						</ul>
					</div>
					<div className="list-UIComponent">
						<div className="listTitle-aside">UIComponents</div>
						<ul className="list-aside">
							<li className="item-aside">
								<NavLink to="button" activeStyle={activeStyle}>
									Botones
								</NavLink>
							</li>
							<li className="item-aside">
								<NavLink to="table" activeStyle={activeStyle}>
									Tablas
								</NavLink>
							</li>
							<li className="item-aside">
								<NavLink to="form" activeStyle={activeStyle}>
									Formularios
								</NavLink>
							</li>
						</ul>
					</div>
				</nav>
			</aside>
		</>
	);
};

export default Aside;
