import React from "react";
import logo from "../img/clickerti-mod.svg";
import { Link } from "react-router-dom";

const Header = () => {
	return (
		<>
			<header className="header ptb-5-s">
				<div className="header-content">
					<Link to="/">
						<img className="main-logo" src={logo} alt="" />
					</Link>
				</div>
			</header>
		</>
	);
};

export default Header;
